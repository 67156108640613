import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { downloadUserReports } from "@/store/reports/actions";
import actionTypes from "@/store/reports/actionTypes";
import './style.scss';
import { userData, bioData, bankDetails, nextOfKin, others, portfolio, employment } from './data';

class UserInformation extends React.Component {

  state = {
    fields: [],
    user: [],
    biodata: [],
    employment: [],
    bank: [],
    nextOfKin: [],
    politicalStatus: [],
    portfolio: [],
    status: [],
    others: [],
    rows: 10,
  }

  // if you're wondering why there's alot of handle change methods here, please forgive me. I was in a hurry
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if(this.state[value].includes(name)){
      const filtArr = this.state[value].filter(item => item !== name);
      return this.setState({ [value]: filtArr });
    }
    return this.setState({ [value]: [...this.state[value], name] });
  }

  handleRowsSelectChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
  }

  handleDownloadReport = () => {
    const { user, biodata, employment, bank, nextOfKin, others, portfolio, rows } = this.state;
    const page = 1;
    const payload = {
      user, biodata, employment, bank, nextOfKin, politicalStatus: others, portfolio 
    }
    const stringed = JSON.stringify(payload)
    this.props.downloadUserReports(stringed, rows, page)
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `user-information-${Math.random()}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
    })
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="user-information mb-5">
        <div className="white-section card">
          <div className="row">
            <div className="col-md-6">
              <h3 className="text-deep-blue mb-4">User ID</h3>
              {userData.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      defaultChecked={this.state.user.includes(item.value)}
                      value="user"
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
            <div className="col-md-6">
            <h3 className="text-deep-blue mb-4">Bank Details</h3>
              {bankDetails.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      value="bank"
                      defaultChecked={this.state.bank.includes(item.value)}
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>
            <div className="mt-5">
            <h3 className="text-deep-blue mb-4">Bio Data</h3>
            </div>
          <div className="row">
            {bioData.map(item => {
              return (
                <div className="col-md-6" key={Math.random()*1000}>
                  <div className="d-flex align-items-center mb-3">
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      defaultChecked={this.state.biodata.includes(item.value)}
                      value="biodata"
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
            <h3 className="text-deep-blue mb-4">Employment Details</h3>
              {employment.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      value="employment"
                      defaultChecked={this.state.employment.includes(item.value)}
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
            <div className="col-md-6">
            <h3 className="text-deep-blue mb-4">Next of Kin Details</h3>
              {nextOfKin.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      value="nextOfKin"
                      defaultChecked={this.state.nextOfKin.includes(item.value)}
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
            <h3 className="text-deep-blue mb-4">Portfolio</h3>
              {portfolio.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      checked={this.state.portfolio.includes(item.value)}
                      value="portfolio"
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
            <div className="col-md-6">
            <h3 className="text-deep-blue mb-4">Other Informations</h3>
              {others.map(item => {
                return (
                  <div className="d-flex align-items-center mb-3" key={Math.random()*1000}>
                    <input
                      id={item.value}
                      className="mr-4"
                      type="checkbox"
                      name={item.value}
                      onChange={this.handleChange}
                      checked={this.state.others.includes(item.value)}
                      value="others"
                    />
                    <label className="mb-0" htmlFor="occupiedTillDate">{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              
            </div>
          </div>
            
          <div className="d-flex justify-content-start mt-4">
            <button className="btn btn-primary btn-sm" onClick={this.handleDownloadReport}>
              Download Report
              {loading &&
                <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
              }
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: getActionLoadingState(state, actionTypes.DOWNLOAD_USER_REPORTS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadUserReports: (payload, rows, page) => dispatch(downloadUserReports(payload, rows, page)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInformation));
