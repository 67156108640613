import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import { fetchAdmins, registerAdmin, changeAdminStatus, deleteAdmin, editAdmin, resetAdminsPassword, multipleApproveReject } from "@/store/setup/adminUserAction";
import { disableUser } from "@/store/user/actions";
import { fetchRoles } from "@/store/setup/rolesAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/setup/actionTypes";
import userActionTypes from "@/store/user/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import Alert from "@/components/Alert";
import { serializeErrors, hasPermission } from "@/utils";
import InputError from "@/components/InputError";
import RightPanel from '@/components/RightPanel';
import Dropdown from '@/components/DropdownMenu';
import Emptystate from '@/components/Emptystate';
import Pagination from '@/components/Pagination';
import ConfirmationBox from '@/components/ConfirmationBox';
import ExportDropdown from '@/components/ExportDropdown';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import SearchBox from '@/components/SearchBox';
import MultipleApprovalDropdown from '@/components/MultipleApprovalDropdown';

class Admins extends Component {
    defaultState = {
        open: false,
        firstName: '',
        lastName: '',
        phone: '',
        countryCode: '+234',
        email: '',
        roleId: '',
        editAdminId: '',
        previewMode: false,
    };

    state = {
        ...this.defaultState,
        admins: [],
        pendingAdmins: [],
        roles: [],
        confirmationContext: '',
        confirmationSuccess: false,
        searchTerm: '',
        shouldSearch: false,
        selectedRowsIds: [],
    };

    componentDidMount() {
        this.getAdmins(1, 10);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({selectedRowsIds: []}, () => this.getAdmins(1, 10))
        }
        if(this.state.searchTerm !== prevState.searchTerm && this.state.shouldSearch) {
            this.getAdmins(1, 10, this.state.searchTerm);
        }
    }

    isShowingActiveAdmins = () => {
        if(this.props.location.pathname.indexOf('pending') === -1) return true;
        return false;
    }

    setPageData = ({lastPage, perPage, page, total}) => {
        this.setState({lastPage, perPage, page, total});
    }

    getAdmins = (page, limit, searchTerm) => {
        if(this.isShowingActiveAdmins()){
            this.props.fetchAdmins(page, limit, 'active', searchTerm).then(admins => {
                this.setState({admins: admins.data, searchTerm, shouldSearch: false});
                this.setPageData(admins)
            })
        } else {
            this.props.fetchAdmins(page, limit, 'pending', searchTerm).then(admins => {
                this.setState({pendingAdmins: admins.data, searchTerm, shouldSearch: false});
                this.setPageData(admins)
            })
        }
    }

    changeAdminStatus = (reason) => {
        const { id, newStatus, admins } = this.state;
        const status = newStatus === 'Disable' ? 'disabled' : 'enabled';
        this.props.disableUser(id, reason, status).then(() => {
            const allAdmins = [ ...admins];
            const adminIndex = allAdmins.findIndex(admin => admin.id === id);
            const newAdminInfo = { ...allAdmins[adminIndex], status: newStatus === 'Disable' ? 'disabled' : 'enabled'};
            allAdmins[adminIndex] =  newAdminInfo;
            this.setState({admins: allAdmins, confirmationSuccess: true });
        });
    }

    onEditAdmin = (id) => {
        const adminInfo = this.state.admins.find(admin => admin.id === id);
        this.fetchRolesIfNotExit();
        this.setState({
            firstName: adminInfo.firstName,
            lastName: adminInfo.lastName,
            phone: adminInfo.phone,
            countryCode: adminInfo.countryCode ?? '+234',
            email: adminInfo.email,
            roleId: adminInfo.roles[0]?.id,
            editAdminId: id,
            open: true,
        })
    }

    removeAdmin = () => {
        const { id, admins } = this.state;
        this.props.deleteAdmin(id).then(() => {
            const allAdmins = [ ...admins];
            const adminIndex = allAdmins.findIndex(admin => admin.id === id);
            allAdmins.splice(adminIndex, 1);
            this.setState({admins: allAdmins, confirmationSuccess: true });
        });
    }

    confirmationData = () => {
        const {isMultipleApproval, selectedRowsIds} = this.state;
        // addS below is used to know whether to display the message in plural or singular form
        const addS = isMultipleApproval && selectedRowsIds.length > 1;
        return {
            changeStatus: {
                title: this.state.newStatus === 'Disable' ? `Reason for disabling admin` : `${this.state.newStatus} admin`,
                question: `Are you sure you want to ${this.state.newStatus} this admin?`,
                action: (reason) => this.changeAdminStatus(reason),
                requiresReason: this.state.newStatus === 'Disable'
            },
            remove: {
                title: 'Remove admin',
                question: 'Are you sure you want to remove this admin?',
                action: this.removeAdmin,
            },
            approveAdmin: {
                title: `Approve admin${addS ? 's' : ''}`,
                question: `Are you sure you want to approve ${addS ? 'these' : 'this'} admin${addS ? 's' : ''}?`,
                action: () => isMultipleApproval ? this.multipleApproveReject('approval') :  this.approveAdmin(),
            },
            rejectAdmin: {
                title: `Reject admin${addS ? 's' : ''}`,
                question: `Are you sure you want to reject ${addS ? 'these' : 'this'} admin${addS ? 's' : ''}?`,
                action: () => isMultipleApproval ? this.multipleApproveReject('reject') : this.rejectAdmin(),
            },
        }
    }

    setConfirmationContext = (context, adminId, newStatus = '', isMultipleApproval ) => {
        this.setState({
            confirmationContext: context,
            confirmationSuccess: false,
            id: adminId,
            newStatus,
            isMultipleApproval,
        })
    }

    dropdownmenus = (id, status) => {
        // const newStatus = status === 'enabled' ? 'Disable' : 'Enable';
        // const canUpdate = hasPermission(this.props.permissions, 'update_user');
        // const canDelete = hasPermission(this.props.permissions, 'delete_user');
        // return [
        //     canUpdate ? {name: 'Edit', handler: () => this.onEditAdmin(id)} : null,
        //     canUpdate ? {name: newStatus, handler: () => this.setConfirmationContext('changeStatus', id, newStatus)} : null,
        //     canDelete ? {name: 'Remove', handler: () => this.setConfirmationContext('remove', id)} : null,
        const newStatus = status === 'enabled' ? 'Disable' : 'Enable';
        return [
            { name: 'Edit', handler: () => this.onEditAdmin(id) },
            { name: newStatus, handler: () => this.setConfirmationContext('changeStatus', id, newStatus) },
            { name: 'Remove', handler: () => this.setConfirmationContext('remove', id) },
        ].filter(Boolean)
    }

    onRowClick = (e, admin) => {
        if(!['BUTTON', 'IMG', 'LI', 'INPUT'].includes(e.target.nodeName)){
            this.setState({
                firstName: admin.firstName,
                lastName: admin.lastName,
                phone: admin.phone,
                countryCode: admin.countryCode ?? '+234',
                email: admin.email,
                adminRole: admin.roles[0]?.name,
                picture: admin.pictureUrl,
                adminStatus: admin.status === 'enabled' ? 'Active' : 'Disabled',
                previewMode: true,
                clickedAdminId: admin.id,
                open: true,
            });
        }
    }

    renderActiveAdmins = () => {
        return <table className="custum-table">
            <tbody>
                <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                {this.state.admins.map(admin => (
                    <tr key={admin.id} onClick={(e) => this.onRowClick(e, admin)} className="cursor-pointer">
                        <td>{admin.id}</td>
                        <td className="text-capitalize">{`${admin.firstName} ${admin.lastName}`}</td>
                        <td>{admin.email}</td>
                        <td>{admin.roles[0]?.name}</td>
                        <td>
                            <span
                                className={`status ${admin.status === 'enabled' ? 'status--active' : 'status--disabled'}`}
                            >
                                {admin.status}
                            </span>
                        </td>
                        <td className="custum-table__ellipsis">
                            <Dropdown menu={this.dropdownmenus(admin.id, admin.status)} width="117px" arrow={true}>
                                <button className="wrapper-button ellipsis">
                                    <img src={require("@/assets/icons/flat-ellipsis.svg")} alt="dropdown"/>
                                </button>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    multipleApproveReject = (type) => {
        const {selectedRowsIds, pendingAdmins, total} = this.state;
        const payload = {
            staffApproval: type === 'approval' ? 'active' : '',
            users: selectedRowsIds,
            requests: selectedRowsIds,
        };
        this.props.multipleApproveReject(payload, type).then(() => {
            const unchangedItems = pendingAdmins.filter(data => !selectedRowsIds.includes(data.id));
            this.setState({
                pendingAdmins: unchangedItems,
                selectedRowsIds: [],
                confirmationSuccess: true,
                isMultipleApproval: false,
                total: total - selectedRowsIds.length,
            })
        })
    }

    approveAdmin = () => {
        const { id, pendingAdmins } = this.state;
        this.props.changeAdminStatus(id, 'enable').then(() => {
            const allAdmins = [ ...pendingAdmins];
            const adminIndex = allAdmins.findIndex(admin => admin.id === id);
            allAdmins.splice(adminIndex, 1);
            this.setState({pendingAdmins: allAdmins, confirmationSuccess: true });
        });
    }

    rejectAdmin = () => {
        const { id, pendingAdmins } = this.state;
        this.props.changeAdminStatus(id, 'disable', 'reject').then(() => {
            const allAdmins = [ ...pendingAdmins];
            const adminIndex = allAdmins.findIndex(admin => admin.id === id);
            allAdmins.splice(adminIndex, 1);
            this.setState({pendingAdmins: allAdmins, confirmationSuccess: true });
        });
    }

    onCheckRow = (e, rowId) => {
        const selectedRows = [ ...this.state.selectedRowsIds ]
        if(e.target.checked) {
            selectedRows.push(rowId);
        } else {
            const uncheckedRowIndex = selectedRows.findIndex(id => id === rowId);
            selectedRows.splice(uncheckedRowIndex, 1)
        }
        this.setState({selectedRowsIds: selectedRows})
    }

    renderPendingAdmins = () => {
        return <table className="custum-table">
            <tbody>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th className="actions-column">Actions</th>
                </tr>
                {this.state.pendingAdmins.map(admin => (
                    <tr key={admin.id} onClick={(e) => this.onRowClick(e, admin)} className="cursor-pointer">
                        <td className="row-checkbox">
                            <input type="checkbox" onChange={(e) => this.onCheckRow(e, admin.id)}/>
                        </td>
                        <td>{admin.id}</td>
                        <td className="text-capitalize">{`${admin.firstName} ${admin.lastName}`}</td>
                        <td>{admin.email}</td>
                        <td>{admin.roles[0]?.name}</td>
                        <td className="custum-table__button-cell">
                            <button className="custum-table__button-blue" onClick={() => this.setConfirmationContext('approveAdmin', admin.id)}>Approve</button>
                            <button className="custum-table__button-plain" onClick={() => this.setConfirmationContext('rejectAdmin', admin.id)}>Reject</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    fetchRolesIfNotExit = () => {
        if(!this.state.roles.length) {
            this.props.fetchRoles().then(roles => {
                this.setState({roles: roles.data});
            });
        }
    }

    onAddAdmin = () => {
        this.setState({
            open: true,
        });
        this.fetchRolesIfNotExit();
    }

    createAdmin = (e) => {
        e.preventDefault();
        const { firstName, lastName, email, phone, countryCode, roleId, editAdminId } = this.state;
        const payload = { firstName, lastName, email, phone, countryCode, roleId, };

        if(editAdminId) {
            this.props.editAdmin(payload, editAdminId);
        } else {
            this.props.registerAdmin(payload);
        }
    }

    resetAdminsPassword = () => {
        const { clickedAdminId: id, firstName, lastName } = this.state;
        this.props.resetAdminsPassword(id, `${firstName} ${lastName}`);
    }

    closePanel = (e) => {
        e.preventDefault();
        this.setState({ ...this.defaultState });
        this.props.clearError();
    }

    render() {
        const {
            match: { path },
            createError,
            fetching,
            confirmationLoading,
            disableUserLoading,
            pageRequestError,
            permissions,
        } = this.props;
        const {
            firstName,
            lastName,
            phone,
            countryCode,
            email,
            roles,
            roleId,
            adminRole,
            adminStatus,
            picture,
            editAdminId,
            confirmationContext,
            confirmationSuccess,
            previewMode,
            admins,
            pendingAdmins,
            lastPage,
            perPage,
            page,
            total,
            searchTerm,
        } = this.state;
        const createErrorObject = serializeErrors(pageRequestError?.message);
        const prompt = this.confirmationData()[confirmationContext];
        const canApprove = hasPermission(permissions, 'approve_user');
        const canCreate = hasPermission(permissions, 'create_user');
        const isShowingActiveAdmins = this.isShowingActiveAdmins();
        const dataCount = isShowingActiveAdmins ? admins.length : pendingAdmins.length;
        const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

        return(
            <div className="setup-inner">
                <RightPanel
                    open={this.state.open}
                    onClose={this.closePanel}
                >
                    <h1 className="panel-heading">{editAdminId ? 'Edit Admin' : previewMode ? 'Admin User Info' : 'Add New Admin User'}</h1>
                    <Alert alert={createError} />
                    {!previewMode ? <form className="form employer-onboard__form" onSubmit={this.createAdmin}>
                        <label className="panel-label" htmlFor="first-name">First name</label>
                        <input
                            type="text"
                            className="form-control panel-input"
                            placeholder="First name"
                            id="first-name"
                            name="firstName"
                            value={firstName}
                            onChange={this.onInputChange}
                            autoComplete="off"
                            required
                        />
                        <InputError error={createErrorObject.firstName} />
                        <label className="panel-label" htmlFor="last-name">Last name</label>
                        <input
                            type="text"
                            className="form-control panel-input"
                            placeholder="Last name"
                            id="last-name"
                            name="lastName"
                            value={lastName}
                            onChange={this.onInputChange}
                            autoComplete="off"
                            required
                        />
                        <InputError error={createErrorObject.lastName} />
                        <label className="panel-label" htmlFor="phone">Phone number</label>
                        <PhoneNumberInput
                            onInputChange={this.onInputChange}
                            countryCodeValue={countryCode}
                            phoneValue={phone}
                        />
                        <InputError error={createErrorObject.phone} />
                        <label className="panel-label" htmlFor="email">Email address</label>
                        <input
                            type="email"
                            className="form-control panel-input"
                            placeholder="Email address"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.onInputChange}
                            autoComplete="off"
                            required
                            disabled={editAdminId}
                        />
                        <InputError error={createErrorObject.email} />
                        <label className="panel-label" htmlFor="role">Role</label>
                        <select
                            required
                            className="form-control panel-input"
                            id="role"
                            name="roleId"
                            value={roleId}
                            onChange={this.onInputChange}
                        >
                            <option value=''>Choose a role</option>
                            {roles.map(role => <option value={role.id} key={role.id}>{role.name}</option>)}
                        </select>
                        <InputError error={createErrorObject.roleId} />
                        <div className="dual-button-box">
                            <button onClick={this.closePanel} className="cp-button-blue">Cancel</button>
                            <button
                                className="cp-button-blue"
                            >
                                {editAdminId ? 'Submit' : 'Add Admin User'} {confirmationLoading &&
                                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>}
                            </button>
                        </div>
                    </form> :
                    <div className="panel-info">
                        <div className="panel-info__picture">
                            <img src={picture || require("@/assets/icons/img-default.svg")} alt="profile"/>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">First name</span>
                            <span className="panel-info__value">{firstName}</span>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">Last name</span>
                            <span className="panel-info__value">{lastName}</span>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">Phone number</span>
                            <span className="panel-info__value">{countryCode + Number(phone)}</span>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">Email address</span>
                            <span className="panel-info__value">{email}</span>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">Role</span>
                            <span className="panel-info__value">{adminRole}</span>
                        </div>
                        <div className="panel-info__row">
                            <span className="panel-info__field">Status</span>
                            <span
                                className={`status ${adminStatus === 'Active' ? 'status--active' : 'status--disabled'}`}
                            >
                                {adminStatus}
                            </span>
                        </div>
                        {(isShowingActiveAdmins && canApprove) && <div className="panel-info__row">
                            <span className="panel-info__field">Forgotten Password?</span>
                            {confirmationLoading ? <div className="spinner-border spinner-border-sm ml-2 mb-2"></div> :
                            <span
                                className="panel-info__value text-blue cursor-pointer"
                                onClick={this.resetAdminsPassword}
                            >Reset Password</span>}
                        </div>}
                        <button className="long-button" onClick={this.closePanel}>Close</button>
                    </div>}
                </RightPanel>
                <div className="setup-inner__top">
                    <ConfirmationBox
                        open={confirmationContext}
                        closeHandler={() => this.setState({confirmationContext: ''})}
                        success={confirmationSuccess}
                        title={prompt?.title}
                        question={prompt?.question}
                        action={prompt?.action}
                        loading={confirmationLoading || disableUserLoading}
                        requiresReason={prompt?.requiresReason}
                    />
                    <ul className="setup-inner__nav">
                        <li className="setup-nav__item">
                            <NavLink
                                exact
                                to={{pathname: `${path}`, state: { pageTitle: 'Setup'}}}
                                activeClassName="setup-nav__item--active"
                            >
                                Admin Users
                            </NavLink>
                        </li>
                        {canApprove && <li className="setup-nav__item">
                            <NavLink
                                to={{pathname: `${path}/pending`, state: { pageTitle: 'Setup'}}}
                                activeClassName="setup-nav__item--active"
                            >
                                Pending Approval
                            </NavLink>
                        </li>}
                    </ul>
                    <SearchBox
                        placeholder="Search"
                        handleSearch={searchTerm => this.setState({searchTerm, shouldSearch: true})}
                        isActiveSearch={Boolean(searchTerm)}
                        key={this.props.location.pathname}
                    />
                    <ExportDropdown
                        module="admin-users"
                        status={isShowingActiveAdmins ? 'approved' : 'pending-approval'}
                        page={page}
                        limit={perPage}
                        hasMoreThanOnePage={lastPage > 1}
                    />
                    {(isShowingActiveAdmins && canCreate) && <button className="setup-inner__button" onClick={this.onAddAdmin}>
                        <img src={require("@/assets/icons/plus.svg")} alt="plus icon" className="setup-inner__button-img" />
                         Add Admin User
                    </button>}
                    {showMultiAprrovalDropdown && <MultipleApprovalDropdown
                        approve={() => this.setConfirmationContext('approveAdmin', null, null, true)}
                        reject={() => this.setConfirmationContext('rejectAdmin', null, null, true)}
                    />}
                </div>
                {fetching ? <div className="text-center text-primary">
                    <div className="spinner-border" role="status"></div>
                </div> :
                <div className="position-relative">
                    {!dataCount ? <Emptystate
                        title={`${searchTerm ? 'No Result Found' : 'No Admin users'}`}
                        icon={require('@/assets/icons/info.svg')}
                    /> : <>
                        <Pagination
                            totalPages={lastPage}
                            page={page}
                            limit={perPage}
                            changePageHandler={(page, limit) => this.getAdmins(page, limit, searchTerm)}
                        />
                        <div className="setup-inner__main">
                            <Switch>
                                <Route exact path={path} render={() => this.renderActiveAdmins()} />
                                {canApprove && <Route exact path={`${path}/pending`} render={() => this.renderPendingAdmins()} />}
                            </Switch>
                        </div>
                        <div className="data-count">
                            Showing<span className="font-weight-bold mx-2">{`${dataCount} of ${total}`}</span>Admin users
                        </div>
                    </>}
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetching: getActionLoadingState(state, actionTypes.GET_ADMIN_REQUEST),
        confirmationLoading: getActionLoadingState(state, actionTypes.ADMIN_PAGE_REQUEST),
        disableUserLoading: getActionLoadingState(state, userActionTypes.DISABLE_USER_REQUEST),
        pageRequestError: getActionErrorState(state, actionTypes.ADMIN_PAGE_ERROR),
        permissions: state.user.permissions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAdmins: (page, limit, status, searchTerm) => dispatch(fetchAdmins(page, limit, status, searchTerm)),
        registerAdmin: (data) => dispatch(registerAdmin(data)),
        editAdmin: (data, adminId) => dispatch(editAdmin(data, adminId)),
        deleteAdmin: (adminId) => dispatch(deleteAdmin(adminId)),
        multipleApproveReject: (payload, type) => dispatch(multipleApproveReject(payload, type)),
        resetAdminsPassword: (adminId, name) => dispatch(resetAdminsPassword(adminId, name)),
        changeAdminStatus: (adminId, newStatus, action) => dispatch(changeAdminStatus(adminId, newStatus, action)),
        disableUser: (userId, reason, status) => dispatch(disableUser(userId, reason, status)),
        fetchRoles: () => dispatch(fetchRoles('active')),
        clearError: () => dispatch(clearError()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Admins)
);
