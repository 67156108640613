import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import { getActionLoadingState } from "@/store/selectors";
import { getUniqueCustomerReport } from "@/store/ppi/actions";
import actionTypes from "@/store/ppi/actionTypes";
import Pagination from '@/components/Pagination';
import Emptystate from '@/components/Emptystate';
import './style.scss';

class UniqueCustomers extends React.Component {
  componentDidMount() {
    this.getUniqueCustomers(1, 10)
  }

  getUniqueCustomers = (page, limit) => {
    const { id } = this.props;
    this.props.getUniqueCustomerReport(id, page, limit)
  }
  render() {
    const { unique, loading } = this.props;

    return (
      <div className="unique-users-report-page">
        <Pagination
          totalPages={unique?.lastPage}
          page={unique?.page}
          limit={unique?.perPage}
          changePageHandler={(page, limit) => this.getDownloadedInfo(page, limit)}
        />
        {!unique && loading &&
          <div className="text-center p-4">
            <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
          </div>
        }
        {unique && (
          unique?.mutual?.data?.length > 0 ?
            <>
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email Address</th>
                      <th>Date of Click</th>
                    </tr>
                    {unique?.mutual?.data.map(item => (
                      <tr key={Math.random()*1000}>
                        <td><span>{item.firstName} {item.lastName}</span></td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{dayjs(item.created_at).format('DD MMM, YYYY')}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">Showing {unique?.mutual?.data?.length} of {unique?.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
            :
            <Emptystate
              title="No Downloaded data"
              icon={require('@/assets/icons/info.svg')}
            />)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app:
    {
      ppi: {
        unique
      }
    }
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_UNIQUE_CUSTOMER_REPORT_REQUEST),
    unique,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUniqueCustomerReport: (id, page, limit ) => dispatch(getUniqueCustomerReport(id, page, limit)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UniqueCustomers));
