import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import actionTypes from "@/store/app/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import LineGraph from "@/components/LineGraph";
import Emptystate from "@/components/Emptystate";
import CustomSelectInput from "@/components/CustomSelectInput";
import { fetchDashboardInfo, fetchGraph } from "@/store/app/actions";
import { currency, sumArray } from "@/utils";
import "./style.scss";

class DashboardHome extends Component {
  state = {
    data: "",
    userYAxis: [],
    userXAxis: [],
    investmentXAxis: [],
    investmentYAxis: [],
    userRange: "daily",
    investmentRange: "daily",
    graphToUpdate: "",
    redraw: true,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchDashboardInfo().then((data) => {
      this.setState({
        data: data.data,
        loading: false,
      });
    });
  }

  componentDidUpdate(_, prevState) {
    if (this.state.data !== prevState.data) {
      this.getUsersGraph();
      this.getInvestmentGraph();
    }
    if (this.state.userRange !== prevState.userRange) {
      this.updateUsersGraph(this.state.userRange);
    }
    if (this.state.investmentRange !== prevState.investmentRange) {
      this.updateInvestmentsGraph(this.state.investmentRange);
    }
  }

  summaryData = () => {
    const {
      data: { reports, investmentsReport, transactionsReport },
    } = this.state;
    return [
      {
        mainTitle: "Users",
        mainValue: reports?.totalUsers,
        bottomTitle1: "Active",
        bottomValue1: reports?.activeUsers,
        bottomTitle2: "Inactive",
        bottomValue2: reports?.inActiveUsers,
        class: "users",
      },
      {
        mainTitle: "Sign-UPs",
        mainValue: reports?.totalSignUps,
        bottomTitle1: "KYC Approved",
        bottomValue1: reports?.approvedUsers,
        bottomTitle2: "KYC Pending",
        bottomValue2: reports?.pendingUsers,
        class: "signups",
      },
      {
        mainTitle: "termed investments",
        mainValue: ` ₦${currency(investmentsReport?.totalBalance, true)}`,
        bottomTitle1: "Accrued Interest",
        bottomValue1: ` ₦${currency(investmentsReport?.totalInterest, true)}`,
        bottomTitle2: "Targets",
        bottomValue2: ` ₦${currency(
          investmentsReport?.totalTargetAmount,
          true
        )}`,
        class: "investments",
      },
      {
        mainTitle: "Transaction Value",
        mainValue: ` ₦${currency(transactionsReport?.totalTransactions, true)}`,
        bottomTitle1: "Deposits",
        bottomValue1: ` ₦${currency(transactionsReport?.totalDeposit, true)}`,
        bottomTitle2: "Withdrawals",
        bottomValue2: ` ₦${currency(
          transactionsReport?.totalWithdrawal,
          true
        )}`,
        class: "transaction",
      },
    ];
  };

  renderSummary = () => {
    return (
      <div className="summary">
        {this.summaryData().map((data, index) => (
          <div key={index} className="summary-card">
            <span className="summary-card__title">{data.mainTitle}</span>
            <span className="summary-card__total">{data.mainValue}</span>
            <div className="summary-card__bottom">
              <div className={`summary-card__bottom-half--${data.class}`}>
                <span className="summary-card__bottom-status">
                  {data.bottomTitle1}
                </span>
                <span className="summary-card__bottom-value">
                  {data.bottomValue1}
                </span>
              </div>
              <span className="summary-card__bottom-divider"></span>
              <div className={`summary-card__bottom-half--${data.class}`}>
                <span className="summary-card__bottom-status">
                  {data.bottomTitle2}
                </span>
                <span className="summary-card__bottom-value">
                  {data.bottomValue2}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  updateUsersGraph = (range) => {
    this.props.fetchGraph("users", range).then((data) => {
      const currentData = { ...this.state.data };
      currentData.usersChart = data.data.usersChart;
      this.setState({ data: currentData });
    });
  };

  updateInvestmentsGraph = (range) => {
    this.props.fetchGraph("investments", range).then((data) => {
      const currentData = { ...this.state.data };
      currentData.investmentsChart = data.data.investmentsChart;
      this.setState({ data: currentData });
    });
  };

  getUsersGraph = () => {
    if (!this.state.data) return;
    const {
      data: {
        usersChart: { totalUsers },
      },
    } = this.state;
    const AllData = [...totalUsers];
    let userYAxis = [];
    let userXAxis = [];
    AllData.forEach((data, index) => {
      userYAxis[index] = data.totalUsers;

      if (this.state.userRange === "monthly") {
        userXAxis[index] = dayjs(data.date).format("MM/YY");
      } else if (this.state.userRange === "yearly") {
        userXAxis[index] = dayjs(data.date).format("YYYY");
      } else userXAxis[index] = dayjs(data.date).format("DD/MM");
    });
    this.setState({ userYAxis, userXAxis });
  };

  getInvestmentGraph = () => {
    if (!this.state.data) return [];
    const {
      data: {
        investmentsChart: { totalInvestments },
      },
    } = this.state;
    const AllData = [...totalInvestments];
    let investmentYAxis = [];
    let investmentXAxis = [];
    AllData.forEach((data, index) => {
      investmentYAxis[index] = data.totalInvestments;
      if (this.state.investmentRange === "monthly") {
        investmentXAxis[index] = dayjs(data.date).format("MM/YY");
      } else if (this.state.investmentRange === "yearly") {
        investmentXAxis[index] = dayjs(data.date).format("YYYY");
      } else investmentXAxis[index] = dayjs(data.date).format("DD/MM");
    });
    this.setState({ investmentYAxis, investmentXAxis });
  };

  resolveArrow = (diff) =>
    diff < 0 ? "fall-red-small" : diff > 0 ? "rise-green" : "flatline";

  displayRange = (range) =>
    range === "daily" ? "yesterday" : `last ${range.split("ly")[0]}`;

  render() {
    const { fetching, firstName, lastLogin, fetchingGraph } = this.props;
    const {
      investmentYAxis,
      investmentXAxis,
      userYAxis,
      userXAxis,
      data,
      userRange,
      investmentRange,
      graphToUpdate,
    } = this.state;
    const {
      nearingMaturityInvestment,
      latestTickets,
      usersChart,
      investmentsChart,
    } = data;
    const usersDiffArrow = this.resolveArrow(usersChart?.percentageDiff);
    const investmentDiffArrow = this.resolveArrow(
      investmentsChart?.percentageDiff
    );

    if (fetching)
      return (
        <div className="text-center text-primary mt-5">
          <div className="spinner-border" role="status"></div>
        </div>
      );
    if (!this.state.data) return "";

    return (
      <div className="main-dashboard">
        <h3 className="main-dashboard__admin-name">Hi {firstName}</h3>
        <p className="main-dashboard__last-seen">
          <span>Last sign-in: </span>
          <span>{dayjs(lastLogin).format("ddd, MMM D, YYYY h:mm A")}</span>
        </p>
        {this.renderSummary()}
        <div className="boxes-container">
          <div className="graph-box">
            <div className="d-flex align-items-center">
              <h3 className="box-title mr-auto">User registrations</h3>
              <CustomSelectInput
                options={[
                  { name: "DAILY", value: "daily" },
                  { name: "WEEKLY", value: "weekly" },
                  { name: "MONTHLY", value: "monthly" },
                  { name: "YEARLY", value: "yearly" },
                ]}
                onChange={(range) =>
                  this.setState({
                    userRange: range,
                    graphToUpdate: "users",
                    redraw: false,
                  })
                }
                width="128px"
                value={userRange}
                loading={fetchingGraph && graphToUpdate === "users"}
              />
            </div>
            <div className="d-flex align-items-center my-2 mr-4">
              <span className="graph-box__total mr-auto">
                {sumArray(userYAxis)}
              </span>
              <div className="graph-box__performance">
                <span className={`d-block ${usersDiffArrow}`}>
                  <img
                    src={require(`@/assets/icons/${usersDiffArrow}.svg`)}
                    alt=""
                  />{" "}
                  {usersChart.percentageDiff}%
                </span>
                <span className="d-block text-grey">
                  vs {this.displayRange(userRange)}
                </span>
              </div>
            </div>
            <LineGraph
              yAxis={userYAxis}
              xAxis={userXAxis}
              redraw={this.state.redraw}
              isCurrency={false}
            />
          </div>
          <div className="graph-box">
            <div className="d-flex align-items-center">
              <h3 className="box-title mr-auto">Investments</h3>
              <CustomSelectInput
                options={[
                  { name: "DAILY", value: "daily" },
                  { name: "WEEKLY", value: "weekly" },
                  { name: "MONTHLY", value: "monthly" },
                  { name: "YEARLY", value: "yearly" },
                ]}
                onChange={(range) =>
                  this.setState({
                    investmentRange: range,
                    graphToUpdate: "investments",
                    redraw: false,
                  })
                }
                width="128px"
                value={investmentRange}
                loading={fetchingGraph && graphToUpdate === "investments"}
              />
            </div>
            <div className="d-flex align-items-center my-2 mr-4">
              <span className="graph-box__total mr-auto">
                {` ₦${currency(sumArray(investmentYAxis), true)}`}
              </span>
              <div className="graph-box__performance">
                <span className={`d-block ${investmentDiffArrow}`}>
                  <img
                    src={require(`@/assets/icons/${investmentDiffArrow}.svg`)}
                    alt=""
                  />{" "}
                  {investmentsChart.percentageDiff}%
                </span>
                <span className="d-block text-grey">
                  vs {this.displayRange(investmentRange)}
                </span>
              </div>
            </div>
            <LineGraph
              yAxis={investmentYAxis}
              xAxis={investmentXAxis}
              abbreviateY
              redraw={this.state.redraw}
              isCurrency={true}
            />
          </div>
        </div>
        <div className="boxes-container">
          <div className="table-box">
            <h3 className="box-title">Nearing maturity (investments)</h3>
            {nearingMaturityInvestment.length > 0 && (
              <span
                className="box-link"
                onClick={() =>
                  this.props.history.push(
                    "transactions/investments/nearing-maturity"
                  )
                }
              >
                Show all
              </span>
            )}
            {nearingMaturityInvestment.length > 0 ? (
              <table className="custum-table table-box__table">
                <tbody>
                  <tr>
                    <th>Maturity Date</th>
                    <th>Title</th>
                    <th>Face value</th>
                  </tr>
                  {nearingMaturityInvestment.map((investment) => (
                    <tr key={investment.title + investment.endDate}>
                      <td>{dayjs(investment.endDate).format("D MMM YYYY")}</td>
                      <td>{investment.title}</td>
                      <td>{investment.balance}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Emptystate
                title="No investment"
                icon={require("@/assets/icons/info.svg")}
              />
            )}
          </div>
          <div className="table-box">
            <h3 className="box-title">open tickets</h3>
            {latestTickets.length > 0 && (
              <span
                className="box-link"
                onClick={() => this.props.history.push("support/tickets")}
              >
                Show all
              </span>
            )}
            {latestTickets.length > 0 ? (
              <table className="custum-table table-box__table">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Subject</th>
                    <th>Date Created</th>
                  </tr>
                  {latestTickets.map((ticket) => (
                    <tr key={ticket.id}>
                      <td>{ticket.id}</td>
                      <td>{`${ticket.user.firstName} ${ticket.user.lastName}`}</td>
                      <td>{ticket.title}</td>
                      <td>{dayjs(ticket.created_at).format("D MMM YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Emptystate
                title="No tickets"
                icon={require("@/assets/icons/info.svg")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { firstName, lastLogin, permissions } = state.user;
  return {
    fetching: getActionLoadingState(state, actionTypes.APP_DASHBOARD_REQUEST),
    fetchingGraph: getActionLoadingState(
      state,
      actionTypes.FETCH_GRAPH_REQUEST
    ),
    firstName,
    lastLogin,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardInfo: () => dispatch(fetchDashboardInfo()),
    fetchGraph: (type, range) => dispatch(fetchGraph(type, range)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardHome)
);
