import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { getActionLoadingState } from "@/store/selectors";
import { getDrafts, deletePost, editBlogPost, getCategories } from "@/store/blog/actions";
import actionTypes from "@/store/blog/actionTypes";
import DropdownMenu from '@/components/DropdownMenu';
import Modal from '@/components/PopModal';
import RightPanel from '@/components/RightPanel';
import Pagination from '@/components/Pagination';
import { serializeErrors, validateFields, hasPermission } from '@/utils';
import './style.scss';

class Drafts extends React.Component {
  postRef = React.createRef();
  imgRef = React.createRef();

  state = {
    selectedPost: null,
    showConfirmModal: false,
    post: '',
    title: '',
    openPanel: false,
    category: '',
    headerImage: null,
    fileName: '',
    errors: null,
    saveLoading: false,
  }

  componentDidMount() {
    this.props.getCategories(1, 200);
    if (this.props.drafts) {
      this.updateSelected(this.props.drafts[0])
    }
    this.getDrafts(1, 10)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.drafts !== this.props.drafts) {
      this.updateSelected(this.props.drafts[0])
    }
  }

  getDrafts = (page, limit) => {
    this.props.getDrafts(page, limit)
  }

  updateSelected = (data) => {
    this.setState({ selectedPost: data }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`
      }
      if(this.state.selectedPost === undefined || !this.state.selectedPost) {
        this.postRef.current.innerHTML = null
      }
    })
  }

  selectPost = (post) => {
    this.setState({ selectedPost: post }, () => {
      if (this.state.selectedPost && this.postRef) {
        this.postRef.current.innerHTML = `${this.state.selectedPost?.content}`
      }
    })
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({ showConfirmModal: !prevState.showConfirmModal }))
  }

  handleCategoryChange = (event) => {
    const { value } = event.target
    this.setState({ category: parseInt(value,10) });
  }

  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click()
  }

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ fileName: target.files[0].name, headerImage: target.files[0] })
    }
  }

  handleDelete = () => {
    const { selectedPost } = this.state;
    const { deletePost } = this.props;
    deletePost(selectedPost.slug)
      .then(data => {
        this.toggleConfirmModal();
        this.props.getDrafts(1, 10)
        .then(data => {
          this.updateSelected(data?.data?.data[0])
        })
      })
      .catch(error => {
        this.toggleConfirmModal();
      })
  }

  handleEdit = () => {
    const { selectedPost } = this.state;
    const { history } = this.props;
    if(!selectedPost) return;
    history.push({
      pathname: `/dashboard/setup/blog/edit/${selectedPost.slug}`,
      state: {
        blogPost: selectedPost, pageTitle: 'Edit Post'
      }
    })
  }

  handleSubmitNewPost = (event) => {
    event.preventDefault();
    const { editBlogPost } = this.props;
    const { title, post, headerImage, category, selectedPost } = this.state;

    const contentBlock = htmlToDraft(post);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks); 
    const editorState = EditorState.createWithContent(contentState);
    const description = editorState.getCurrentContent().getPlainText();

    const formData = new FormData();
    formData.append('title', title)
    formData.append('content', post)
    formData.append('image', headerImage)
    formData.append('category', category)
    formData.append('description', description.substring(0, 200))
    formData.append('publish', true)

    editBlogPost(formData, selectedPost.slug ).then(data => {
      this.setState(prevState => ({ openPanel: !prevState.openPanel }));
      this.props.history.push('/dashboard/setup/blog/pending')
    });
  }

  openPanel = () => {
    const { selectedPost } = this.state;
    this.setState({ 
      errors: null,
      post: selectedPost.content === null ? '' : selectedPost.content,
      title: selectedPost.title === null ? '' : selectedPost.title,
      headerImage: selectedPost.image,
    }, () => {
      const data = this.state;
      const required = [ 'title', 'post' ];
      const errors = validateFields(data, required)

      if (Object.keys(errors).length > 0) {
        return this.setState({ errors });
      }
      this.setState(prevState => ({ openPanel: !prevState.openPanel }));
    });
  }

  closeNewPanel = (event) => {
    event.preventDefault();
    this.setState(prevState => ({ openPanel: !prevState.openPanel }));
  }

  handleEditItem = () => {
    const { selectedPost } = this.state;
    const { history } = this.props;
    if(!selectedPost) return;
    history.push({
      pathname: `/dashboard/setup/blog/edit/${selectedPost.slug}`,
      state: {
        blogPost: selectedPost, pageTitle: 'Edit Post'
      }
    })
  }

  render() {
    const { error, loading, drafts, removeLoading, categories, data, postLoading, permissions } = this.props;
    const { selectedPost, showConfirmModal, openPanel, errors, fileName } = this.state;
    const errorObject = serializeErrors(error);
    const canDelete = hasPermission(permissions, 'delete_service');
    const canUpdate = hasPermission(permissions, 'update_service');

    const menu = [
      canUpdate && {
        name: 'Edit blog',
        handler: this.handleEditItem,
      },
      {
        name: 'Publish blog',
        handler: this.openPanel,
      },
      canDelete && {
        name: 'Delete Blog',
        handler: this.toggleConfirmModal
      }
    ]

    return (
      <div className="drafts-page">
        {showConfirmModal &&
          <Modal onClose={this.toggleConfirmModal}>
            <div className="text-center">
                <h3>Confirm</h3>
                <p>Are you sure you want to delete this blog post?</p>
                <div className="d-flex">
                <button className="btn btn-sm btn-white btn-block mt-3 mr-2" onClick={() => this.setState({ showConfirmModal: false })}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center" onClick={this.handleDelete}>
                  Confirm
                  {removeLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
                </div>
              </div>
          </Modal>
        }
        <RightPanel
          open={openPanel}
          onClose={this.closeNewPanel}
        >
          <h4 className="panel-heading">Publish blog</h4>
          <form onSubmit={this.handleSubmitNewPost}>
            <label className="panel-label" htmlFor="new-investment-type">What category will this be under?</label>
            <select
              className="form-control panel-input mb-0"
              placeholder="choose investment type"
              name="type"
              onChange={this.handleCategoryChange}
            >
              <option>select category</option>
              {categories && categories.map(category => (
                <option value={category.id} key={category.id}>{category.name}</option>
              ))}
            </select>
            <p className="text-error">{errors ? errors.category : (errorObject && errorObject['category'])}</p>

            <label className="panel-label mt-4" htmlFor="new-investment-icon">Header image</label>
            <div
              className="img-input"
              id="new-investment-icon"
            >
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input type="file" className="file" ref={this.imgRef} accept="image/*" onChange={this.handleImageSelect} />
                  <button className="bg-white pl-4 pr-4" onClick={this.handleFileClick}>
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload PDF, JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">{errors ? errors.headerImage : (errorObject && errorObject['image'])}</p>
            </div>
            <p className="text-error">{errors ? errors.title : (errorObject && errorObject['title'])}</p>
            <p className="text-error">{errors ? errors.post : (errorObject && errorObject['content'])}</p>
            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.closeNewPanel}>
                  Cancel
              </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                  {postLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>

          </form>
        </RightPanel>
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getDrafts(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {drafts && drafts.length > 0 ? drafts.map(draft => (
                <p className="text-deep-blue font-md cursor-pointer text-capitalize pb-5" key={draft.id} onClick={() => this.selectPost(draft)}>
                  {draft.title}
                </p>
              ))
                : <p className="font-md text-deep-blue">No drafts</p>
              }
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedPost && selectedPost.title}
                  {drafts?.length < 1 && 'No Drafts'}
                </h2>
                {loading &&
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                }
                {selectedPost && 
                  <DropdownMenu menu={menu}>
                    <img src={require("@/assets/icons/flat-ellipsis.svg")} alt="dropdown" />
                  </DropdownMenu>
                }
              </div>
              <div className="post-container p-4">
                {this.postRef ? 
                  <div ref={this.postRef} className="mt-3"></div>
                  :
                  <p>No Data</p>
                }
                <p className="text-error">{errors && errors.post ? 'Please edit post to add content' : (errorObject && errorObject['content'])}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app: { blog: { error, data, drafts, categories } }, user: { permissions } } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_DRAFTS_REQUEST),
    removeLoading: getActionLoadingState(state, actionTypes.DELETE_BLOG_POST_REQUEST),
    postLoading: getActionLoadingState(state, actionTypes.EDIT_BLOG_POST_REQUEST),
    error,
    data,
    drafts,
    categories,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (page, limit) => dispatch(getCategories(page, limit)),
    getDrafts: (page, limit) => dispatch(getDrafts(page, limit)),
    deletePost: (slug) => dispatch(deletePost(slug)),
    editBlogPost: (payload, slug) => dispatch(editBlogPost(payload, slug)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drafts));
