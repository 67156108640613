module.exports = {
    GET_ADMIN_REQUEST: "adminUser/GET_ADMIN_REQUEST",
    GET_ADMIN_ERROR: "adminUser/GET_ADMIN_ERROR",
    GET_ADMIN_SUCCESS: "adminUser/GET_ADMIN_SUCCESS",
    ADMIN_PAGE_REQUEST: "adminUser/ADMIN_PAGE_REQUEST",
    ADMIN_PAGE_ERROR: "adminUser/ADMIN_PAGE_ERROR",
    ADMIN_PAGE_SUCCESS: "adminUser/ADMIN_PAGE_SUCCESS",
    GET_ROLE_REQUEST: "role/GET_ROLE_REQUEST",
    GET_ROLE_ERROR: "role/GET_ROLE_ERROR",
    GET_ROLE_SUCCESS: "role/GET_ROLE_SUCCESS",
    FETCH_ROLES_ERROR: "role/FETCH_ROLES_ERROR",
    ROLE_PAGE_REQUEST: "role/ROLE_PAGE_REQUEST",
    ROLE_PAGE_ERROR: "role/ROLE_PAGE_ERROR",
    ROLE_PAGE_SUCCESS: "role/ROLE_PAGE_SUCCESS",
    CONFIG_PAGE_REQUEST: "config/CONFIG_PAGE_REQUEST",
    CONFIG_PAGE_SUCCESS: "config/CONFIG_PAGE_SUCCESS",
    CONFIG_PAGE_ERROR: "config/CONFIG_PAGE_ERROR",
    GET_CONFIG_REQUEST: "config/GET_CONFIG_REQUEST",
    GET_CONFIG_SUCCESS: "config/GET_CONFIG_SUCCESS",
    GET_CONFIG_ERROR: "config/GET_CONFIG_ERROR",
    SEGMENTS_PAGE_REQUEST: "segments/SEGMENTS_PAGE_REQUEST",
    SEGMENTS_PAGE_SUCCESS: "segments/SEGMENTS_PAGE_SUCCESS",
    SEGMENTS_PAGE_ERROR: "segments/SEGMENTS_PAGE_ERROR",
    GET_SEGMENT_REQUEST: "segments/GET_SEGMENT_REQUEST",
    GET_SEGMENT_SUCCESS: "segments/GET_SEGMENT_SUCCESS",
    GET_SEGMENT_ERROR: "segments/GET_SEGMENT_ERROR",
    GET_MUTUAL_FUNDS_REQUEST: "mutualFunds/GET_MUTUAL_FUNDS_REQUEST",
    GET_MUTUAL_FUNDS_ERROR: "mutualFunds/GET_MUTUAL_FUNDS_ERROR",
    GET_MUTUAL_FUNDS_SUCCESS: "mutualFunds/GET_MUTUAL_FUNDS_SUCCESS",
    MUTUAL_FUNDS_PAGE_REQUEST: "mutualFunds/MUTUAL_FUNDS_PAGE_REQUEST",
    MUTUAL_FUNDS_PAGE_ERROR: "mutualFunds/MUTUAL_FUNDS_PAGE_ERROR",
    MUTUAL_FUNDS_PAGE_SUCCESS: "mutualFunds/MUTUAL_FUNDS_PAGE_SUCCESS",
    GET_SINGLE_MUTUAL_FUND_REQUEST: "singleMutualFund/GET_SINGLE_MUTUAL_FUND_REQUEST",
    GET_SINGLE_MUTUAL_FUND_ERROR: "singleMutualFund/GET_SINGLE_MUTUAL_FUND_ERROR",
    GET_SINGLE_MUTUAL_FUND_SUCCESS: "singleMutualFund/GET_SINGLE_MUTUAL_FUND_SUCCESS",
    GET_FAQ_REQUEST: "faq/GET_FAQ_REQUEST",
    GET_FAQ_SUCCESS: "faq/GET_FAQ_SUCCESS",
    GET_FAQ_ERROR: "faq/GET_FAQ_ERROR",
    FAQ_PAGE_REQUEST: "faq/FAQ_PAGE_REQUEST",
    FAQ_PAGE_SUCCESS: "faq/FAQ_PAGE_SUCCESS",
    FAQ_PAGE_ERROR: "faq/FAQ_PAGE_ERROR",
    GET_PUSH_NOTIFICATION_REQUEST: "pushNotification/GET_PUSH_NOTIFICATION_REQUEST",
    GET_PUSH_NOTIFICATION_SUCCESS: "pushNotification/GET_PUSH_NOTIFICATION_SUCCESS",
    GET_PUSH_NOTIFICATION_ERROR: "pushNotification/GET_PUSH_NOTIFICATION_ERROR",
    PUSH_NOTIFICATION_PAGE_REQUEST: "pushNotification/PUSH_NOTIFICATION_PAGE_REQUEST",
    PUSH_NOTIFICATION_PAGE_SUCCESS: "pushNotification/PUSH_NOTIFICATION_PAGE_SUCCESS",
    PUSH_NOTIFICATION_PAGE_ERROR: "pushNotification/PUSH_NOTIFICATION_PAGE_ERROR",
}
