import React from 'react';
import './style.scss';
import CPLogo from '@/assets/images/cp-logo-white.png';

const AdminAuthNav = () => {

  return (
    <div className="admin-auth-nav">
      <div className="container">
        <div className="d-flex justify-content-between">
        <div className="logo">
            <img src={CPLogo} alt="logo" className="cp-logo" />
        </div>
      </div>
      </div>
      
    </div>
  )
}

export default AdminAuthNav;
