import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { currency } from "@/utils";
import { getTransactionChartOptions } from "@/utils/index";
import { fetchMaturitiesAnalytics } from "@/store/analytics/termedInvestments";
import ExportDropdown from "@/components/ExportDropdown";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import MaturitiesAnalyticsDate from "@/components/MaturitiesAnalyticsDate";

class Maturities extends Component {
  state = {
    maturitiesDue: null,
    maturitiesByMonth: null,
  };
  componentDidMount() {
    this.fetchDueAndValuation();
    this.fetchDueByMonth();
  }
  fetchDueAndValuation = () => {
    const startDate = this.props.maturityStartDate
      ? dayjs(this.props.maturityStartDate).format("YYYY-MM-DD")
      : "";
    const endDate = this.props.maturityEndDate
      ? dayjs(this.props.maturityEndDate).format("YYYY-MM-DD")
      : "";
    const date = this.props.date
      ? dayjs(this.props.date).format("YYYY-MM-DD")
      : "";
    this.props
      .fetchMaturitiesAnalytics(
        "maturity-due-and-valuation",
        date,
        startDate,
        endDate
      )
      .then((values) =>
        this.setState({
          maturitiesDue: values.data.maturityDueAndValuation,
        })
      );
  };

  fetchDueByMonth = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");
    const startDate = this.props.maturityStartDate
      ? dayjs(this.props.maturityStartDate).format("YYYY-MM-DD")
      : "";
    const endDate = this.props.maturityEndDate
      ? dayjs(this.props.maturityEndDate).format("YYYY-MM-DD")
      : "";
    this.props
      .fetchMaturitiesAnalytics(
        "maturity-due-by-month",
        date,
        startDate,
        endDate
      )
      .then((values) => {
        this.setState({
          maturitiesByMonth: values.data.analytics,
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.maturityStartDate !== prevProps.maturityStartDate) {
      this.fetchDueAndValuation();
    }
    if (this.props.maturityEndDate !== prevProps.maturityEndDate) {
      this.fetchDueAndValuation();
    }
    if (this.props.date !== prevProps.date) {
      this.fetchDueByMonth();
    }
  }

  maturitiesChartData = () => {
    const monthdata = this.state.maturitiesByMonth;
    const labels = monthdata
      ? this.state.maturitiesByMonth.maturityDueByMonth.labels
      : [];
    const datas = monthdata
      ? this.state.maturitiesByMonth.maturityDueByMonth.data
      : [];
    const monthcolor = monthdata
      ? this.state.maturitiesByMonth.maturityDueByMonth.monthBackgroundColor
      : [];
    return {
      labels: [...labels],
      datasets: [
        {
          data: [...datas],
          backgroundColor: [...monthcolor],
        },
      ],
    };
  };

  render() {
    const { maturitiesDue, maturitiesByMonth } = this.state;
    const { date, handleChange, maturityEndDate, maturityStartDate } =
      this.props;

    return (
      <div id="page-container">
        {!maturitiesDue ? (
          <div className="investment-summary">
            <div className="text-center investment-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
            <div className="text-center investment-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="print-all">
              <MaturitiesAnalyticsDate value={maturityStartDate} />
              <div className="analytics-date-to">To</div>
              <MaturitiesAnalyticsDate value={maturityEndDate} />
              <ExportDropdown
                module="termed-investments"
                status="maturities"
                excludeCSV
                includeJpeg
              />
            </div>
            <div className="investment-summary">
              <div className="investment-summary-card">
                <span className="investment-summary-card__title">
                  Maturities Due
                </span>
                <span className="investment-summary-card__total-left">
                  {maturitiesDue.maturityDue}
                </span>
              </div>
              <div className="investment-summary-card">
                <span className="withdrawal-summary-card__title">
                  Aggregate Investment Values/Valuation Per Day
                </span>
                <span className="investment-summary-card__total-right">
                  {`N${currency(maturitiesDue.totalInvestmentValue, true)}`}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="main-chart">
          <div
            className="setup-inner__main p-5 analytics-graph"
            id="graph-print"
          >
            <div className="text-center">
              <h5 className="text-medium">Maturities Due Per Month</h5>
              <MaturitiesAnalyticsDate
                value={date}
                handleChange={handleChange}
                minDate={new Date()}
              />
              <div className="pb-4"></div>
            </div>
            {!maturitiesByMonth ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                data={this.maturitiesChartData}
                options={getTransactionChartOptions(
                  "Value of Investments",
                  "Month"
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaturitiesAnalytics: (view, date, startDate, endDate) =>
      dispatch(fetchMaturitiesAnalytics(view, date, startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(Maturities);
