import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { getTransactionChartOptions } from "@/utils/index";
import { fetchInvestmentValueAnalytics } from "@/store/analytics/usersAnalyticsAction";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";

class InvestmentValue extends Component {
  state = {
    ageInvestment: null,
    genderInvestment: null,
  };

  componentDidMount() {
    this.fetchAgeInvestment();
    this.fetchGenderInvestment();
  }

  formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  fetchAgeInvestment = () => {
    const { ageDates } = this.props;
    if (!ageDates[1]) return;
    this.props
      .fetchInvestmentValueAnalytics(
        "age",
        this.formatDate(ageDates[0]),
        this.formatDate(ageDates[1])
      )
      .then((ageData) => {
        this.setState({ ageInvestment: ageData.data.investmentByAge });
      });
  };

  fetchGenderInvestment = () => {
    const { genderDates } = this.props;
    if (!genderDates[1]) return;
    this.props
      .fetchInvestmentValueAnalytics(
        "gender",
        this.formatDate(genderDates[0]),
        this.formatDate(genderDates[1])
      )
      .then((genderData) => {
        this.setState({
          genderInvestment: genderData.data.investmentByGender,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ageDates !== prevProps.ageDates) {
      this.fetchAgeInvestment();
    }
    if (this.props.genderDates !== prevProps.genderDates) {
      this.fetchGenderInvestment();
    }
  }

  ageData = () => ({
    labels: [...this.state.ageInvestment.label],
    datasets: [
      {
        data: [...this.state.ageInvestment.data],
        backgroundColor: [
          "#9DC6FB",
          "#C8EA73",
          "#F4A28C",
          "#A0A2EC",
          "#9DC6FB",
          "#FFCEBF",
        ],
      },
    ],
  });

  genderData = () => ({
    labels: [...this.state.genderInvestment.label],
    datasets: [
      {
        data: [...this.state.genderInvestment.data],
        backgroundColor: ["#9DC6FB", "#F4A28C", "#A0A2EC"],
      },
    ],
  });

  render() {
    const { ageInvestment, genderInvestment } = this.state;

    const {
      ageDates,
      genderDates,
      handleAgeDateChange,
      handleGenderDateChange,
    } = this.props;
    return (
      <div id="users-container">
        <div className="setup-inner__main p-5 analytics-graph" id="users-print">
          <div className="text-center">
            <h5 className="text-medium">
              Value of Investments by Age Range as at{" "}
            </h5>
            <AnalyticsDateInput
              value={ageDates[0]}
              isRange={true}
              startDate={ageDates[0]}
              endDate={ageDates[1]}
              handleChange={(date) => handleAgeDateChange(date)}
            />
          </div>
          {!ageInvestment ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={this.ageData}
              options={getTransactionChartOptions(
                "Value of Investment (N)",
                "Age Range"
              )}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 mt-5 analytics-graph"
          id="users-print"
        >
          <div className="text-center">
            <h5 className="text-medium">
              Value of Investments by Gender as at
            </h5>
            <AnalyticsDateInput
              value={genderDates[0]}
              isRange
              startDate={genderDates[0]}
              endDate={genderDates[1]}
              handleChange={(date) => handleGenderDateChange(date)}
            />
          </div>
          {!genderInvestment ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={this.genderData}
              options={getTransactionChartOptions(
                "Value of Investment (N)",
                "Gender"
              )}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvestmentValueAnalytics: (view, startDate, endDate) =>
      dispatch(fetchInvestmentValueAnalytics(view, startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(InvestmentValue);
