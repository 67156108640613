import React from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getFundsBystatus } from "@/store/ppi/actions";
import actionTypes from "@/store/ppi/actionTypes";
import Pagination from '@/components/Pagination';
import Emptystate from '@/components/Emptystate';
import './style.scss';

class PrivatePlacements extends React.Component {

  componentDidMount() {
    this.getPrivatePlacements(1, 10)
  }

  getPrivatePlacements = (page, limit) => {
    const payload = {
      category: 'private-placement',
      page, limit,
      action: 'click'
    }
    this.props.getFundsBystatus(payload)
  }

  handleSelectItem = (itemId) => {
    const { history } = this.props;
    history.push({
      pathname: `/dashboard/reports/ppi/${itemId}/report`,
      state: { pageTitle: 'Report' },
    })
  }

  render() {
    const { loading, status } = this.props;

    return (
      <div className="private-ppi-page">
        <Pagination
          totalPages={status?.lastPage}
          page={status?.page}
          limit={status?.perPage}
          changePageHandler={(page, limit) => this.getPrivatePlacements(page, limit)}
        />
        {!status && loading &&
          <div className="text-center p-4">
            <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
          </div>
        }
        {status && (
          status?.data?.length > 0 ?
            <>
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Title</th>
                      <th>Date Listed</th>
                      <th>Status</th>
                      <th>Summary of Clicks</th>
                      <th>Unique Customer</th>
                    </tr>
                    {status?.data.map(item => (
                      <tr key={item.id}>
                        <td onClick={() => this.handleSelectItem(item.id)}><span className="color-dark-blue cursor-pointer">{item.title}</span></td>
                        <td>{moment(item.created_at).format('ddd Do MMM, yyyy')}</td>
                        <td><span>{item.status}</span></td>
                        <td>{item.summary}</td>
                        <td>{item.uniqueCustomers}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">Showing {status?.data?.length} of {status?.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
            :
            <Emptystate
              title="No Private placement"
              icon={require('@/assets/icons/info.svg')}
            />)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app:
    {
      ppi: {
        status
      }
    }
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_FUNDS_BY_STATUS_REQUEST),
    status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFundsBystatus: (payload) => dispatch(getFundsBystatus(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivatePlacements));
