import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar, Pie } from "react-chartjs-2";
import { getTransactionChartOptions, pieOptions } from "@/utils/index";
import { fetchDepositAnalytics } from "@/store/analytics/transactionAnalyticsAction";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import ExportDropdown from "@/components/ExportDropdown";
import { currency } from "@/utils";

class Deposits extends Component {
  state = {
    monthDeposits: null,
    typeDeposits: null,
    totalAndAverageDeposit: null,
  };

  componentDidMount() {
    this.fetchMonthDeposits();
    this.fetchTypeDeposits();
    this.fetchTotalAndAverageDeposits();
  }
  formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  fetchMonthDeposits = () => {
    const { monthDates } = this.props;
    if (!monthDates[1]) return;
    this.props
      .fetchDepositAnalytics(
        "total-deposit-distribution-by-month",
        this.formatDate(monthDates[0]),
        this.formatDate(monthDates[1])
      )
      .then((monthData) => {
        const newdata = monthData.data.totalDepositsDistributionByMonths;
        this.setState({
          monthDeposits: newdata,
        });
      });
  };

  fetchTypeDeposits = () => {
    const { typeDates } = this.props;
    if (!typeDates[1]) return;
    this.props
      .fetchDepositAnalytics(
        "total-deposit-distribution-by-type",
        this.formatDate(typeDates[0]),
        this.formatDate(typeDates[1])
      )
      .then((typeData) => {
        const newdata = typeData.data.totalDepositsDistributionByType;

        this.setState({
          typeDeposits: newdata,
        });
      });
  };
  fetchTotalAndAverageDeposits = () => {
    const { totalAndAverageDate } = this.props;
    if (!totalAndAverageDate[1]) return;
    this.props
      .fetchDepositAnalytics(
        "totalAndAverageDeposit",
        this.formatDate(totalAndAverageDate[0]),
        this.formatDate(totalAndAverageDate[1])
      )
      .then((totalAndAverage) => {
        this.setState({
          totalAndAverageDeposit: totalAndAverage.data.totalAndAverageDeposit,
        });
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.typeDates !== prevProps.typeDates) {
      this.fetchTypeDeposits();
    }
    if (this.props.monthDates !== prevProps.monthDates) {
      this.fetchMonthDeposits();
    }
    if (this.props.totalAndAverageDate !== prevProps.totalAndAverageDate) {
      this.fetchTotalAndAverageDeposits();
    }
  }

  monthData = () => {
    const monthdata = this.state.monthDeposits;
    const labels = monthdata ? this.state.monthDeposits.labels : [];
    const datas = monthdata ? this.state.monthDeposits.data : [];
    const monthcolor =
      monthdata && monthdata.monthBackgroundColor
        ? monthdata.monthBackgroundColor
        : ["#A0ECA8", "#F48CEA"];

    return {
      labels: [...labels],
      datasets: [
        {
          data: [...datas],
          backgroundColor: [...monthcolor],
        },
      ],
    };
  };

  typeData = () => {
    const typedata = this.state.typeDeposits;
    const labels = typedata ? this.state.typeDeposits.labels : [];
    const datas = typedata ? this.state.typeDeposits.data : [];
    const typecolor =
      typedata && typedata.typeBackgroundColor
        ? typedata.typeBackgroundColor
        : ["#A0ECA8", "#F48CEA"];
    return {
      labels: [...labels],
      legend: {
        display: true,
        position: "left",
      },
      datasets: [
        {
          data: [...datas],
          backgroundColor: [...typecolor],
        },
      ],
    };
  };

  render() {
    const { monthDeposits, typeDeposits, totalAndAverageDeposit } = this.state;
    const {
      monthDates,
      typeDates,
      handleMonthDateChange,
      handleTypeDateChange,
      totalAndAverageDate,
    } = this.props;

    return (
      <div id="transaction-container">
        {!totalAndAverageDeposit ? (
          <div className="deposit-summary">
            <div className="text-center deposit-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
            <div className="text-center deposit-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="print-all">
              <AnalyticsDateInput
                value={totalAndAverageDate[0]}
                isRange={true}
                startDate={totalAndAverageDate[0]}
                endDate={totalAndAverageDate[1]}
                handleChange={(date) => this.handleTandAChange(date)}
              />

              <ExportDropdown
                module="transactions"
                status="deposits"
                excludeCSV
              />
            </div>
            <div className="deposit-summary">
              <div className="deposit-summary-card">
                <span className="deposit-summary-card__title">
                  Total Deposits
                </span>
                <span className="deposit-summary-card__total">
                  {`N${currency(totalAndAverageDeposit.totalDeposit, true)}`}
                </span>
              </div>
              <div className="deposit-summary-card">
                <span className="deposit-summary-card__title">
                  Average Deposit
                </span>
                <span className="deposit-summary-card__total">
                  {`N${currency(
                    totalAndAverageDeposit.totalAverageDeposit,
                    true
                  )}`}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="main-chart" id="transaction-print">
          <div className="setup-inner__main p-5 analytics-graph">
            <div className="text-center">
              <h5 className="text-medium">Deposit Value Per Month</h5>
              <div className="pb-4">
                <AnalyticsDateInput
                  value={monthDates[0]}
                  isRange={true}
                  startDate={monthDates[0]}
                  endDate={monthDates[1]}
                  handleChange={(date) => handleMonthDateChange(date)}
                />
              </div>
            </div>
            {!typeDeposits ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                abbreviateY
                data={this.monthData}
                options={getTransactionChartOptions("Value", "Month")}
              />
            )}
          </div>
        </div>

        <div className="setup-inner__main pie-container p-5 mt-5 analytics-graph">
          <div className="text-center">
            <h5 className="text-medium">Value Of Deposits By Type</h5>
          </div>
          {!monthDeposits ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <div>
              <div className="transaction-pie">
                <Pie
                  data={this.typeData}
                  options={pieOptions("card", "bank")}
                />
              </div>
              <div className="deposit-pie">
                <AnalyticsDateInput
                  value={typeDates[0]}
                  isRange={true}
                  startDate={typeDates[0]}
                  endDate={typeDates[1]}
                  handleChange={(date) => handleTypeDateChange(date)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDepositAnalytics: (view, startDate, endDate) =>
      dispatch(fetchDepositAnalytics(view, startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(Deposits);
