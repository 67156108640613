import React from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

const Back = ({ url, ...props }) => {

  const handleBack = () => {
    if(url) {
      return props.history.push(url)
    }
    props.history.goBack();
  }

  return (
    <div onClick={handleBack} className="go-back">
      <img
        src={require("@/assets/icons/back-arrow.svg")}
        alt="transfer logo"
        className="mr-2"
      />
      Back
    </div>
  )
}

export default withRouter(Back);
