import React, { Component } from "react";
import "./style.scss";
import refresh from "@/assets/icons/replay_white_24dp.svg";

export default class index extends Component {
  reloadAll = () => {
    window.location.reload();
  };
  render() {
    return (
      <div>
        <button
          style={{
            backgroundImage: `url(${refresh})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left 6px top 6px",
          }}
          onClick={this.reloadAll}
        >
          <span className="refresh">Refresh</span>
        </button>
      </div>
    );
  }
}

