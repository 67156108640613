import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createOrEditMutualFund,
  fetchSingleMutualFund,
} from "@/store/setup/mutualFunds";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import actionTypes from "@/store/setup/actionTypes";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import FormBuilderMenu from "@/components/FormBuilderMenu";
import TextEditor from "@/components/TextEditor";
import fields from "./fields";
import InputError from "@/components/InputError";
import { serializeErrors } from "@/utils";
import Compressor from "compressorjs";
import "react-datepicker/dist/react-datepicker.css";

class Create extends Component {
  state = {
    isFormBuilder: false,
    isBuildingForm: false,
    step: 1,
    createdForms: [],
    document: [],
    description: "",
    instruction:
      "Orders must be made in accordance with the instructions set out in the Private Placement Memorandum. Investors must carefully follow all instructions as applications which do not comply with the instruction may be rejected. If in any doubt, consult your Stockbroker, Accountant, Banker, Solicitor or any professional adviser for guidance.",
    hasInstruction: true,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.fetchSingleMutualFund(id).then((mutualFund) => {
        this.setState({
          ...mutualFund,
          startDate: dayjs(mutualFund.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(mutualFund.endDate).format("YYYY-MM-DD"),
          document: [],
          createdForms: mutualFund.formBuilder || [],
        });
      });
    }
  }

  onInputChange = (e) => {
    if (e.target.name === "image") {
      new Compressor(e.target.files?.[0], {
        quality: 1,
        convertSize: 1000000,
        maxHeight: 400,
        maxWidth: 400,
        resize: "contain",
        success: (compressedFile) => {
          compressedFile = new File([compressedFile], compressedFile.name);
          this.setState({
            image: compressedFile,
          });
        },
      });
    } else {
      this.setState({ [e.target.name]: e.target.files?.[0] ?? e.target.value });
    }
  };

  onFormBuilderChange = (e) => {
    this.setState({
      isFormBuilder: e.target.checked,
      createdForms: [],
    });
  };

  abort = () => {
    if (this.state.isBuildingForm) {
      return this.setState({ isBuildingForm: false });
    }
    this.props.history.goBack();
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      summary,
      description,
      category,
      tags,
      startDate,
      endDate,
      isFormBuilder,
      createdForms,
      document,
      image,
      instruction,
    } = this.state;
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;

    const payload = {
      title,
      summary,
      description,
      category,
      tags,
      startDate,
      endDate,
      isFormBuilder,
      image: this.state.image,
    };
    if (!this.state.isFormBuilder) {
      payload.document = document;
      this.props.createOrEditMutualFund(payload, id, history);
      return;
    }
    if (this.state.createdForms.length) {
      payload.formBuilder = JSON.stringify(createdForms);
      payload.instructions = instruction;
      this.props.createOrEditMutualFund(payload, id, history);
    } else {
      this.setState({ isBuildingForm: true });
    }
  };

  createForm = (e) => {
    e.preventDefault();
    if (this.state.instruction.trim().length < 5) {
      return this.setState({ hasInstruction: false });
    }
    this.setState({ isBuildingForm: false });
  };

  addField = (field) => {
    const { createdForms } = this.state;
    if (!createdForms.find((element) => element.title === field.title)) {
      this.setState({ createdForms: [...createdForms, field] });
    }
  };

  removeField = (index) => {
    const newCreatedFormsArray = this.state.createdForms;
    newCreatedFormsArray.splice(index, 1);
    this.setState({ createdForms: newCreatedFormsArray });
  };

  onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    this.setState({
      document: [...this.state.document, selectedFile],
    });
  };

  removeFile = (index) => {
    const files = this.state.document;
    files.splice(index, 1);
    this.setState({ document: files });
  };

  setDate = (name, value) => {
    this.setState({ [name]: dayjs(value).format("YYYY-MM-DD") });
  };

  render() {
    const {
      isFormBuilder,
      isBuildingForm,
      createdForms,
      document,
      title,
      hasInstruction,
    } = this.state;
    const { isLoading, pageRequestError, fetching } = this.props;
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const { id } = this.props.match.params;

    return fetching ? (
      <div className="text-center text-primary min-view-height">
        <div className="spinner-border mt-5" role="status"></div>
      </div>
    ) : (
      <div className="setup-inner">
        <h4 className="panel-heading text-center">{`${
          id ? "Edit" : "Create"
        } New Offers`}</h4>
        <form
          className="custum-form"
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          {isBuildingForm ? (
            <div className="custum-form__builder">
              <FormBuilderMenu fields={fields} onFieldClick={this.addField} />
              <div className="custum-form__row custum-form__row--instruction">
                <label className="custum-form__label" htmlFor="instruction">
                  Instruction
                </label>
                <textarea
                  id="instruction"
                  name="instruction"
                  placeholder="Provide instruction for customers"
                  className={`custum-form__field ${
                    !hasInstruction && "error-input"
                  }`}
                  rows="6"
                  required
                  defaultValue={this.state.instruction}
                  onChange={this.onInputChange}
                />
                <span className="custum-form__trash invisible">
                  <img
                    src={require("@/assets/icons/trash-can.svg")}
                    alt="trash can"
                  />
                  Remove field
                </span>
                {!hasInstruction && (
                  <InputError error="instruction is required" />
                )}
              </div>
              {createdForms.map((field, index) => (
                <div className="custum-form__row" key={field.name}>
                  <label className="custum-form__label">{field.title}</label>
                  <input
                    type={field.type}
                    className="custum-form__field custum-form__input"
                    placeholder={field.placeholder}
                    disabled
                  />
                  <span
                    className="custum-form__trash"
                    onClick={() => this.removeField(index)}
                  >
                    <img
                      src={require("@/assets/icons/trash-can.svg")}
                      alt="trash can"
                    />
                    Remove field
                  </span>
                </div>
              ))}{" "}
            </div>
          ) : (
            <>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.title ? "error-input" : ""
                  }`}
                  placeholder="New Private Placement/Public Issue"
                  id="title"
                  name="title"
                  required
                  value={title || ""}
                  onChange={this.onInputChange}
                />
                <InputError error={createErrorObject.title} />
              </div>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="category">
                  Category
                </label>
                <select
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.category ? "error-input" : ""
                  }`}
                  id="category"
                  name="category"
                  value={this.state.category || ""}
                  onChange={this.onInputChange}
                  required
                >
                  <option value="">Select category</option>
                  <option value="Public Issue">Public Issue</option>
                  <option value="Private Placement">Private Placement</option>
                </select>
                <InputError error={createErrorObject.category} />
              </div>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="startDate">
                  Offer opening date
                </label>
                <DatePicker
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.startDate ||
                    createErrorObject.isNotValidStartDate
                      ? "error-input"
                      : ""
                  }`}
                  dayClassName={() => "react-datepicker-dates"}
                  minDate={new Date()}
                  selected={
                    this.state.startDate ? new Date(this.state.startDate) : null
                  }
                  onChange={(date) => this.setDate("startDate", date)}
                  id="startDate"
                  placeholderText="Select start date"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                />
                <InputError
                  error={
                    createErrorObject.startDate ||
                    createErrorObject.isNotValidStartDate
                  }
                />
              </div>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="endDate">
                  Offer closing date
                </label>
                <DatePicker
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.startDate ||
                    createErrorObject.isNotValidStartDate
                      ? "error-input"
                      : ""
                  }`}
                  dayClassName={() => "react-datepicker-dates"}
                  minDate={
                    this.state.startDate
                      ? new Date(dayjs(this.state.startDate).add(1, "day"))
                      : new Date(dayjs().add(1, "day"))
                  }
                  selected={
                    this.state.endDate ? new Date(this.state.endDate) : null
                  }
                  onChange={(date) => this.setDate("endDate", date)}
                  id="endDate"
                  placeholderText="Select end date"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                />
                <InputError
                  error={
                    createErrorObject.endDate ||
                    createErrorObject.isNotValidEndDate
                  }
                />
              </div>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="summary">
                  Summary
                </label>
                <input
                  type="text"
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.summary ? "error-input" : ""
                  }`}
                  placeholder="Add summary"
                  id="summary"
                  name="summary"
                  required
                  value={this.state.summary || ""}
                  onChange={this.onInputChange}
                />
                <InputError error={createErrorObject.summary} />
              </div>
              {(!id || title) && (
                <div className="custum-form__row custum-form__row--textarea">
                  <label className="custum-form__label" htmlFor="description">
                    Description
                  </label>
                  <TextEditor
                    customClass={`custum-form__editor ${
                      createErrorObject.description
                        ? "custum-form__editor--error"
                        : ""
                    }`}
                    onChange={(description) => this.setState({ description })}
                    toolbarOptions={[
                      "history",
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "image",
                      "list",
                      "textAlign",
                    ]}
                    toolbarConfigs={{
                      inline: { options: ["bold", "italic", "underline"] },
                    }}
                    height="367px"
                    defaultValue={this.state.description}
                  />
                  <InputError error={createErrorObject.description} />
                </div>
              )}
              <div className="custum-form__row">
                <label className="custum-form__label">Image</label>
                <div
                  className={`custum-form__field custum-form__file-upload ${
                    createErrorObject.image ? "error-input" : ""
                  }`}
                >
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="image" className="cursor-pointer">
                    {this.state.image?.name || (
                      <>
                        <span className="text-blue mr-2">+ Click here</span>
                        to upload an image
                      </>
                    )}
                  </label>
                </div>
                <InputError error={createErrorObject.image} />
              </div>
              {!isFormBuilder && (
                <>
                  <div className="custum-form__row">
                    <label className="custum-form__label">
                      Offer document upload
                    </label>
                    <div
                      className={`custum-form__field custum-form__file-upload ${
                        createErrorObject.document ? "error-input" : ""
                      }`}
                    >
                      <input
                        type="file"
                        id="file"
                        accept=".pdf"
                        onChange={this.onSelectFile}
                      />
                      <label htmlFor="file" className="cursor-pointer">
                        <span className="text-blue mr-2">+ Click here</span>
                        {document.length
                          ? "to add another document"
                          : "to upload document"}
                      </label>
                    </div>
                    <InputError error={createErrorObject.document} />
                  </div>
                  {document.length > 0 && (
                    <div className="custum-form__file-list">
                      {document.map((file, index) => (
                        <span key={file.name + index}>
                          {" "}
                          {file.name}
                          <button
                            type="button"
                            onClick={() => this.removeFile(index)}
                          >
                            <img
                              src={require("@/assets/icons/circle-cancel.svg")}
                              alt="cancel icon"
                            />
                          </button>
                        </span>
                      ))}
                    </div>
                  )}
                </>
              )}
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="tag">
                  Tag
                </label>
                <input
                  type="text"
                  className={`custum-form__field custum-form__input ${
                    createErrorObject.tags ? "error-input" : ""
                  }`}
                  placeholder="Real estate"
                  id="tag"
                  name="tags"
                  required
                  value={this.state.tags || ""}
                  onChange={this.onInputChange}
                />
                <InputError error={createErrorObject.tags} />
              </div>
              <div className="custum-form__row">
                <label className="custum-form__label" htmlFor="builder">
                  Form Builder
                </label>
                <input
                  name="isFormBuilder"
                  className="custum-form__builder-input"
                  id="builder"
                  type="checkbox"
                  checked={isFormBuilder}
                  onChange={this.onFormBuilderChange}
                />
                <label htmlFor="builder"></label>
                {createdForms.length > 0 && (
                  <span
                    className="mx-4 text-blue cursor-pointer"
                    onClick={() => this.setState({ isBuildingForm: true })}
                  >
                    Update Form
                  </span>
                )}
              </div>
            </>
          )}
          <div className="dual-button-box col-md-6 mx-auto">
            <button
              type="button"
              onClick={this.abort}
              className="cp-button-blue color-red"
            >
              {isBuildingForm ? "Back" : "Cancel"}
            </button>
            {isBuildingForm ? (
              <button
                onClick={this.createForm}
                className={`cp-button-blue ${
                  createdForms.length === 0 ? "btn-disabled" : ""
                }`}
                disabled={createdForms.length === 0}
              >
                Create
              </button>
            ) : (
              <button className="cp-button-blue">
                {!isFormBuilder || createdForms.length > 0
                  ? "Request Approval"
                  : "Next"}{" "}
                {isLoading && (
                  <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: getActionLoadingState(
      state,
      actionTypes.MUTUAL_FUNDS_PAGE_REQUEST
    ),
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_SINGLE_MUTUAL_FUND_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.MUTUAL_FUNDS_PAGE_ERROR
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createOrEditMutualFund: (data, id = "", history) =>
      dispatch(createOrEditMutualFund(data, id, history)),
    fetchSingleMutualFund: (id) => dispatch(fetchSingleMutualFund(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create));
