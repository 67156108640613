import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchMutualFunds,
  searchMutualFunds,
  exportMutualFunds,
} from "@/store/Transactions/mutualFundsAction";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";

class MutualFunds extends Component {
  state = {
    mutualFunds: [],
    searchTerm: "",
    shouldSearch: false,
  };

  componentDidMount() {
    this.getMutualFunds(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: "", shouldSearch: false }, () =>
        this.getMutualFunds(1, 10)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchMutualFunds(1, 10, this.state.searchTerm);
    }
  }

  getMutualFunds = (page, limit) => {
    if (this.state.searchTerm)
      return this.searchMutualFunds(page, limit, this.state.searchTerm);
    const { type } = this.props.match.params;
    this.props.fetchMutualFunds(page, limit, type).then((mutualFunds) => {
      this.setPageData(mutualFunds.data);
    });
  };

  searchMutualFunds = (page, limit, searchTerm) => {
    if (!searchTerm) return this.getMutualFunds(1, 10);
    const { type } = this.props.match.params;
    this.props
      .searchMutualFunds(page, limit, type, searchTerm)
      .then((mutualFunds) => {
        this.setPageData(mutualFunds.data);
      });
  };

  setPageData = ({ lastPage, perPage, page, total, data: mutualFunds }) => {
    this.setState({ mutualFunds, lastPage, perPage, page, total });
  };

  onRowClick = (e, mutualFundId) => {
    this.props.history.push(`${mutualFundId}/performance/recent`);
  };

  renderMutualFunds = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Title</th>
            <th>Date Listed</th>
            <th>Status</th>
            <th>Summary of Clicks</th>
            <th>Unique Customer</th>
          </tr>
          {this.state.mutualFunds.map((mutualFund) => (
            <tr
              key={mutualFund.id}
              onClick={(e) => this.onRowClick(e, mutualFund.id)}
              className="cursor-pointer"
            >
              <td>{mutualFund.title}</td>
              <td>{dayjs(mutualFund.created_at).format("D MMM YYYY")}</td>
              <td>{mutualFund.status}</td>
              <td>{mutualFund.summary}</td>
              <td>{mutualFund.uniqueCustomers}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {
      match: {
        params: { type },
      },
      fetching,
    } = this.props;
    const { mutualFunds, lastPage, perPage, page, total, searchTerm } =
      this.state;

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "private-placement",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => type === "private-placement"}
                activeClassName="setup-nav__item--active"
              >
                Private Placement
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "public-issue",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => type === "public-issue"}
                activeClassName="setup-nav__item--active"
              >
                Public Issue
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={type}
          />
          <ExportDropdown
            module="mutualFunds"
            status={type}
            page={page}
            limit={perPage}
            downloadHandler={this.props.exportMutualFunds}
            hasMoreThanOnePage={lastPage > 1}
          />
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!mutualFunds.length ? (
              <Emptystate
                title="No New Offers"
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getMutualFunds(page, limit, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderMutualFunds()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${mutualFunds.length} of ${total}`}</span>
                    New Offers
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_MUTUAL_FUNDS_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMutualFunds: (page, limit, type) =>
      dispatch(fetchMutualFunds(page, limit, type)),
    searchMutualFunds: (page, limit, type, searchTerm) =>
      dispatch(searchMutualFunds(page, limit, type, searchTerm)),
    exportMutualFunds: (module, format, status, page, limit) =>
      dispatch(exportMutualFunds(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MutualFunds)
);
