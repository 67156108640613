import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import {
  getPendingInvestments,
  updateInvestmentStatus,
  removeInvestment,
} from "@/store/investments/actions";
import actionTypes from "@/store/investments/actionTypes";
import Emptystate from "@/components/Emptystate";
import RightPanel from "@/components/RightPanel";
import Pagination from "@/components/Pagination";
import ConfirmationBox from "@/components/ConfirmationBox";
import "./style.scss";

class PendingApproval extends React.Component {
  state = {
    selectedItem: null,
    open: false,
    showConfirmModal: false,
    showConfirmApprovalModal: false,
    itemId: "",
    approveItem: "",
  };

  componentDidMount() {
    this.props.getPendingInvestments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        open: false,
        showConfirmModal: false,
        showConfirmApprovalModal: false,
      });
    }
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.props.getPendingInvestments(10, 1, this.props.searchTerm);
    }
  }

  handleSelectItem = (itemId) => {
    const { data } = this.props;
    const foundItem = data?.investments?.data.find(
      (item) => item.id === itemId
    );

    this.setState({ selectedItem: foundItem }, () =>
      this.setState({ open: true })
    );
  };

  showConfirmApproval = (id) => {
    this.setState({ showConfirmApprovalModal: true, approveItem: id });
  };

  handleApprove = (id) => {
    const { approveItem } = this.state;
    this.props.updateInvestmentStatus(approveItem, { status: "active" });
  };

  showConfirm = (id) => {
    this.setState({ showConfirmModal: true, itemId: id });
  };

  handleReject = () => {
    const { itemId } = this.state;
    this.props.removeInvestment(itemId);
  };

  toggleModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  render() {
    const { loading, data, updateLoading, rejectLoading, searchTerm } =
      this.props;
    const { selectedItem, showConfirmModal, showConfirmApprovalModal } =
      this.state;
    const pendingData = data?.investments || data?.services;

    return (
      <div className="pending-approval-investments">
        <RightPanel
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <h4 className="panel-heading">{selectedItem && selectedItem.name}</h4>
          <div className="text-right border-bottom pb-5">
            <img
              src={selectedItem && selectedItem.icon}
              className="img-fluid"
              alt="user-img"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <p>Investment name</p>
            <p>
              <b>{selectedItem && selectedItem.name}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Investment type</p>
            <p>
              <b>{selectedItem && selectedItem.type}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Description</p>
            <p className="text-right">
              <b>{selectedItem && selectedItem.description}</b>
            </p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-sm w-50 mr-2 btn-white color-red"
              onClick={() => this.showConfirm(selectedItem.id)}
            >
              Reject
            </button>
            <button
              className="btn btn-sm w-50 ml-2 btn-primary"
              onClick={() => this.showConfirmApproval(selectedItem.id)}
            >
              Approve
              {updateLoading && (
                <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
              )}
            </button>
          </div>
        </RightPanel>
        <ConfirmationBox
          open={showConfirmModal}
          closeHandler={() => this.setState({ showConfirmModal: false })}
          title="Reject Investment"
          question="Are you sure you want to reject this investment?"
          action={this.handleReject}
          loading={rejectLoading}
        />
        <ConfirmationBox
          open={showConfirmApprovalModal}
          closeHandler={() =>
            this.setState({ showConfirmApprovalModal: false })
          }
          title="Approve Investment"
          question="Are you sure you want to approve this investment?"
          action={this.handleApprove}
          loading={updateLoading}
        />
        {loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary ml-auto mr-auto"></div>
          </div>
        ) : (
          data &&
          (pendingData?.data.length > 0 ? (
            <>
              <Pagination
                totalPages={pendingData.lastPage}
                page={pendingData.page}
                limit={pendingData.perPage}
                changePageHandler={(page, limit) =>
                  this.props.getPendingInvestments(limit, page, searchTerm)
                }
              />
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>Investment name</th>
                      <th>Description</th>
                      <th>Investment type</th>
                      <th>Action performed</th>
                      <th>Actions</th>
                    </tr>
                    {pendingData.data?.map((investment) => (
                      <tr key={investment.id}>
                        <td className="row-checkbox">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              this.props.onCheckRow(
                                e.target.checked,
                                investment.id
                              )
                            }
                          />
                        </td>
                        <td>{investment.id}</td>
                        <td
                          onClick={() => this.handleSelectItem(investment.id)}
                        >
                          <span className="color-dark-blue cursor-pointer">
                            {investment.name}
                          </span>
                        </td>
                        <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                          {investment.description}
                        </td>
                        <td>{investment.type}</td>
                        <td>{investment.action}</td>
                        <td
                          className="custum-table__button-cell"
                          style={{ maxWidth: "25rem" }}
                        >
                          <button
                            className="custum-table__button-blue"
                            onClick={() =>
                              this.showConfirmApproval(investment.id)
                            }
                          >
                            Approve
                          </button>
                          <button
                            className="custum-table__button-plain"
                            onClick={() => this.showConfirm(investment.id)}
                          >
                            Reject
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "1.4rem",
                  }}
                  className="text-center py-4"
                >
                  Showing{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {data?.investments?.data.length}
                  </span>{" "}
                  of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {data?.investments?.total}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <Emptystate
              title={`${
                searchTerm
                  ? "No Result Found"
                  : "No Investment Pending approval"
              }`}
              icon={require("@/assets/icons/info.svg")}
            />
          ))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: {
        pendingInvestments: { data, error },
        singleInvestment,
      },
    },
  } = state;
  return {
    loading: getActionLoadingState(
      state,
      actionTypes.FETCH_ALL_PENDING_INVESTMENTS_REQUEST
    ),
    updateLoading: getActionLoadingState(
      state,
      actionTypes.UPDATE_INVESTMENT_STATUS_REQUEST
    ),
    rejectLoading: getActionLoadingState(
      state,
      actionTypes.REMOVE_INVESTMENT_REQUEST
    ),
    error,
    data,
    singleInvestmentData: singleInvestment.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingInvestments: (limit, page, searchTerm) =>
      dispatch(getPendingInvestments(limit, page, searchTerm)),
    updateInvestmentStatus: (id, payload) =>
      dispatch(updateInvestmentStatus(id, payload)),
    removeInvestment: (id) => dispatch(removeInvestment(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingApproval)
);
