import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchTempProfile,
  approveOrRejectProfile,
  approveOrRejectMultipleProfile,
} from "@/store/Customers";
import actionTypes from "@/store/Customers/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import ConfirmationBox from "@/components/ConfirmationBox";
import RightPanel from "@/components/RightPanel";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import { camelCaseToString as formatString } from "@/utils";

class ProfileUpdate extends Component {
  state = {
    profiles: [],
    openPanel: false,
    selectedRowsIds: [],
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getProfile(1, 10, status);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { status } = this.props.match.params;
      this.setState({ selectedRowsIds: [] }, () =>
        this.getProfile(1, 10, status)
      );
    }
  }

  getProfile = (page, limit, status) => {
    this.props.fetchTempProfile(page, limit, status).then((data) => {
      this.setPageData(data.data);
    });
  };

  setPageData = ({ lastPage, perPage, page, total, users: profiles }) => {
    this.setState({ profiles, lastPage, perPage, page, total });
  };

  displayLink = (url, title) =>
    url ? (
      <a className="mb-2" href={url} target="_blank" rel="noopener noreferrer">
        {title ?? `document`}
      </a>
    ) : (
      "_ _"
    );

  renderModifications = (data) => {
    const modifications = { ...data };
    if (data.newProfile.politicalStatus?.politicalAssociate) {
      modifications.newProfile.politicalAssociate =
        data.newProfile.politicalStatus.politicalAssociate;
      modifications.oldProfile.politicalAssociate =
        data.oldProfile.politicalStatus?.politicalAssociate || {};
    }
    return Object.keys(modifications.newProfile).map((section) => {
      let main;
      if (section === "documents") {
        main = modifications.newProfile[section].map((field) => {
          const oldDataIndex = modifications.oldProfile[section]?.findIndex(
            (data) => field.category === data.category
          );
          const newData = this.displayLink(field.url, field.type);
          const oldData = this.displayLink(
            modifications.oldProfile[section]?.[oldDataIndex]?.["url"],
            modifications.oldProfile[section]?.[oldDataIndex]?.type
          );
          return this.renderChanges(
            field.category,
            field.category,
            oldData,
            newData
          );
        });
      } else {
        main = Object.keys(modifications.newProfile[section]).map((field) => {
          if (typeof modifications.newProfile[section][field] === "object")
            return "";
          const newData = formatString(
            modifications.newProfile[section][field]
          );
          const oldData = formatString(
            modifications.oldProfile[section][field]
          );
          return this.renderChanges(
            section + field,
            formatString(field),
            oldData,
            newData
          );
        });
      }
      return (
        <div key={section}>
          <p className="font-md text-bold text-capitalize">
            {formatString(section)} details
          </p>
          {main}
        </div>
      );
    });
  };

  renderChanges = (key, field, oldData, newData) => (
    <div className="panel-info__row" key={key}>
      <span className="font-md text-lowercase">{formatString(field)}</span>
      <span className="text-right ml-2">
        <span className="panel-info__field">Old data: {oldData}</span>
        <span className="d-block font-md text-bold">New data: {newData}</span>
      </span>
    </div>
  );

  removeFromList = (ids) => {
    let profiles;
    if (Array.isArray(ids)) {
      profiles = this.state.profiles.filter((data) => {
        const found = ids.find(
          (obj) => obj.id === data.id && obj.userId === data.user_id
        );
        return found ? false : true;
      });
    } else {
      profiles = [...this.state.profiles];
      const profileIndex = profiles.findIndex((profile) => profile.id === ids);
      profiles.splice(profileIndex, 1);
    }
    this.setState({
      profiles,
      selectedRowsIds: [],
      confirmationSuccess: true,
    });
  };

  updateAccount = (status, rejectionReason) => {
    const { userId, profileId, isMultipleApproval, selectedRowsIds } =
      this.state;
    if (isMultipleApproval) {
      this.props
        .approveOrRejectMultipleProfile(
          selectedRowsIds,
          status,
          rejectionReason
        )
        .then(() => {
          this.removeFromList(selectedRowsIds);
        });
    } else {
      this.props
        .approveOrRejectProfile(userId, profileId, status, rejectionReason)
        .then(() => {
          this.removeFromList(profileId);
        });
    }
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      approve: {
        title: `Approve update${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } update${addS ? "s" : ""}?`,
        action: () => this.updateAccount("approve"),
      },
      reject: {
        title: `Reason for rejecting update${addS ? "s" : ""}`,
        question: "",
        action: (rejectionReason) =>
          this.updateAccount("reject", rejectionReason),
        requiresReason: true,
      },
    };
  };

  setConfirmationContext = (context, userId, profileId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      userId,
      profileId,
      isMultipleApproval,
    });
  };

  onRowClick = (e, modifications) => {
    if (!["BUTTON"].includes(e.target.nodeName)) {
      this.setState({ modifications, openPanel: true });
    }
  };

  onCheckRow = (e, id, userId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push({ id, userId });
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((obj) => obj.id === id);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderProfiles = () => {
    const { status } = this.props.match.params;
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>Date of Change</th>
            <th>Customer Name</th>
            <th>Number of Modification</th>
            <th>Field Modification</th>
            {status !== "approved" && <th></th>}
          </tr>
          {this.state.profiles.map((profile) => (
            <tr
              key={profile.id}
              onClick={(e) => this.onRowClick(e, profile.compare)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    this.onCheckRow(e, profile.id, profile.user_id)
                  }
                />
              </td>
              <td>{dayjs(profile.created_at).format("D MMM YYYY")}</td>
              <td>{profile.customer}</td>
              <td>{profile.numberOfModification}</td>
              <td
                className="text-lowercase"
                style={{ maxWidth: "24rem", whiteSpace: "normal" }}
              >
                {profile.fields.map((field, index) =>
                  index > 0 ? `, ${formatString(field)}` : formatString(field)
                )}
              </td>
              {status !== "approved" ? (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext(
                        "approve",
                        profile.user_id,
                        profile.id
                      )
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext(
                        "reject",
                        profile.user_id,
                        profile.id
                      )
                    }
                  >
                    Reject
                  </button>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {};

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
    } = this.props;
    const {
      profiles,
      lastPage,
      perPage,
      page,
      total,
      confirmationContext,
      confirmationSuccess,
      openPanel,
      modifications,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />

        <RightPanel
          open={openPanel}
          onClose={() => this.setState({ openPanel: false })}
        >
          <h1 className="panel-heading">Field Modification</h1>
          <div className="panel-info">
            {openPanel && this.renderModifications(modifications)}
            <button
              className="long-button"
              onClick={() => this.setState({ openPanel: false })}
            >
              Close
            </button>
          </div>
        </RightPanel>

        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "pending", state: { pageTitle: "Users" } }}
                isActive={() => status === "pending"}
                activeClassName="setup-nav__item--active"
              >
                Pending
              </NavLink>
            </li>
            {/* <li className="setup-nav__item">
                            <NavLink  
                                to={{pathname: 'approved', state: { pageTitle: 'Users'}}}
                                isActive={() => status === 'approved'}
                                activeClassName="setup-nav__item--active"
                            >
                                Approved
                            </NavLink>
                        </li> */}
          </ul>
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approve", null, null, true)
              }
              reject={() =>
                this.setConfirmationContext("reject", null, null, true)
              }
            />
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!profiles.length ? (
              <Emptystate
                title="No profile updates"
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getProfile(page, limit, status)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderProfiles()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${profiles.length} of ${total}`}</span>
                    Users
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.CUSTOMERS_PAGE_REQUEST
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTempProfile: (page, limit, status) =>
      dispatch(fetchTempProfile(page, limit, status)),
    approveOrRejectProfile: (userId, profileId, status, rejectionReason) =>
      dispatch(
        approveOrRejectProfile(userId, profileId, status, rejectionReason)
      ),
    approveOrRejectMultipleProfile: (profiles, status, rejectionReason) =>
      dispatch(
        approveOrRejectMultipleProfile(profiles, status, rejectionReason)
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate)
);
