import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActionLoadingState } from "@/store/selectors";
import { getUserGuides, addGuideTopic, updateUserGuide, updateGuideCategory, deleteGuides } from "@/store/support/actions";
import actionTypes from "@/store/support/actionTypes";
import Pagination from '@/components/Pagination';
import DropdownMenu from '@/components/DropdownMenu';
import RightPanel from '@/components/RightPanel';
import Modal from '@/components/PopModal';
import TopicsDropdown from '@/components/PermissionsDropdown';
import { serializeErrors, validateFields, hasPermission } from '@/utils';
import './style.scss';

class Guides extends React.Component {
  imgRef = React.createRef();
  editImgRef = React.createRef();
  imgCategoryRef = React.createRef();

  state = {
    selectedGuide: null,
    showConfirmModal: false,
    selectedTopic: null,
    openTopicPanel: false,
    openNewTopicPanel: false,
    openEditTopicPanel: false,
    openEditCategoryPanel: false,
    openDeleteTopicPanel: false,
    errors: null,
    fileName: '',
    topicImage: null,
    editTopicImage: null,
    topic: '',
    description: '',
    selectedEditTopic: null,
    editTopic: '',
    editDescription: '',
    editImgSrc: '',
    editCategoryImgSrc: '',
    catDescription: '',
    categoryName: '',
    categoryFileName: '',
    categoryImage: null,
    selectedDeleteCategoryTopic: null,
    setCategory: null,
    deleteReason: '',
    topicList: [],
    topicIdList: [],
    showCategoryConfirmModal: false,
  }

  componentDidMount() {
    if (this.props.guides) {
      this.updateSelected(this.props.guides[0])
    }
    this.getGuides(1, 10)
  }

  componentDidUpdate(prevProps) {
    if(this.props.searchString !== prevProps.searchString && this.props.searchString !== '') {
      this.getGuides(1, 10, this.props.searchString)
    }
    if(this.props.searchString !== prevProps.searchString && this.props.searchString === '') {
      this.getGuides(1, 10)
    }
  }

  getGuides = (page, limit, searchTerm) => {
    this.props.getUserGuides(page, limit, searchTerm)
      .then(data => {
        this.updateSelected(data?.data[0])
      })
  }

  selectGuide = (guide) => {
    this.setState({ selectedGuide: guide })
  }

  selectTopic = (topic) => {
    this.setState({ selectedTopic: topic }, () => {
      this.toggleTopicPanel()
    })
  }

  updateSelected = (data) => {
    this.setState({ selectedGuide: data })
  }

  toggleTopicPanel = () => {
    this.setState(prevState => ({ openTopicPanel: !prevState.openTopicPanel }))
  }

  toggleNewTopicPanel = () => {
    this.setState(prevState => ({ openNewTopicPanel: !prevState.openNewTopicPanel }))
  }

  toggleEditTopicPanel = () => {
    this.setState(prevState => ({ openEditTopicPanel: !prevState.openEditTopicPanel }))
  }

  toggleEditCategoryPanel = () => {
    this.setState(prevState => ({ openEditCategoryPanel: !prevState.openEditCategoryPanel }))
  }

  toggleDeleteTopicPanel = () => {
    this.setState(prevState => ({ openDeleteTopicPanel: !prevState.openDeleteTopicPanel }))
  }

  toggleConfirmCategoryModal = () => {
    this.setState(prevState => ({ showCategoryConfirmModal: !prevState.showCategoryConfirmModal }))
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value });
  }

  handleCategoryChange = (event) => {
    const { value } = event.target
    this.setState({ selectedEditTopic: parseInt(value,10) }, () => {
      const foundTopic = this.state.selectedGuide.guides?.find(guide => guide.id === this.state.selectedEditTopic)
      this.setState({
        editDescription: foundTopic.content,
        editTopic: foundTopic.title,
        editImgSrc: foundTopic.image,
      })
    });
  }

  handleDeleteCategoryTopicChange = (event) => {
    const { value } = event.target
    this.setState({ selectedDeleteCategoryTopic: parseInt(value,10) }, () => {
      const foundTopic = this.props.guides?.data?.find(guide => guide.id === this.state.selectedDeleteCategoryTopic)
      this.setState({ setCategory: foundTopic })
    });
  }

  //add topic to delete
  addTopic = (newTopic) => {
    const { topicList, topicIdList } = this.state;
    //check if position is already selected if yes do nothing
    if(topicList.find(topic => topic.id === newTopic.id)) return;
    const currentTopics = [ ...topicList, newTopic];
    const currentTopicList = [ ...topicIdList, newTopic.id ]
    this.setState({topicList: currentTopics, topicIdList: currentTopicList})
  }

  //remove topic to delete
  removeTopic = (index) => {
    const { topicList, topicIdList } = this.state;
    const topicListCopy = [...topicList];
    const topicListIdCopy = [ ...topicIdList]
    topicListCopy.splice(index, 1);
    topicListIdCopy.splice(index, 1);
    this.setState({topicList: topicListCopy, topicIdList: topicListIdCopy});
  }

  //add new topic image
  handleFileClick = (event) => {
    event.preventDefault();
    this.imgRef.current.click()
  }

  handleImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ fileName: target.files[0].name, topicImage: target.files[0] })
    }
  }

  //edit topic image
  handleEditFileClick = (event) => {
    event.preventDefault();
    this.editImgRef.current.click()
  }

  handleEditImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ editFileName: target.files[0].name, editTopicImage: target.files[0] })
    }
  }

  //edit category image
  handleCategoryFileClick = (event) => {
    event.preventDefault();
    this.imgCategoryRef.current.click()
  }

  handleCategoryImageSelect = (event) => {
    const { target } = event;
    if (target.files[0]) {
      this.setState({ categoryFileName: target.files[0].name, categoryImage: target.files[0] })
    }
  }

  handleEditCategory = () => {
    this.setState({ 
      catDescription: this.state.selectedGuide.content,
      categoryName: this.state.selectedGuide.title,
      editCategoryImgSrc: this.state.selectedGuide.image,
     }, () => {
       this.toggleEditCategoryPanel();
     })
  }

  submitNewTopic= (event) => {
    event.preventDefault();

    this.setState({ errors: null });
    const { addGuideTopic } = this.props;

    const { topic, description, topicImage, selectedGuide } = this.state;

    const data = this.state;
    const required = [ 'topic', 'description' ];
    const errors = validateFields(data, required)

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    const formData = new FormData();
    formData.append('title', topic)
    formData.append('content', description)
    formData.append('image', topicImage)
    formData.append('category', selectedGuide?.id)

    addGuideTopic(formData).then(data => {
      this.toggleNewTopicPanel();
      this.setState({ topic: '', description: '', fileName: '' })
    })
  }

  submitEditTopic = (event) => {
    event.preventDefault()

    this.setState({ errors: null });
    const { updateUserGuide } = this.props;

    const { editTopic, selectedEditTopic, editDescription, editTopicImage, selectedGuide } = this.state;

    const data = this.state;
    const required = [ 'editTopic', 'editDescription' ];
    const errors = validateFields(data, required)

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }
    const formData = new FormData();
    formData.append('title', editTopic)
    formData.append('content', editDescription)
    formData.append('image', editTopicImage ? editTopicImage : null)
    formData.append('category', selectedGuide?.id)

    updateUserGuide(selectedEditTopic, formData).then(data => {
      this.toggleEditTopicPanel();
      this.setState({ editTopic: '', description: '', fileName: '' })
    })
  }

  submitEditCategory = (event) => {
    event.preventDefault()

    this.setState({ errors: null });
    const { updateGuideCategory } = this.props;

    const { categoryImage, categoryName, catDescription, selectedGuide } = this.state;

    const formData = new FormData();
    formData.append('title', categoryName)
    formData.append('content', catDescription)
    formData.append('image', categoryImage ? categoryImage : null)

    updateGuideCategory(selectedGuide.id, formData).then(data => {
      this.toggleEditCategoryPanel();
      this.setState({ categoryName: '', catDescription: '', categoryFileName: '' })
    })
  }

  submitTopicDelete = (event) => {
    event.preventDefault()

    this.setState({ errors: null });
    const { deleteGuides } = this.props;

    const { deleteReason, topicIdList, setCategory  } = this.state;
    if (!setCategory) {
      return this.setState({ errors: { setCategory: 'please select a category' } });
    }
    if (topicIdList.length < 1) {
      return this.setState({ errors: { topic: 'please select a topic' } });
    }
    const payload = {
      id: topicIdList,
      message: deleteReason,
    }

    deleteGuides(payload).then(data => {
      this.toggleDeleteTopicPanel();
    })
  }

  handleDeleteCategory = () => {
    const { deleteGuides } = this.props;
    const { selectedGuide } = this.state;
    const payload = {
      id: [selectedGuide.id],
      message: 'ok',
    }

    deleteGuides(payload).then(data => {
      this.toggleConfirmCategoryModal();
    })
  }

  render() {
    const { loading, guides, removeLoading, data, error, addLoading, updateLoading, updateGuideLoading, deleteGuideLoading, permissions } = this.props;
    const { 
      selectedGuide, showConfirmModal, openTopicPanel, selectedTopic, openNewTopicPanel, errors, fileName, topic, description,
      openEditTopicPanel, editFileName, editTopic, editDescription, editImgSrc, editCategoryImgSrc, openEditCategoryPanel,
      catDescription, categoryFileName, categoryName, openDeleteTopicPanel, setCategory, deleteReason, topicList, showCategoryConfirmModal
     } = this.state;
    const errorObject = serializeErrors(error);
    const canDelete = hasPermission(permissions, 'delete_service');
    const canUpdate = hasPermission(permissions, 'update_service');

    const userGuides = guides?.data ? guides.data : guides;
    const menu = [
      {
        name: 'Add new topic',
        handler: this.toggleNewTopicPanel,
      },
      canUpdate && {
        name: 'Edit topic',
        handler: this.toggleEditTopicPanel
      },
      canUpdate && {
        name: 'Edit Category',
        handler: this.handleEditCategory
      },
      canDelete && {
        name: 'Delete topic',
        handler: this.toggleDeleteTopicPanel
      },
      canDelete && {
        name: 'Delete category',
        handler: this.toggleConfirmCategoryModal
      }
    ]
    return (
      <div className="guides-page">
        <RightPanel
          open={openTopicPanel}
          onClose={this.toggleTopicPanel}
        >
          <h4 className="panel-heading">Topic Details</h4>
          <div className="row">
            <div className="col-md-4">
              <label className="text-grey font-md">Name</label>
            </div>
            <div className="col-md-8">
              <p className="font-md">{selectedTopic?.title}</p>

            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <label className="text-grey font-md">Description</label>

            </div>
            <div className="col-md-8">
              <p className="font-md">{selectedTopic?.content}</p>
            </div>
          </div>
        </RightPanel>
        <RightPanel
          open={openNewTopicPanel}
          onClose={this.toggleNewTopicPanel}
        >
          <h4 className="panel-heading">Add New Topic</h4>
          <form onSubmit={this.submitNewTopic}>
            <label className="panel-label">Topic name</label>
            <input
              name="topic"
              type="text"
              placeholder="Topic name"
              className="border-grey form-control panel-input mb-0"
              value={topic}
              onChange={this.handleChange}
            />
            <p className="text-error">{errors ? errors.topic : (errorObject && errorObject['title'])}</p>
            
            <label className="panel-label mt-4" htmlFor="new-topic-icon">Add image</label>
            <div
              className="img-input"
              id="new-topic-icon"
            >
              <div className="img-upload d-flex align-items-center">
                <div>
                  <input type="file" className="file" ref={this.imgRef} accept="image/*" onChange={this.handleImageSelect} />
                  <button className="bg-white pl-4 pr-4" onClick={this.handleFileClick}>
                    Upload file
                  </button>
                  <p>{fileName}</p>
                  <p>Upload JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">{errors ? errors.fileName : (errorObject && errorObject['image'])}</p>
            </div>

            <label className="panel-label">Description</label>
            <textarea
              name="description"
              type="text"
              placeholder="Enter description"
              className="border-grey form-control panel-input mb-0"
              value={description}
              onChange={this.handleChange}
              rows="5"
            />
            <p className="text-error">{errors ? errors.description : (errorObject && errorObject['content'])}</p>
            
            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.toggleNewTopicPanel}>
                  Cancel
              </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                  {addLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <RightPanel
          open={openEditTopicPanel}
          onClose={this.toggleEditTopicPanel}
        >
          <h4 className="panel-heading">Edit Topic</h4>
          <form onSubmit={this.submitEditTopic}>
            <label className="panel-label" htmlFor="new-investment-type">Choose topic to edit from {selectedGuide?.title}</label>
            <select
              className="form-control panel-input mb-0"
              placeholder="choose topic"
              name="type"
              onChange={this.handleCategoryChange}
            >
              <option>select topic</option>
              {selectedGuide && selectedGuide.guides.map(topic => (
                <option value={topic.id} key={topic.id}>{topic.title}</option>
              ))}
            </select>
            <label className="panel-label mt-4">Topic name</label>
            <input
              name="editTopic"
              type="text"
              placeholder="Topic name"
              className="border-grey form-control panel-input mb-0"
              value={editTopic}
              onChange={this.handleChange}
            />
            <p className="text-error">{errors ? errors.editTopic : (errorObject && errorObject['title'])}</p>
            
            <label className="panel-label mt-4" htmlFor="new-topic-icon">Add image</label>
            <div
              className="img-input"
              id="new-topic-icon"
            >
              <div className="img-upload d-flex align-items-center">
                {editImgSrc && <img src={editImgSrc} alt="topic" className="img-fluid edit-img" />}
                <div>
                  <input type="file" className="file" ref={this.editImgRef} accept="image/*" onChange={this.handleEditImageSelect} />
                  <button className="bg-white pl-4 pr-4" onClick={this.handleEditFileClick}>
                    Upload file
                  </button>
                  <p>{editFileName}</p>
                  <p>Upload JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">{errors ? errors.editFileName : (errorObject && errorObject['image'])}</p>
            </div>

            <label className="panel-label">Description</label>
            <textarea
              name="editDescription"
              type="text"
              placeholder="Enter description"
              className="border-grey form-control panel-input mb-0"
              value={editDescription}
              onChange={this.handleChange}
              rows="5"
            />
            <p className="text-error">{errors ? errors.editDescription : (errorObject && errorObject['content'])}</p>
            
            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.toggleEditTopicPanel}>
                  Cancel
              </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                  {updateLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <RightPanel
          open={openEditCategoryPanel}
          onClose={this.toggleEditCategoryPanel}
        >
          <h4 className="panel-heading">Add new category</h4>
          <form onSubmit={this.submitEditCategory}>
            <label className="panel-label" htmlFor="new-category-name">Category name</label>
            <input
              name="categoryName"
              type="text"
              placeholder="Category name"
              className="border-grey form-control panel-input mb-0"
              value={categoryName}
              onChange={this.handleChange}
            />
            <p className="text-error">{errors ? errors.categoryName : (errorObject && errorObject['title'])}</p>

            <label className="panel-label mt-4" htmlFor="new-category-name">Category description</label>
            <textarea
              name="catDescription"
              type="text"
              placeholder="Category description"
              className="border-grey form-control panel-input mb-0"
              value={catDescription}
              onChange={this.handleChange}
            />
            <p className="text-error">{errors ? errors.catDescription : (errorObject && errorObject['content'])}</p>


            <label className="panel-label mt-4" htmlFor="new-investment-icon">Category Icon</label>
            <div
              className="img-input"
              id="new-category-icon"
            >
              <div className="img-upload d-flex align-items-center">
              {editCategoryImgSrc && <img src={editCategoryImgSrc} alt="category" className="img-fluid edit-img" />}
                <div>
                  <input type="file" className="file" ref={this.imgCategoryRef} accept="image/*" onChange={this.handleCategoryImageSelect} />
                  <button className="bg-white pl-4 pr-4" onClick={this.handleCategoryFileClick}>
                    Upload file
                  </button>
                  <p>{categoryFileName}</p>
                  <p>Upload JPEG, JPG or PNG</p>
                </div>
              </div>
              <p className="text-error">{errors ? errors.categoryFileName : (errorObject && errorObject['image'])}</p>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.toggleEditCategoryPanel}>
                  Cancel
                    </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                    {updateGuideLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        <RightPanel
          open={openDeleteTopicPanel}
          onClose={this.toggleDeleteTopicPanel}
        >
          <h4 className="panel-heading">Delete User Guide</h4>
          <form onSubmit={this.submitTopicDelete}>
            <label className="panel-label" htmlFor="new-investment-type">Choose what to delete from (Category)</label>
            <select
              className="form-control panel-input mb-0"
              placeholder="choose topic"
              name="type"
              onChange={this.handleDeleteCategoryTopicChange}
            >
              <option>select category</option>
              {userGuides && userGuides.map(topic => (
                <option value={topic.id} key={topic.id}>{topic.title}</option>
              ))}
            </select>
            <p className="text-error">{errors ? errors.setCategory : (errorObject && errorObject['category'])}</p>

            {setCategory && 
            <>
            <label className="panel-label mt-4" htmlFor="new-investment-type">Choose what to delete from (Title)</label>
              <TopicsDropdown 
                permissions={setCategory && setCategory.guides}
                id="permissionsSelect"
                passPermissions={this.addTopic}
                placeholder="Select Topic"
              />
              <p className="text-error">{errors ? errors.topic : (errorObject && errorObject['title'])}</p>
              </>
              }
              <div className="dropdown__positions-box">
                  {topicList.map((topic, index) => (
                      <div className="dropdown__position-item" key={topic.id}>
                          {topic.name}
                          <button onClick={() => this.removeTopic(index)}>
                              <img src={require("@/assets/icons/circle-cancel.svg")} alt="cancel icon" />
                          </button>
                      </div>
                  ))}
              </div>
            <label className="panel-label mt-4">Reason for deletion</label>
            <textarea
              name="deleteReason"
              type="text"
              placeholder="Enter reason"
              className="border-grey form-control panel-input mb-0"
              value={deleteReason}
              onChange={this.handleChange}
              rows="5"
            />
            <p className="text-error">{errors ? errors.deleteReason : (errorObject && errorObject['message'])}</p>
            
            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-white btn-sm font-md w-100" onClick={this.toggleDeleteTopicPanel}>
                  Cancel
              </button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary btn-sm font-md w-100 d-flex align-items-center justify-content-center" type="submit">
                  Request Approval
                  {deleteGuideLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </form>
        </RightPanel>
        {showConfirmModal &&
          <Modal onClose={this.toggleConfirmModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to delete this blog post?</p>
              <div className="d-flex">
                <button className="btn btn-sm btn-white btn-block mt-3 mr-2" onClick={() => this.setState({ showConfirmModal: false })}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center" onClick={this.handleDelete}>
                  Confirm
                  {removeLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </Modal>
        }
        {showCategoryConfirmModal &&
          <Modal onClose={this.toggleConfirmCategoryModal}>
            <div className="text-center">
              <h3>Confirm</h3>
              <p>Are you sure you want to delete this category?</p>
              <div className="d-flex">
                <button className="btn btn-sm btn-white btn-block mt-3 mr-2" onClick={() => this.setState({ showCategoryConfirmModal: false })}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-danger btn-block mt-3 ml-2 d-flex align-items-center justify-content-center" onClick={this.handleDeleteCategory}>
                  Confirm
                  {deleteGuideLoading &&
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2"></div>
                  }
                </button>
              </div>
            </div>
          </Modal>
        }
        <Pagination
          totalPages={data?.lastPage}
          page={data?.page}
          limit={data?.perPage}
          changePageHandler={(page, limit) => this.getGuides(page, limit)}
        />
        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-md-3 border-right p-5">
              {userGuides && userGuides.length > 0 ? userGuides.map(guide => (
                <p className="text-deep-blue font-md cursor-pointer text-capitalize pb-5" key={guide.id} onClick={() => this.selectGuide(guide)}>
                  {guide.title}
                </p>
              ))
                : <p className="font-md text-deep-blue">{this.props.searchString === '' ?  'No user guides' : 'No user guide matches the search word'}</p>
              }
            </div>
            <div className="col-md-9">
              <div className="p-5 d-flex justify-content-between align-items-center border-bottom">
                <h2 className="text-deep-blue text-capitalize">
                  {selectedGuide && selectedGuide.title}
                  {userGuides && userGuides.length < 1 && 'No user guides'}
                </h2>
                {loading &&
                  <div className="spinner-border spinner-border-blue spinner-border-md ml-2"></div>
                }
                {selectedGuide &&
                  <DropdownMenu menu={menu}>
                    <img src={require("@/assets/icons/flat-ellipsis.svg")} alt="dropdown" />
                  </DropdownMenu>
                }
              </div>
              <div className="topic-container py-4">
                {selectedGuide && <p className="text-deep-blue font-md font-weight-bold p-4">Topics</p>}
                {selectedGuide &&
                  (selectedGuide.guides.length > 0 ? selectedGuide.guides.map(topic => (
                    <div
                      key={topic.id}
                      className={`${(topic.id % 2) === 0 && 'back-colour'}`}
                    >
                      <p className="text-deep-blue font-md text-capitalize p-4 mb-0 cursor-pointer" onClick={() => this.selectTopic(topic)}>
                        {topic.title}
                      </p>
                    </div>
                  ))
                    :
                    <p className="text-deep-blue text-capitalize font-md p-4">No topic under this guide</p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app: { support: { error, data, userGuides } }, user: { permissions } } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_USER_GUIDES_REQUEST),
    removeLoading: getActionLoadingState(state, actionTypes.DELETE_BLOG_POST_REQUEST),
    addLoading: getActionLoadingState(state, actionTypes.ADD_USER_GUIDES_REQUEST),
    updateLoading: getActionLoadingState(state, actionTypes.UPDATE_USER_GUIDES_REQUEST),
    updateGuideLoading: getActionLoadingState(state, actionTypes.UPDATE_GUIDE_CATEGORY_REQUEST),
    deleteGuideLoading: getActionLoadingState(state, actionTypes.DELETE_USER_GUIDES_REQUEST),
    error,
    data,
    guides: userGuides,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserGuides: (page, limit, searchTerm) => dispatch(getUserGuides(page, limit, searchTerm)),
    addGuideTopic: (payload) => dispatch(addGuideTopic(payload)),
    updateUserGuide: (id, payload) => dispatch(updateUserGuide(id,payload)),
    updateGuideCategory: (id, payload) => dispatch(updateGuideCategory(id,payload)),
    deleteGuides: (payload) => dispatch(deleteGuides(payload)),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Guides));
