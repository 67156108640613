import React, { Component } from "react";
import './style.scss';

// keyboard accessible custom dropdown autosuggest input
export default class PermissionsDropdown extends Component {
    state = {
        open: false,
        inputValue: '',
        filteredOptions: [],
        addedPositions: [],
    }
    optionBoxRef = React.createRef();
    inputRef = React.createRef();

    componentDidUpdate(prevProps) {
        if(this.state.filteredOptions.length) {
            document.addEventListener('mousedown', this.clickOutsideHandler);
        } else {
            document.removeEventListener('mousedown', this.clickOutsideHandler);
        }
        if(this.props.isFetching !== prevProps.isFetching) {
            this.filterOptions(this.state.inputValue);
        }
    }

    filterOptions = (searchTerm) => {
        if(this.props.fetchDataHandler) {
            if (!searchTerm) return this.setState({filteredOptions: []});
            this.props.fetchDataHandler(searchTerm);
        }
        const allOptions = this.props.permissions;
        if (!searchTerm) {
            this.setState({filteredOptions: allOptions});
            return;
        }
        const filteredOptions = allOptions.filter(
            option => option.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
        this.setState({filteredOptions});
    }

    onInputChange = (e) => {
        const value = e.target.value;
        if(value === this.state.inputValue) return this.setState({filteredOptions: []});
        this.setState({inputValue: value});
        this.filterOptions(value.trim());
    }

    highlightActiveOption = (currentActive, previousActive) => {
        previousActive.classList.remove("dropdown__option--active");
        currentActive.classList.add("dropdown__option--active");
        currentActive.scrollIntoView(false);
    }

    onKeyDown = (e) => {
        const {activeOptionIndex} = this.state;
        const options = this.optionBoxRef.current?.children;

        if (e.key === 'Escape') {
            this.setState({filteredOptions: []})
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            if((!activeOptionIndex && activeOptionIndex !== 0) || !options ) return;
            this.setState({
                inputValue: options[activeOptionIndex].textContent,
                selectedPositionId: options[activeOptionIndex].getAttribute('data-id'),
                filteredOptions: [],
            })
            if(this.props.isSimpleInput) {
                this.props.passPermissions({name: options[activeOptionIndex].textContent, id: options[activeOptionIndex].getAttribute('data-id')});
            }
        }

        if (e.key === 'ArrowDown') {
            let nextOptionIndex = activeOptionIndex === undefined ? 0 : activeOptionIndex + 1;
            //check if dropdown has options if not get options
            if(!options){
                this.filterOptions();
                return;
            }
            if(nextOptionIndex === options.length) nextOptionIndex = 0;
            const previousActiveOption = nextOptionIndex === 0 ? options[options.length - 1] : options[nextOptionIndex - 1]
            this.setState({activeOptionIndex: nextOptionIndex});
            this.highlightActiveOption(options[nextOptionIndex], previousActiveOption);
        }

        if (e.key === 'ArrowUp') {
            if(!options) return;
            let nextOptionIndex = activeOptionIndex === undefined ? options.length - 1 : activeOptionIndex - 1;
            let previousOptionIndex = nextOptionIndex + 1 >= options.length ? 0 : nextOptionIndex + 1;
            if(nextOptionIndex < 0) {
                nextOptionIndex = options.length - 1;
                previousOptionIndex = 0;
            }
            this.setState({activeOptionIndex: nextOptionIndex});
            this.highlightActiveOption(options[nextOptionIndex], options[previousOptionIndex]);
        }
    }

    clickOutsideHandler = (event) => {
        if (this.optionBoxRef.current.contains(event.target) || this.inputRef.current.contains(event.target)) return;
        this.setState({filteredOptions: []})
    }

    onClickOption = (e) => {
        this.setState({
            inputValue: e.target.textContent,
            filteredOptions: [],
            selectedPositionId: e.target.getAttribute('data-id'),
        })
        // the isSimpleInput prop specifies if the dropdown is to be used as a simple html select input
        if(this.props.isSimpleInput) {
            this.props.passPermissions({name: e.target.textContent, id: e.target.getAttribute('data-id')});
        };
    }

    addPosition = (e) => {
        e.preventDefault();
        const { inputValue, selectedPositionId } = this.state;
        if(!selectedPositionId) return;
        const permission = {name: inputValue, id: selectedPositionId};
        this.setState({
            inputValue: '',
            selectedPositionId: null,
        })
        this.props.passPermissions(permission);
    }

    render() {
        const { filteredOptions, inputValue, activeOptionIndex, selectedPositionId } = this.state;
        return(
            <div className="dropdown">
                <input 
                    type="text"
                    placeholder={`${this.props.placeholder ? this.props.placeholder : 'Select Permission' }`}
                    className="form-control dropdown__input"
                    id={this.props.id}
                    value={inputValue}
                    onChange={this.onInputChange}
                    onClick={() => this.filterOptions()}
                    onKeyDown={this.onKeyDown}
                    autoComplete="off"
                    ref={this.inputRef}
                    required={this.props.isRequired}
                />
                {this.props.isFetching && <div className="spinner-border spinner-border-sm ml-2 mb-2 text-primary"></div>}
                {(!this.props.isSimpleInput && selectedPositionId) && <button className="dropdown__add-button" onClick={this.addPosition}>
                    <img src={require("@/assets/icons/boxed-plus.svg")} alt="add icon"/>
                </button>}
                {filteredOptions.length ? <ul className="dropdown__optionBox" ref={this.optionBoxRef}>
                    {filteredOptions.map((option, index) => (
                        <li 
                            className={`dropdown__option ${activeOptionIndex === index ? 'dropdown__option--active' : ''}`} 
                            key={option.id}
                            data-id={option.id}
                            onClick={this.onClickOption}
                            onMouseEnter={() => this.setState({activeOptionIndex: index})}
                        >
                            {option.name || option.title}
                        </li>
                    ))}
                </ul> : ''}
            </div>
        )
    }
}
