import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { currency } from "@/utils";
import { getTransactionChartOptions } from "@/utils/index";
import { fetchWithdrawalAnalytics } from "@/store/analytics/transactionAnalyticsAction";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";
import ExportDropdown from "@/components/ExportDropdown";

class Withdrawals extends Component {
  state = {
    withdrawalsData: null,
    totalAndAverageWithdrawal: null,
  };

  componentDidMount() {
    this.fetchWithdrawalsData();
    this.fetchTotalAndAverageWithdrawal();
  }

  formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  fetchWithdrawalsData = () => {
    const { dates } = this.props;
    if (!dates[1]) return;
    this.props
      .fetchWithdrawalAnalytics(
        "total-withdrawal-distribution-by-month",
        this.formatDate(dates[0]),
        this.formatDate(dates[1])
      )
      .then((withdrawalsData) => {
        this.setState({
          withdrawalsData:
            withdrawalsData.data.totalWithdrawalsDistributionByMonths,
        }); //rember to complete
      });
  };
  fetchTotalAndAverageWithdrawal = () => {
    const { totalAndAverageWithdrawalDate } = this.props;
    if (!totalAndAverageWithdrawalDate[1]) return;
    this.props
      .fetchWithdrawalAnalytics(
        "totalAndAverageWithdrawal",
        this.formatDate(totalAndAverageWithdrawalDate[0]),
        this.formatDate(totalAndAverageWithdrawalDate[1])
      )
      .then((totalAndAverage) => {
        this.setState({
          totalAndAverageWithdrawal:
            totalAndAverage.data.totalAndAverageWithdrawal,
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.dates !== prevProps.dates) {
      this.fetchWithdrawalsData();
    }
    if (
      this.props.totalAndAverageWithdrawalDate !==
      prevProps.totalAndAverageWithdrawalDate
    ) {
      this.fetchTotalAndAverageWithdrawal();
    }
  }

  withdrawalsChartData = () => {
    const withdrawaldata = this.state.withdrawalsData;
    const labels = withdrawaldata ? this.state.withdrawalsData.labels : [];
    const datas = withdrawaldata ? this.state.withdrawalsData.data : [];
    const typecolor =
      withdrawaldata && withdrawaldata.monthBackgroundColor
        ? withdrawaldata.monthBackgroundColor
        : ["#A0ECA8", "#F48CEA"];

    return {
      labels: [...labels],
      datasets: [
        {
          data: [...datas],
          backgroundColor: [...typecolor],
        },
      ],
    };
  };

  render() {
    const { withdrawalsData, totalAndAverageWithdrawal } = this.state;

    const { dates, handleDateChange, totalAndAverageWithdrawalDate } =
      this.props;
    return (
      <div>
        {!totalAndAverageWithdrawal ? (
          <div className="withdrawal-summary">
            <div className="text-center deposit-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
            <div className="text-center deposit-summary-card text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="print-all">
              <AnalyticsDateInput
                value={totalAndAverageWithdrawalDate[0]}
                isRange={true}
                startDate={totalAndAverageWithdrawalDate[0]}
                endDate={totalAndAverageWithdrawalDate[1]}
              />

              <ExportDropdown
                module="transaction-analytics"
                status="withdrawals"
                excludeCSV
              />
            </div>
            <div className="withdrawal-summary">
              <div className="withdrawal-summary-card">
                <span className="withdrawal-summary-card__title">
                  Total Withdrawal
                </span>
                <span className="withdrawal-summary-card__total">
                  {`N${currency(
                    totalAndAverageWithdrawal.totalWithdrawal,
                    true
                  )}`}
                </span>
              </div>
              <div className="withdrawal-summary-card">
                <span className="withdrawal-summary-card__title">
                  Average Withdrawal
                </span>
                <span className="withdrawal-summary-card__total">
                  {`N${currency(
                    totalAndAverageWithdrawal.averageWithdrawal,
                    true
                  )}`}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="main-chart">
          <div className="setup-inner__main p-5 analytics-graph">
            <div className="text-center">
              <h5 className="text-medium">Withdrawal Value Per Month</h5>
              <div className="pb-4">
                <AnalyticsDateInput
                  isRange={true}
                  startDate={dates[0]}
                  endDate={dates[1]}
                  handleChange={(date) => handleDateChange(date)}
                />
              </div>
            </div>

            {!withdrawalsData ? (
              <div className="text-center text-primary">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <Bar
                data={this.withdrawalsChartData}
                options={getTransactionChartOptions("Value", "Month")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWithdrawalAnalytics: (view, startDate, endDate) =>
      dispatch(fetchWithdrawalAnalytics(view, startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(Withdrawals);
