import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchReport,
  searchReport,
  exportAuditTrail,
} from "@/store/reports/actions";
import actionTypes from "@/store/reports/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";

class AuditTrail extends Component {
  state = {
    auditTrail: [],
    page: 0,
    searchTerm: "",
    shouldSearch: false,
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getAuditTrail(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: "", shouldSearch: false }, () =>
        this.getAuditTrail(1, 10, status)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      if (!this.state.searchTerm) return this.getAuditTrail(1, 10, status);
      this.searchAuditTrail(this.state.searchTerm, 1, 10, status);
    }
  }

  getAuditTrail = (page, limit, status) => {
    if (this.state.searchTerm)
      return this.searchAuditTrail(this.state.searchTerm, page, limit);
    this.props.fetchAuditTrail(page, limit, status).then(({ data }) => {
      this.setState({
        lastPage: Math.ceil(data.total.value / limit),
        perPage: limit,
        page,
        total: data.total.value,
        auditTrail: data.hits,
        searchTerm: "",
      });
    });
  };

  searchAuditTrail = (searchTerm, page, limit, status) => {
    if (!searchTerm) return this.getBookings(1, 10, status);
    this.props.searchAuditTrail(searchTerm, page, limit).then(({ data }) => {
      this.setState({
        total: data.total.value,
        perPage: limit,
        page,
        lastPage: Math.ceil(data.total.value / limit),
        auditTrail: data.hits,
        shouldSearch: false,
      });
    });
  };

  renderAuditTrail = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th>Description</th>
            <th>Action taken by</th>
            <th>IP Address</th>
            <th>Date/Time</th>
          </tr>
          {this.state.auditTrail.map((data) => (
            <tr key={data._id}>
              <td style={{ whiteSpace: "normal", maxWidth: "24rem" }}>
                {data._source.description}
              </td>
              <td className="text-capitalize">{data._source.actionTakenBy}</td>
              <td>{data._source.ipaddress}</td>
              <td>
                {dayjs(data._source.created_at).format("D MMM YYYY/hh:m:ss")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
    } = this.props;
    const { auditTrail, lastPage, perPage, page, total, searchTerm } =
      this.state;

    return (
      <div className="setup-inner">
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "recent", state: { pageTitle: "Reports" } }}
                isActive={() => status === "recent"}
                activeClassName="setup-nav__item--active"
              >
                Recent
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "all", state: { pageTitle: "Reports" } }}
                isActive={() => status === "all"}
                activeClassName="setup-nav__item--active"
              >
                All Audits
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          <ExportDropdown
            module="audit-trail"
            status={status}
            page={page}
            limit={perPage}
            downloadHandler={this.props.exportAuditTrail}
            hasMoreThanOnePage={lastPage > 1}
          />
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!auditTrail.length ? (
              <Emptystate
                title={`${
                  searchTerm ? "No Result Found" : "No Recent Records"
                }`}
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getAuditTrail(page, limit, status)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main">
                    {this.renderAuditTrail()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${auditTrail.length} of ${total}`}</span>
                    Records
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_REPORTS_REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAuditTrail: (offset, limit, path) =>
      dispatch(fetchReport(offset, limit, path, "audit")),
    searchAuditTrail: (searchTerm, page, limit) =>
      dispatch(searchReport(searchTerm, "audit", page, limit)),
    exportAuditTrail: (module, format, status, page, limit) =>
      dispatch(exportAuditTrail(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditTrail)
);
