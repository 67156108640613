import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import actionTypes from "@/store/setup/actionTypes";
import { clearError } from "@/store/ui/actions";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import {
  fetchPushNotifications,
  searchPushNotifications,
  changeStatus,
  createOrUpdate,
  deleteNotification,
  exportNotifications,
} from "@/store/setup/pushNotificatioAction";
import Emptystate from "@/components/Emptystate";
import Dropdown from "@/components/DropdownMenu";
import Pagination from "@/components/Pagination";
import RightPanel from "@/components/RightPanel";
import InputError from "@/components/InputError";
import ConfirmationBox from "@/components/ConfirmationBox";
import ExportDropdown from "@/components/ExportDropdown";
import SearchBox from "@/components/SearchBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import { serializeErrors, hasPermission } from "@/utils";

import "react-datepicker/dist/react-datepicker.css";

class PushNotification extends Component {
  defaultState = {
    title: "",
    description: "",
    deliveryType: "scheduled",
    deliveryDate: "",
    open: false,
    previewMode: false,
  };

  state = {
    ...this.defaultState,
    notifications: [],
    confirmationContext: "",
    confirmationSuccess: false,
    searchTerm: "",
    shouldSearch: false,
    selectedRowsIds: [],
  };

  componentDidMount() {
    this.getNotifications(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        selectedRowsIds: [],
        searchTerm: "",
        shouldSearch: false,
      });

      this.getNotifications(1, 10);
    } else if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchNotifications();
    }
  }

  currentActivePage = () => {
    const path = this.props.location.pathname.split("/").slice(-1).join("");

    let activePage = "active";
    if (path === "pending" || path === "rejected") {
      activePage = path;
    }

    return activePage;
  };

  setPageData = (
    { lastPage, perPage, page, total, data: notifications },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      notifications,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  getNotifications = (page, limit) => {
    const activePage = this.currentActivePage();

    this.props
      .fetchPushNotifications(page, limit, activePage)
      .then((result) => {
        this.setPageData(result.data);
      });
  };

  searchNotifications = (page = 1, limit = 10) => {
    const { searchTerm } = this.state;
    if (!searchTerm) return this.getNotifications(1, 10);
    const status = this.currentActivePage();
    this.props
      .searchPushNotifications(page, limit, status, searchTerm)
      .then((result) => {
        this.setPageData(result.data, searchTerm);
      });
  };

  updateNotification = (newStatus, reason) => {
    const { id, notifications, isMultipleApproval, selectedRowsIds, total } =
      this.state;
    const ids = isMultipleApproval ? selectedRowsIds : [id];
    this.props.changeStatus(ids, newStatus, reason).then(() => {
      const unchangedItems = notifications.filter(
        (data) => !ids.includes(data.id)
      );
      this.setState({
        notifications: unchangedItems,
        confirmationSuccess: true,
        selectedRowsIds: [],
        total: total - ids.length,
      });
    });
  };

  deleteNotification = () => {
    const { id, notifications, isMultipleApproval, selectedRowsIds, total } =
      this.state;
    const ids = isMultipleApproval ? selectedRowsIds : [id];
    this.props.deleteNotification(ids).then(() => {
      const unchangedItems = notifications.filter(
        (data) => !ids.includes(data.id)
      );
      this.setState({
        notifications: unchangedItems,
        confirmationSuccess: true,
        selectedRowsIds: [],
        total: total - ids.length,
      });
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      deleteNotification: {
        title: `Delete notification${addS ? "s" : ""}`,
        question: `Are you sure you want to delete ${
          addS ? "these" : "this"
        } notification${addS ? "s" : ""}?`,
        action: () => this.deleteNotification(),
      },
      approveNotification: {
        title: `Approve notification${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } notification${addS ? "s" : ""}?`,
        action: () => this.updateNotification("active"),
      },
      rejectNotification: {
        title: `Reason for rejecting notification${addS ? "s" : ""}`,
        requiresReason: true,
        action: (reason) => this.updateNotification("rejected", reason),
      },
    };
  };

  setConfirmationContext = (context, id, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      isMultipleApproval,
      id,
    });
  };

  canUpdate = hasPermission(this.props.permissions, "update_pushnotification");
  canDelete = hasPermission(this.props.permissions, "delete_pushnotification");
  canApprove = hasPermission(
    this.props.permissions,
    "approve_pushnotification"
  );
  dropdownReject = (data) => {
    return [
      this.canApprove
        ? {
            name: "Approve Notification",
            handler: () =>
              this.setConfirmationContext("approveNotification", data.id),
          }
        : null,
      this.canApprove
        ? {
            name: "Reject Notification",
            handler: () =>
              this.setConfirmationContext("rejectNotification", data.id),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };
  dropdownsentmenus = (data) => {
    return [
      this.canDelete
        ? {
            name: "Delete Notification",
            handler: () =>
              this.setConfirmationContext("deleteNotification", data.id),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };

  dropdownmenus = (data) => {
    return [
      this.canUpdate
        ? {
            name: "Edit Notification",
            handler: () =>
              this.setState({
                id: data.id,
                title: data.title,
                description: data.description,
                deliveryType: data.deliveryType,
                deliveryDate: data.deliveryDate,
                open: true,
              }),
          }
        : null,
      this.canDelete
        ? {
            name: "Delete Notification",
            handler: () =>
              this.setConfirmationContext("deleteNotification", data.id),
            textClass: "text-red",
          }
        : null,
    ].filter(Boolean);
  };

  onRowClick = (e, notification) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "Notification Title": notification.title,
          "Push Date":
            notification.deliveryDate &&
            dayjs(notification.deliveryDate).format("DD MMM YYYY h:mm a"),
          "Date Created": dayjs(notification.created_at).format("DD MMM YYYY "),
          "Delivery Type": notification.deliveryType,
          Description: notification.description,
          Status: notification.status,
        },

        open: true,
      });
    }
  };
  onRejectedClick = (e, notification) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "Notification Title": notification.title,
          "Push Date":
            notification.deliveryDate &&
            dayjs(notification.deliveryDate).format("DD MMM YYYY h:mm a"),
          "Date Created": dayjs(notification.created_at).format("DD MMM YYYY "),
          "Delivery Type": notification.deliveryType,
          Description: notification.description,
          Status: notification.status,
          "Reason For Rejection":
            notification.reasons && notification.reasons[0].reviewerMessage,
        },

        open: true,
      });
    }
  };
  ///

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  onCheckRow = (e, rowId) => {
    const selectedRowsIds = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRowsIds.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRowsIds.findIndex((id) => id === rowId);
      selectedRowsIds.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds });
  };

  renderActiveNotifications = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>Notification Title</th>
            <th>Date Created</th>
            <th>Push Date</th>
            <th>Delivery Type</th>
            <th>Description</th>
            <th>Status</th>
            <th></th>
          </tr>
          {this.state.notifications.map((notification) => (
            <tr
              key={notification.id}
              onClick={(e) => this.onRowClick(e, notification)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, notification.id)}
                />
              </td>
              <td>{notification.title}</td>
              <td>{dayjs(notification.created_at).format("DD MMM YYYY")}</td>

              <td className="text-capitalize">
                {notification.pushDate
                  ? dayjs(notification.pushDate).format("DD MMM YYYY h:mm A")
                  : notification.deliveryDate
                  ? dayjs(notification.deliveryDate).format(
                      "DD MMM YYYY h:mm A"
                    )
                  : dayjs(notification.updated_at).format("DD MMM YYYY h:mm A")}
              </td>
              <td>{notification.deliveryType}</td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {notification.description}
              </td>
              <td>
                <span
                  className={`status ${
                    notification.status === "sent"
                      ? "status--active"
                      : "status--disabled"
                  }`}
                >
                  {notification.status}
                </span>
              </td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={
                    notification.status === "sent"
                      ? this.dropdownsentmenus(notification)
                      : this.dropdownmenus(notification)
                  }
                  width="151px"
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg")}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPendingNotifications = () => {
    return (
      <table className="custum-table">
        <tbody>
          <tr>
            <th></th>
            <th>Notification Title</th>
            <th>Date Created</th>
            <th>Push Date</th>
            <th>Delivery Type</th>
            <th>Description</th>
            <th className="actions-column">Actions</th>
          </tr>
          {this.state.notifications.map((notification) => (
            <tr
              key={notification.id}
              onClick={(e) => this.onRowClick(e, notification)}
              className="cursor-pointer"
            >
              <td className="row-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, notification.id)}
                />
              </td>
              <td>{notification.title}</td>
              <td>{dayjs(notification.created_at).format("DD MMM YYYY")}</td>
              <td className="text-capitalize">
                {notification.pushDate
                  ? dayjs(notification.pushDate).format("DD MMM YYYY h:mm A")
                  : notification.deliveryDate
                  ? dayjs(notification.deliveryDate).format(
                      "DD MMM YYYY h:mm A"
                    )
                  : dayjs(notification.updated_at).format("DD MMM YYYY h:mm A")}
              </td>
              <td>{notification.deliveryType}</td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {notification.description}
              </td>
              <td>{notification.action}</td>

              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownReject(notification)}
                  width="151px"
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg")}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  renderRejectedNotifications = () => {
    return (
      <table className="custum-table ">
        <tbody>
          <tr>
            <th></th>
            <th>Notification Title</th>
            <th>Date Created</th>
            <th>Push Date</th>
            <th>Delivery Type</th>
            <th>Description</th>

            <th>Status</th>
            <th></th>
          </tr>
          {this.state.notifications.map((notification) => (
            <tr
              key={notification.id}
              onClick={(e) => this.onRejectedClick(e, notification)}
              className="cursor-pointer"
            >
              <td className="row-checkbox ">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckRow(e, notification.id)}
                />
              </td>
              <td>{notification.title}</td>
              <td>{dayjs(notification.created_at).format("DD MMM YYYY")}</td>

              <td className="text-capitalize">
                {notification.pushDate
                  ? dayjs(notification.pushDate).format("DD MMM YYYY  h:mm A")
                  : notification.deliveryDate
                  ? dayjs(notification.deliveryDate).format(
                      "DD MMM YYYY h:mm A"
                    )
                  : dayjs(notification.updated_at).format("DD MMM YYYY h:mm A")}
              </td>
              <td>{notification.deliveryType}</td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {notification.description}
              </td>

              <td>
                <span
                  className={`status ${
                    notification.status === "sent"
                      ? "status--active"
                      : "status--disabled"
                  }`}
                >
                  {notification.status}
                </span>
              </td>
              <td className="custum-table__ellipsis">
                <Dropdown
                  menu={this.dropdownmenus(notification)}
                  width="151px"
                  arrow={true}
                >
                  <button className="wrapper-button">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg")}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      ...this.defaultState,
    });
    this.props.clearError();
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createOrUpdate = (e) => {
    e.preventDefault();
    const { title, description, deliveryDate, deliveryType, id } = this.state;
    const payload = { title, description, deliveryType };
    if (deliveryType !== "instant")
      payload.deliveryDate = dayjs(deliveryDate).format("YYYY-MM-DD HH:mm");
    this.props.createOrUpdate(payload, id).then(() => {
      this.setState({
        ...this.defaultState,
        id: null,
        confirmationContext: "confirmed",
        confirmationSuccess: true,
      });
    });
  };

  render() {
    const {
      match: { path },
      fetching,
      pageRequest,
      pageRequestError,
      permissions,
    } = this.props;
    const {
      notifications,
      searchTerm,
      lastPage,
      perPage,
      page,
      total,
      previewMode,
      open,
      confirmationContext,
      confirmationSuccess,
      title,
      description,
      deliveryType,
    } = this.state;
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const canApprove = hasPermission(permissions, "approve_pushnotification");
    const canCreate = hasPermission(permissions, "create_pushnotification");
    const canDelete = hasPermission(permissions, "delete_pushnotification");
    const prompt = this.confirmationData()[confirmationContext];

    const isShowingActive =
      this.currentActivePage() === "active" ? true : false;
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    const multiApprovalOptions = [
      canDelete && {
        name: "Delete",
        handler: () =>
          this.setConfirmationContext("deleteNotification", null, true),
      },
    ].filter(Boolean);

    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() < selectedDate.getTime();
    };

    return (
      <div className="setup-inner">
        <RightPanel open={open} onClose={this.closePanel}>
          <h1 className="panel-heading">
            {previewMode
              ? "Push Notification Details"
              : description
              ? "Edit Notification"
              : "Create New Notification"}
          </h1>
          {!previewMode ? (
            <form
              className="form employer-onboard__form"
              onSubmit={this.createOrUpdate}
            >
              <label className="panel-label" htmlFor="name">
                Notification title
              </label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={this.onInputChange}
                className={`form-control panel-input ${
                  createErrorObject.title ? "error-input" : ""
                }`}
                placeholder="Notification title"
                id="title"
                autoComplete="off"
                required
              />
              <InputError error={createErrorObject.title} />
              <label className="panel-label" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={this.onInputChange}
                placeholder="Type here...."
                className={`form-control panel-input panel-text-area ${
                  createErrorObject.description ? "error-input" : ""
                }`}
                cols="30"
                rows="12"
                required
              ></textarea>
              <InputError error={createErrorObject.description} />
              <label className="panel-label">
                Do you want to push this notification right now?
              </label>
              <div>
                <input
                  type="checkbox"
                  id="notifyNow"
                  checked={deliveryType === "instant"}
                  onChange={(e) =>
                    this.setState({
                      deliveryType: e.target.checked ? "instant" : "scheduled",
                    })
                  }
                />
                <label className="panel-label ml-3 mr-5" htmlFor="notifyNow">
                  Yes
                </label>
                <input
                  type="checkbox"
                  id="notNotifyNow"
                  checked={deliveryType === "scheduled"}
                  onChange={(e) =>
                    this.setState({
                      deliveryType: e.target.checked ? "scheduled" : "instant",
                    })
                  }
                />
                <label className="panel-label ml-3" htmlFor="notNotifyNow">
                  No
                </label>
              </div>
              <label className="panel-label mt-4" htmlFor="description">
                When do you want to push this notification?
              </label>
              <div className="push-date-picker">
                <DatePicker
                  className={`form-control panel-input panel-text-area ${
                    createErrorObject.deliveryDate ? "error-input" : ""
                  }`}
                  dayClassName={() => "react-datepicker-dates"}
                  disabled={deliveryType === "instant"}
                  required
                  showTimeSelect
                  timeFormat="HH:mm"
                  filterTime={filterPassedTime}
                  minDate={new Date()}
                  selected={
                    this.state.deliveryDate
                      ? new Date(this.state.deliveryDate)
                      : null
                  }
                  onChange={(date) => {
                    this.setState({ deliveryDate: date });
                  }}
                  id="deliveryDate"
                  placeholderText="Select deliveryDate date"
                  dateFormat="MMMM d, yyyy HH:mm "
                  autoComplete="off"
                />
              </div>
              <div className="push-error">
                <InputError error={createErrorObject.deliveryDate} />
              </div>
              <div className="dual-button-box">
                <button
                  type="button"
                  onClick={this.closePanel}
                  className="cp-button-blue"
                >
                  Cancel
                </button>
                <button type="submit" className="cp-button-blue">
                  Request Approval{" "}
                  {pageRequest && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="panel-info">
              {this.renderPreview()}
              <button className="long-button " onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}` }}
                activeClassName="setup-nav__item--active"
              >
                All Notifications
              </NavLink>
            </li>
            {canApprove && (
              <li className="setup-nav__item">
                <NavLink
                  to={{ pathname: `${path}/pending` }}
                  activeClassName="setup-nav__item--active"
                >
                  Pending Approval
                </NavLink>
              </li>
            )}

            <li className="setup-nav__item">
              <NavLink
                exact
                to={{ pathname: `${path}/rejected` }}
                activeClassName="setup-nav__item--active"
              >
                Rejected Notifications
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={this.props.location.pathname}
          />
          {isShowingActive && showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown options={multiApprovalOptions} />
          )}
          {this.currentActivePage() === "rejected" &&
            showMultiAprrovalDropdown && (
              <MultipleApprovalDropdown options={multiApprovalOptions} />
            )}

          {this.currentActivePage() === "pending" &&
            showMultiAprrovalDropdown && (
              <MultipleApprovalDropdown
                approve={() =>
                  this.setConfirmationContext("approveNotification", null, true)
                }
                reject={() =>
                  this.setConfirmationContext("rejectNotification", null, true)
                }
              />
            )}

          {notifications.length > 0 && (
            <ExportDropdown
              module="pushNotification"
              status={
                this.currentActivePage() === "active"
                  ? "active"
                  : this.currentActivePage() === "pending"
                  ? "pending"
                  : "rejected"
              }
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportNotifications}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {isShowingActive && canCreate && (
            <button
              className="setup-inner__button"
              onClick={() => this.setState({ open: true, id: null })}
            >
              <img
                src={require("@/assets/icons/plus.svg")}
                alt="plus icon"
                className="setup-inner__button-img"
              />
              Create new notification
            </button>
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!notifications.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Notification"}`}
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getNotifications(page, limit, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    <Switch>
                      <Route
                        exact
                        path={path}
                        render={() => this.renderActiveNotifications()}
                      />
                      <Route
                        exact
                        path={`${path}/pending`}
                        render={() => this.renderPendingNotifications()}
                      />
                      <Route
                        exact
                        path={`${path}/rejected`}
                        render={() => this.renderRejectedNotifications()}
                      />
                    </Switch>
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${notifications.length} of ${total}`}</span>
                    Notifications
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.user.permissions,
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_PUSH_NOTIFICATION_REQUEST
    ),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.PUSH_NOTIFICATION_PAGE_REQUEST
    ),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.PUSH_NOTIFICATION_PAGE_ERROR
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPushNotifications: (page, limit, isPending) =>
      dispatch(fetchPushNotifications(page, limit, isPending)),
    searchPushNotifications: (page, limit, status, searchTerm) =>
      dispatch(searchPushNotifications(page, limit, status, searchTerm)),
    exportNotifications: (module, format, status, page, limit) =>
      dispatch(exportNotifications(module, format, status, page, limit)),
    changeStatus: (ids, status, reason) =>
      dispatch(changeStatus(ids, status, reason)),
    createOrUpdate: (payload, id) => dispatch(createOrUpdate(payload, id)),
    deleteNotification: (ids) => dispatch(deleteNotification(ids)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PushNotification)
);
