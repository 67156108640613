import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import {
  fetchBookings,
  updateBookings,
  makeBooking,
  searchBookings,
  exportBookings,
  updateMultipleBookings,
} from "@/store/Transactions/bookingsAction";
import { clearError } from "@/store/ui/actions";
import { getAllInvestments } from "@/store/investments/actions";
import { findUser } from "@/store/user/actions";
import actionTypes from "@/store/Transactions/actionTypes";
import userActionTypes from "@/store/user/actionTypes";
import { showAlert } from "@/store/ui/actions";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import { currency, serializeErrors, hasPermission } from "@/utils";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Emptystate from "@/components/Emptystate";
import ConfirmationBox from "@/components/ConfirmationBox";
import InputError from "@/components/InputError";
import Pagination from "@/components/Pagination";
import PermissionsDropdown from "@/components/PermissionsDropdown";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";
import "react-datepicker/dist/react-datepicker.css";

class Bookings extends Component {
  state = {
    open: false,
    previewMode: false,
    bookings: [],
    type: "",
    foundUsers: [],
    selectedUserId: "",
    selectedUserName: "",
    searchTerm: "",
    shouldSearch: false,
    selectedRowsIds: [],
  };

  componentDidMount() {
    const { status } = this.props.match.params;
    this.getBookings(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        { searchTerm: "", shouldSearch: false, selectedRowsIds: [] },
        () => this.getBookings(1, 10, status)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchBookings(1, 10, status, this.state.searchTerm);
    }
  }

  canConfirm = hasPermission(this.props.permissions, "confirm_investment");
  canApprove = hasPermission(this.props.permissions, "approve_investment");

  getBookings = (page, limit, status) => {
    if (this.state.searchTerm)
      return this.searchBookings(page, limit, status, this.state.searchTerm);
    this.props.fetchBookings(page, limit, status).then((bookings) => {
      this.setPageData(bookings.data.investments);
    });
  };

  searchBookings = (page, limit, status, searchTerm) => {
    if (!searchTerm) return this.getBookings(1, 10, status);
    this.props
      .searchBookings(page, limit, status, searchTerm)
      .then((bookings) => {
        this.setPageData(bookings.data.investments, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: bookings },
    searchTerm,
    shouldSearch
  ) => {
    this.setState({
      bookings,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch,
    });
  };

  dropdownmenus = (bookingId) => {
    const { status } = this.props.match.params;
    return [
      status === "pending" && this.canConfirm
        ? {
            name: "Confirm booking",
            handler: () =>
              this.setConfirmationContext("confirmBooking", bookingId),
          }
        : null,
      status === "confirmed" && this.canApprove
        ? {
            name: "Approve booking",
            handler: () =>
              this.setConfirmationContext("approveBooking", bookingId),
          }
        : null,
      status !== "active" && this.canApprove
        ? {
            name: "Reject booking",
            handler: () =>
              this.setConfirmationContext("rejectBooking", bookingId),
          }
        : null,
    ].filter(Boolean);
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      confirmBooking: {
        title: `Confirm booking${addS ? "s" : ""}`,
        question: `Are you sure you want to confirm ${
          addS ? "these" : "this"
        } booking${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultipleBookings("confirm")
            : this.updateBooking("confirm"),
      },
      approveBooking: {
        title: `Approve booking${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } booking${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultipleBookings("approve")
            : this.updateBooking("approve"),
      },
      rejectBooking: {
        title: `Reason for rejecting booking${addS ? "s" : ""}`,
        requiresReason: true,
        action: (reason) =>
          isMultipleApproval
            ? this.updateMultipleBookings("reject", reason)
            : this.updateBooking("reject", reason),
      },
    };
  };

  setConfirmationContext = (context, bookingId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      bookingId,
      isMultipleApproval,
    });
  };

  updateMultipleBookings = (status, reason) => {
    const { bookings, selectedRowsIds, total } = this.state;
    this.props
      .updateMultipleBookings(selectedRowsIds, status, reason)
      .then(() => {
        const unchangedItems = bookings.filter(
          (data) => !selectedRowsIds.includes(data.id)
        );
        this.setState({
          bookings: unchangedItems,
          confirmationSuccess: true,
          total: total - selectedRowsIds.length,
          selectedRowsIds: [],
        });
      });
  };

  updateBooking = (newStatus, reason) => {
    const { bookings, bookingId, total } = this.state;
    this.props.updateBookings(bookingId, newStatus, reason).then(() => {
      const allBookings = [...bookings];
      const bookingIndex = allBookings.findIndex(
        (booking) => booking.id === bookingId
      );
      allBookings.splice(bookingIndex, 1);
      this.setState({
        bookings: allBookings,
        confirmationSuccess: true,
        total: total - 1,
      });
    });
  };

  onRowClick = (e, booking) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "Reference Number": booking.reference,
          Category: booking.service.name,
          "Full Name": `${booking.user.firstName} ${booking.user.lastName}`,
          "Target Amount": `N${currency(booking.targetAmount)}`,
          Frequency: booking.frequency,
          "Payment Amount": `N${currency(booking.installment)}`,
          "Start Date": dayjs(booking.startDate).format("D MMM YYYY"),
          "Maturity endDate": booking.endDate
            ? dayjs(booking.endDate).format("D MMM YYYY")
            : "N/A",
        },
        open: true,
      });
    }
  };

  onCheckRow = (e, rowId) => {
    const selectedRowsIds = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRowsIds.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRowsIds.findIndex((id) => id === rowId);
      selectedRowsIds.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds });
  };

  renderBookings = () => {
    const { status } = this.props.match.params;
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            {status !== "active" && <th></th>}
            <th>Reference Number</th>
            <th>Category</th>
            <th>Title</th>
            <th>Full Name</th>
            <th>Target Amt.</th>
            <th>Frequency</th>
            <th>Payment Amt.</th>
            <th>Start Date</th>
            <th>Maturity Date</th>
            <th></th>
          </tr>
          {this.state.bookings.map((booking) => (
            <tr
              key={booking.id}
              onClick={(e) => this.onRowClick(e, booking)}
              className="cursor-pointer"
            >
              {status !== "active" && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, booking.id)}
                  />
                </td>
              )}
              <td style={{ whiteSpace: "normal", maxWidt: "24rem" }}>
                {booking.reference}
              </td>
              <td>{booking.service.name}</td>
              <td>{booking.title}</td>
              <td className="text-capitalize">{`${booking.user.firstName} ${booking.user.lastName}`}</td>
              <td>{`N${currency(booking.targetAmount)}`}</td>
              <td>{booking.frequency}</td>
              <td>{`N${currency(booking.installment)}`}</td>
              <td>{dayjs(booking.startDate).format("D MMM YYYY")}</td>
              <td>
                {booking.endDate
                  ? dayjs(booking.endDate).format("D MMM YYYY")
                  : "N/A"}
              </td>
              <td className="custum-table__ellipsis">
                <Dropdown menu={this.dropdownmenus(booking.id)} arrow={true}>
                  <button className="wrapper-button ellipsis">
                    <img
                      src={require("@/assets/icons/flat-ellipsis.svg")}
                      alt="dropdown"
                    />
                  </button>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  onMakebooking = () => {
    const { services, getServices } = this.props;
    if (!services.length) getServices();
    this.setState({
      open: true,
    });
  };

  createBooking = (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    if (!this.state.selectedUserId) {
      return this.props.showAlert({
        type: "error",
        message: "Please select a user",
      });
    }
    let payload = { type: this.state.type, user_id: this.state.selectedUserId };
    Array.prototype.forEach.call(formElements, (data) => {
      payload[data.name] = ["startDate", "endDate"].includes(data.name)
        ? dayjs(data.value).format("YYYY-MM-DD")
        : data.value;
    });
    this.props.makeBooking(payload);
  };

  setServiceType = (e) => {
    const service = this.props.services.find(
      (service) => service.id === Number(e.target.value)
    );
    this.setState({ type: service?.type });
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
    this.props.clearError();
  };

  SearchUsers = (name = "") => {
    const { foundUsers, searchTerm } = this.state;
    if (name === searchTerm) return this.setState({ foundUsers: [] });
    this.setState({ selectedUserId: "" });
    if (name.length < 2 || this.props.searchingUsers) return foundUsers;
    this.props.SearchUsers(name).then((users) => {
      this.setState({
        foundUsers: users.map((user) => ({
          name: `${user.firstName} ${user.lastName}`,
          id: user.id,
        })),
        searchTerm: name,
      });
    });
  };

  render() {
    const {
      match: {
        params: { status },
      },
      fetching,
      pageRequest,
      createError,
      services,
      permissions,
      searchingUsers,
    } = this.props;
    const {
      previewMode,
      bookings,
      confirmationContext,
      confirmationSuccess,
      lastPage,
      perPage,
      page,
      total,
      type,
      foundUsers,
      searchTerm,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const createErrorObject = serializeErrors(createError?.message);
    const canCreate = hasPermission(permissions, "create_investment");
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;
    const multiApprovalOptions = [
      status === "pending" &&
        this.canConfirm && {
          name: "Confirm",
          handler: () =>
            this.setConfirmationContext("confirmBooking", null, true),
        },
      status === "confirmed" &&
        this.canApprove && {
          name: "Approve",
          handler: () =>
            this.setConfirmationContext("approveBooking", null, true),
        },
      status !== "active" &&
        this.canApprove && {
          name: "Reject",
          handler: () =>
            this.setConfirmationContext("rejectBooking", null, true),
        },
    ].filter(Boolean);

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">
            {previewMode ? "Booking Details" : "Make a Booking"}
          </h1>
          {!previewMode ? (
            <form
              className="form employer-onboard__form"
              onSubmit={this.createBooking}
            >
              <label className="panel-label" htmlFor="service">
                Category
              </label>
              <select
                required
                className="form-control panel-input"
                id="service"
                name="serviceId"
                onChange={this.setServiceType}
              >
                <option value="">Select a catgory</option>
                {services.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </select>
              <InputError error={createErrorObject.service_id} />
              <label className="panel-label" htmlFor="title">
                Investment title
              </label>
              <input
                type="text"
                className="form-control panel-input"
                placeholder="Investment title"
                id="title"
                name="title"
                autoComplete="off"
                required
              />
              <InputError error={createErrorObject.title} />
              <label className="panel-label" htmlFor="target-amount">
                Target Amount
              </label>
              <input
                type="text"
                className="form-control panel-input"
                placeholder="5,000,000"
                id="target-amount"
                name="targetAmount"
                autoComplete="off"
                required
              />
              <InputError error={createErrorObject.targetAmount} />
              {type === "collection" && (
                <>
                  <label className="panel-label" htmlFor="amount">
                    Payment Amount
                  </label>
                  <input
                    type="text"
                    className="form-control panel-input"
                    placeholder="5,000"
                    id="amount"
                    name="amount"
                    autoComplete="off"
                    required
                  />
                  <InputError error={createErrorObject.amount} />
                </>
              )}
              <input type="hidden" name="currency" value="NGN" />
              <InputError error={createErrorObject.currency} />

              <label className="panel-label" htmlFor="start-date">
                Start date
              </label>
              <DatePicker
                className="form-control panel-input"
                dayClassName={() => "react-datepicker-dates"}
                minDate={new Date()}
                onChange={(date) => this.setState({ selectedStartDate: date })}
                selected={
                  selectedStartDate ? new Date(selectedStartDate) : null
                }
                id="start-date"
                name="startDate"
                placeholderText="21-11-2020"
                autoComplete="off"
                required
                dateFormat="dd-MM-yyyy"
              />
              <InputError
                error={
                  createErrorObject.startDate ||
                  createErrorObject.isNotValidStartDate
                }
              />

              {type !== "collection" && (
                <>
                  <label className="panel-label" htmlFor="end-date">
                    Target date
                  </label>
                  <DatePicker
                    className="form-control panel-input"
                    dayClassName={() => "react-datepicker-dates"}
                    minDate={
                      selectedStartDate
                        ? new Date(dayjs(selectedStartDate).add(1, "day"))
                        : new Date(dayjs().add(1, "day"))
                    }
                    onChange={(date) =>
                      this.setState({ selectedEndDate: date })
                    }
                    selected={
                      selectedEndDate ? new Date(selectedEndDate) : null
                    }
                    id="end-date"
                    name="endDate"
                    placeholderText="21-11-2020"
                    autoComplete="off"
                    required
                    dateFormat="dd-MM-yyyy"
                  />
                  <InputError error={createErrorObject.endDate} />
                </>
              )}
              <label className="panel-label" htmlFor="frequency">
                Frequency
              </label>
              <select
                required
                className="form-control panel-input"
                id="frequency"
                name="frequency"
              >
                <option value="">Select frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="bi-annually">Bi-annually</option>
                <option value="annually">Annually</option>
              </select>
              <InputError error={createErrorObject.frequencyOfPayment} />
              <label className="panel-label" htmlFor="user-id">
                User
              </label>
              <PermissionsDropdown
                permissions={foundUsers}
                id="user-id"
                fetchDataHandler={this.SearchUsers}
                isSimpleInput
                passPermissions={(data) =>
                  this.setState({ selectedUserId: data.id })
                }
                placeholder="Search for a user"
                isFetching={searchingUsers}
                isRequired
              />
              <InputError error={createErrorObject.user_id} />
              <div className="dual-button-box">
                <button onClick={this.closePanel} className="cp-button-blue">
                  Cancel
                </button>
                <button className="cp-button-blue">
                  Request Confirmation{" "}
                  {pageRequest && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="panel-info">
              {this.renderPreview()}
              <button className="long-button" onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "pending",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "pending"}
                activeClassName="setup-nav__item--active"
              >
                Awaiting Confirmation
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "confirmed",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "confirmed"}
                activeClassName="setup-nav__item--active"
              >
                Pending Approval
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "active",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "active"}
                activeClassName="setup-nav__item--active"
              >
                Approved
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {!showMultiAprrovalDropdown && bookings.length > 0 && (
            <ExportDropdown
              module="bookings"
              status={
                status === "pending"
                  ? "awaiting-confirmation"
                  : status === "confirmed"
                  ? "pending-approval"
                  : "approved"
              }
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportBookings}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown options={multiApprovalOptions} />
          )}
          {canCreate && (
            <button
              className="setup-inner__button"
              onClick={this.onMakebooking}
            >
              <img
                src={require("@/assets/icons/plus.svg")}
                alt="plus icon"
                className="setup-inner__button-img"
              />
              Make a booking
            </button>
          )}
        </div>
        {fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!bookings.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Bookings"}`}
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getBookings(page, limit, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderBookings()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${bookings.length} of ${total}`}</span>
                    Bookings
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: {
      investments: { allInvestments },
    },
  } = state;
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_BOOKINGS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.BOOKINGS_PAGE_REQUEST
    ),
    createError: getActionErrorState(state, actionTypes.BOOKINGS_PAGE_ERROR),
    searchingUsers: getActionLoadingState(
      state,
      userActionTypes.FIND_USER_REQUEST
    ),
    permissions: state.user.permissions,
    services: allInvestments?.data.investments.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBookings: (page, limit, status) =>
      dispatch(fetchBookings(page, limit, status)),
    searchBookings: (page, limit, status, searchTerm) =>
      dispatch(searchBookings(page, limit, status, searchTerm)),
    updateBookings: (id, newStatus, reason) =>
      dispatch(updateBookings(id, newStatus, reason)),
    updateMultipleBookings: (ids, newStatus, reason) =>
      dispatch(updateMultipleBookings(ids, newStatus, reason)),
    exportBookings: (module, format, status, page, limit) =>
      dispatch(exportBookings(module, format, status, page, limit)),
    makeBooking: (data) => dispatch(makeBooking(data)),
    getServices: () => dispatch(getAllInvestments(1000000)),
    SearchUsers: (name) => dispatch(findUser(name)),
    clearError: () => dispatch(clearError()),
    showAlert: (message) => dispatch(showAlert(message)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Bookings)
);
