import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getActionLoadingState } from "@/store/selectors";
import actionTypes from "@/store/support/actionTypes";
import { getTickets } from '@/store/support/actions'
import DataTable from '@/components/DataTable';
import Pagination from '@/components/Pagination';
import { columns } from '../utils';
import './style.scss';

class UnsolvedTickets extends React.Component {


  componentDidMount() {
    this.props.getTickets('open', 20, 1);
  }

  naviagetToTicket = (id) => {
    this.props.history.push({
      pathname: `/dashboard/support/tickets/ticket/${id}`,
      state: { pageTitle: 'Support' },
    })
  }

  render() {
    const { tickets, loading, metadata } = this.props;
    return (
      <div className="unresolved-tickets-page">
        {metadata && <Pagination
          totalPages={metadata.lastPage}
          page={metadata.page}
          limit={metadata.perPage}
          changePageHandler={(page, limit) => this.props.getTickets('open', limit, page)}
          />}
        <div className="mt-2 card">
        {loading &&
            <div className="text-center p-4">
              <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
            </div>
          }
          {tickets && tickets.length > 0 ?
            <DataTable data={tickets} columns={columns} info={metadata} selectItem={this.naviagetToTicket} />
            :
            (!loading &&
              <div className="p-5 text-center">
                <img src={require('@/assets/icons/receipt.svg')} alt="no-tickets" className="img-fluid" />
                <p className="font-light text-grey">{this.props.search !== ''.trim() ? 'No ticket history matches this search' : 'You have no booked tickets'}</p>
              </div>)
          }
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  const { app: { support: { tickets, error, metadata } } } = state;

  return {
    loading: getActionLoadingState(state, actionTypes.GET_TICKETS_REQUEST),
    tickets,
    error,
    metadata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTickets: (status, limit, pageNumber) => dispatch(getTickets(status, limit, pageNumber)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnsolvedTickets));
