import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import {
  getBarChartOptions,
  getSuccessChartOptions,
  getTransactionChartOptions,
} from "@/utils/index";
import { fetchInvestments } from "@/store/analytics/termedInvestments";
import Dropdown from "@/components/DropdownMenu";
import ExportDropdown from "@/components/ExportDropdown";
import AnalyticsDateInput from "@/components/AnalyticsDateInput";

import "./style.scss";

class ValueOfInvestment extends Component {
  state = {
    noOfInvestmentType: null,
    valueOfTargetInvestmentType: null,
    valueOfInvestmentType: null,
    avgSuccessCategory: null,
    avgTenorInvestment: null,
  };

  componentDidMount() {
    this.fetchNoOfInvestmentType();
    this.fetchInvestmentTargetCategory();
    this.fetchValueOfInvestmentType();
    this.fetchAvgSuccessCategory();
    this.fetchAvgTenorInvestment();
  }

  fetchNoOfInvestmentType = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");

    this.props
      .fetchInvestments("no-of-investment", date, this.props.status)
      .then((res) => {
        this.setState({ noOfInvestmentType: res.data.noOfInvestment });
      });
  };
  fetchInvestmentTargetCategory = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");

    this.props
      .fetchInvestments(
        "value-of-investment-target-by-type",
        date,
        this.props.tStatus
      )
      .then((res) => {
        this.setState({
          valueOfTargetInvestmentType: res.data.valueOfInvestmentTargetByType,
        });
      });
  };

  fetchValueOfInvestmentType = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");

    this.props
      .fetchInvestments(
        "value-of-investment-by-type",
        date,
        this.props.invStatus
      )
      .then((res) => {
        this.setState({
          valueOfInvestmentType: res.data.valueOfInvestmentByType,
        });
      });
  };
  fetchAvgSuccessCategory = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");

    this.props
      .fetchInvestments(
        "average-success-rate-of-investment-by-category",
        date,
        this.props.avgCatStatus
      )
      .then((res) => {
        this.setState({
          avgSuccessCategory: res.data.averageSuccessRateOfInvestmentByCategory,
        });
      });
  };
  fetchAvgTenorInvestment = () => {
    const date = this.props.date && dayjs(this.props.date).format("YYYY-MM-DD");

    this.props
      .fetchInvestments(
        "average-tenor-of-investment-by-category",
        date,
        this.props.tenorStatus
      )
      .then((res) => {
        this.setState({
          avgTenorInvestment: res.data.averageTenorOfInvestmentByCategory,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.date !== prevProps.date) {
      this.fetchNoOfInvestmentType();
      this.fetchInvestmentTargetCategory();
      this.fetchValueOfInvestmentType();
      this.fetchAvgSuccessCategory();
      this.fetchAvgTenorInvestment();
    }
    if (this.props.status !== prevProps.status) {
      this.fetchNoOfInvestmentType();
    }
    if (this.props.tStatus !== prevProps.tStatus) {
      this.fetchInvestmentTargetCategory();
    }
    if (this.props.invStatus !== prevProps.invStatus) {
      this.fetchValueOfInvestmentType();
    }
    if (this.props.avgCatStatus !== prevProps.avgCatStatus) {
      this.fetchAvgSuccessCategory();
    }
    if (this.props.tenorStatus !== prevProps.tenorStatus) {
      this.fetchAvgTenorInvestment();
    }
  }

  analyticsOptions = [
    { name: "Active Investments", value: "active" },
    {
      name: "Matured Investments",
      value: "matured",
    },
    { name: "Terminated Investments", value: "terminated" },
  ];
  targetTypeOptions = [
    {
      name: "Predefined",
      value: "predefined",
    },
    { name: "Collection", value: "collection" },
  ];
  investmentTypeOptions = [
    {
      name: "Predefined",
      value: "predefined",
    },
    { name: "Collection", value: "collection" },
  ];
  avgCategoryOptions = [
    {
      name: "Predefined",
      value: "predefined",
    },
    { name: "Collection", value: "collection" },
  ];
  tenorCategoryOptions = [
    { name: "Active Investments", value: "active" },
    {
      name: "Matured Investments",
      value: "matured",
    },
    { name: "Terminated Investments", value: "terminated" },
  ];
  render() {
    const {
      noOfInvestmentType,
      valueOfTargetInvestmentType,
      valueOfInvestmentType,
      avgSuccessCategory,
      avgTenorInvestment,
    } = this.state;
    const {
      selectedInvestmentValue,
      selectedValue,
      selectedInvTypeValue,
      selectedAvgValue,
      selectedTenorValue,
      status,
      tStatus,
      invStatus,
      avgCatStatus,
      tenorStatus,
    } = this.props;
    const backgroundColors = [
      "#9DC6FB",
      "#C8EA73",
      "#F4A28C",
      "#F48CEA",
      "#9DC6FB",
      "#9DC6FB",
      "#FFCEBF",
      "#A0A2EC",
      "#9DC6FB",
      "#9DC6FB",
      "#FFCEBF",
      "#C8EA73",
      "#8ACED8",
      "#FFCEBF",
      "#FFE9A3",
      "#9DC6FB",
      "#9DC6FB",
      "#FFCEBF",
      "#C8EA73",
      "#8ACED8",
      "#8ACED8",
      "#F48CA5",
      "#F48CEA",
      "#8ACED8",
      "#F48CEA",
      "#9DC6FB",
      "#9DC6FB",
      "#FFCEBF",
      "#C8EA73",
      "#8ACED8",
      "#8ACED8",
    ];
    const noOfInvestmentData = () => ({
      labels: [...this.state.noOfInvestmentType.labels],
      datasets: [
        {
          data: [...this.state.noOfInvestmentType.data],
          backgroundColor: [...backgroundColors],
        },
      ],
    });

    const targetInvestmentTypeData = () => ({
      labels: [...this.state.valueOfTargetInvestmentType.labels],
      datasets: [
        {
          data: [...this.state.valueOfTargetInvestmentType.data],
          backgroundColor: [...backgroundColors],
        },
      ],
    });

    const valueOfInvestmentTypeData = () => ({
      labels: [...this.state.valueOfInvestmentType.labels],
      datasets: [
        {
          data: [...this.state.valueOfInvestmentType.data],
          backgroundColor: [...backgroundColors],
        },
      ],
    });
    const avgSuccessCategoryData = () => ({
      labels: [...this.state.avgSuccessCategory.labels],
      datasets: [
        {
          data: [...this.state.avgSuccessCategory.data],
          backgroundColor: [...backgroundColors],
        },
      ],
    });

    const avgTenorInvestmentData = () => ({
      labels: [...this.state.avgTenorInvestment.labels],
      datasets: [
        {
          data: [...this.state.avgTenorInvestment.data],
          backgroundColor: [...backgroundColors],
        },
      ],
    });
    const noOfInvestmentOptions = this.analyticsOptions?.map((option) => ({
      name: option.name,
      handler: () => selectedInvestmentValue(option.value),
    }));
    const targetTypeOptions = this.targetTypeOptions?.map((option) => ({
      name: option.name,
      handler: () => selectedValue(option.value),
    }));
    const investmentTypeOptions = this.investmentTypeOptions?.map((option) => ({
      name: option.name,
      handler: () => selectedInvTypeValue(option.value),
    }));
    const avgCategoryOptions = this.avgCategoryOptions?.map((option) => ({
      name: option.name,
      handler: () => selectedAvgValue(option.value),
    }));
    const tenorCategoryOptions = this.tenorCategoryOptions?.map((option) => ({
      name: option.name,
      handler: () => selectedTenorValue(option.value),
    }));

    return (
      <div id="termed-container">
        <div className="print-all">
          <AnalyticsDateInput value={this.props.date} />
          <ExportDropdown
            module="termed-investments"
            status="maturities"
            excludeCSV
            includeJpeg
          />
        </div>

        <div
          className="setup-inner__main p-5 investment-graph"
          id="termed-print"
        >
          <div className="text-center investment-chart">
            <h5 className="text-medium pb-8">No. of Investments by Type</h5>
            <div className="investment-menu">
              <Dropdown menu={noOfInvestmentOptions} width="180px" left>
                <div className="setup-nav__dropdown w-100">
                  <span className="text-capitalize">
                    {status === "active"
                      ? "Active Investments"
                      : status === "matured"
                      ? "Matured Investments"
                      : "Terminated Investments"}
                  </span>

                  <img
                    src={require("@/assets/icons/dropdown-caret.svg")}
                    alt="arrow down"
                    className=""
                  />
                </div>
              </Dropdown>
            </div>
            <div />
          </div>
          {!noOfInvestmentType ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={noOfInvestmentData}
              options={getBarChartOptions("Count", "Type")}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 investment-graph"
          id="termed-print"
        >
          <div className="text-center">
            <h5 className="text-medium">Value of Targets by Investment Type</h5>
            <div className="investment-menu">
              <Dropdown menu={targetTypeOptions} width="180px" left>
                <div className="setup-nav__dropdown w-100">
                  <span className="text-capitalize">
                    {tStatus === "predefined" ? "Predefined" : "Collection"}
                  </span>

                  <img
                    src={require("@/assets/icons/dropdown-caret.svg")}
                    alt="arrow down"
                    className=""
                  />
                </div>
              </Dropdown>
              <div className="date">
                <AnalyticsDateInput value={this.props.date} />
              </div>
            </div>
          </div>
          {!valueOfTargetInvestmentType ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={targetInvestmentTypeData}
              options={getTransactionChartOptions("Value", "Type")}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 investment-graph"
          id="termed-print"
        >
          <div className="text-center">
            <h5 className="text-medium">
              Value of Investments by Investment Type
            </h5>
            <div className="investment-menu">
              <Dropdown menu={investmentTypeOptions} width="180px" left>
                <div className="setup-nav__dropdown w-100">
                  <span className="text-capitalize">
                    {invStatus === "predefined" ? "Predefined" : "Collection"}
                  </span>
                  <img
                    src={require("@/assets/icons/dropdown-caret.svg")}
                    alt="arrow down"
                    className=""
                  />
                </div>
              </Dropdown>
              <div className="date">
                <AnalyticsDateInput value={this.props.date} />
              </div>
            </div>
          </div>
          {!valueOfInvestmentType ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={valueOfInvestmentTypeData}
              options={getTransactionChartOptions(
                "Value of Investments",
                "Type"
              )}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 investment-graph"
          id="termed-print"
        >
          <div className="text-center">
            <h5 className="text-medium">
              Average Success Rate of Target by Category
            </h5>
            <div className="investment-menu">
              <Dropdown menu={avgCategoryOptions} width="180px" left>
                <div className="setup-nav__dropdown w-100">
                  <span className="text-capitalize">
                    {avgCatStatus === "predefined"
                      ? "Predefined"
                      : "Collection"}
                  </span>
                  <img
                    src={require("@/assets/icons/dropdown-caret.svg")}
                    alt="arrow down"
                    className=""
                  />
                </div>
              </Dropdown>
              <div className="date">
                <AnalyticsDateInput value={this.props.date} />
              </div>
            </div>
          </div>
          {!avgSuccessCategory ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={avgSuccessCategoryData}
              options={getSuccessChartOptions("Percentage", "Type")}
            />
          )}
        </div>
        <div
          className="setup-inner__main p-5 mt-5 investment-graph"
          id="termed-print"
        >
          <div className="text-center">
            <h5 className="text-medium">
              Average Tenor of Investment by Category
            </h5>
            <div className="investment-menu">
              <Dropdown menu={tenorCategoryOptions} width="180px" left>
                <div className="setup-nav__dropdown w-100">
                  <span className="text-capitalize">
                    {tenorStatus === "active"
                      ? "Active Investments"
                      : tenorStatus === "matured"
                      ? "Matured Investments"
                      : "Terminated Investments"}
                  </span>

                  <img
                    src={require("@/assets/icons/dropdown-caret.svg")}
                    alt="arrow down"
                    className=""
                  />
                </div>
              </Dropdown>
              <div className="date">
                <AnalyticsDateInput value={this.props.date} />
              </div>
            </div>
          </div>
          {!avgTenorInvestment ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Bar
              data={avgTenorInvestmentData}
              options={getBarChartOptions("No. of Investments", "Tenor")}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvestments: (view, date, status) =>
      dispatch(fetchInvestments(view, date, status)),
  };
};

export default connect(null, mapDispatchToProps)(ValueOfInvestment);
