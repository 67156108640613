import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import { getActionLoadingState } from "@/store/selectors";
import { getSummaryReport } from "@/store/ppi/actions";
import actionTypes from "@/store/ppi/actionTypes";
import Pagination from '@/components/Pagination';
import Emptystate from '@/components/Emptystate';
import './style.scss';

class ClickSummary extends React.Component {

  componentDidMount() {
    this.getClickSummary(1, 10)
  }

  getClickSummary = (page, limit) => {
    const { id } = this.props;
    this.props.getSummaryReport(id, page, limit)
  }

  render() {
    const { summary, loading } = this.props;
    return (
      <div className="click-summary-page">
        <Pagination
          totalPages={summary?.lastPage}
          page={summary?.page}
          limit={summary?.perPage}
          changePageHandler={(page, limit) => this.getClickSummary(page, limit)}
        />
        {!summary && loading &&
          <div className="text-center p-4">
            <div className="spinner-border spinner-border-primary text-primary spinner-border-md mr-2"></div>
          </div>
        }
        {summary && (
          summary?.mutual?.data?.length > 0 ?
            <>
              <div className="setup-inner__main">
                <table className="custum-table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email Address</th>
                      <th>Date of Click</th>
                    </tr>
                    {summary?.mutual?.data.map(item => (
                      <tr key={Math.random()*1000}>
                        <td><span>{item.firstName} {item.lastName}</span></td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{dayjs(item.created_at).format('DD MMM, YYYY')}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" className="text-center">Showing {summary?.mutual?.data?.length} of {summary?.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
            :
            <Emptystate
              title="No Click summary"
              icon={require('@/assets/icons/info.svg')}
            />)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { app:
    {
      ppi: {
        summary
      }
    }
  } = state;
  return {
    loading: getActionLoadingState(state, actionTypes.GET_REPORT_SUMMARY_REQUEST),
    summary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSummaryReport: (id, page, limit ) => dispatch(getSummaryReport(id, page, limit)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClickSummary));
