import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import dayjs from 'dayjs';
import CPLogo from '@/assets/images/cp-logo-white.png'
import { ReactComponent as DashboardIcon } from '@/assets/icons/dashboard.svg';
import { ReactComponent as AnalyticsIcon } from '@/assets/icons/analytics.svg';
import { ReactComponent as ReportsIcon } from '@/assets/icons/reports.svg';
import { ReactComponent as TransactionsIcon } from '@/assets/icons/transactions.svg';
import { ReactComponent as UsersIcon } from '@/assets/icons/users.svg';
import { ReactComponent as SetupIcon } from '@/assets/icons/setup.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/support.svg';
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings.svg';
import './style.scss';

class DashboardMenu extends Component {
    state = {
        currentTime: '',
    }

    componentDidMount() {
        this.setTime();
    }

    setTime = () => {
        this.setState({
            currentTime: dayjs().format('D MMM YYYY hh:mm A')
        })
        setTimeout(this.setTime, 1000);
    }

    menus = [
        {
            name: 'Dashboard',
            icon: DashboardIcon,
            path: `/dashboard/home`,
        },
        {
            name: 'Analytics',
            icon: AnalyticsIcon,
            path: `/dashboard/analytics`,
        },
        {
            name: 'Reports',
            icon: ReportsIcon,
            path: '/dashboard/reports',
        },
        {
            name: 'Transactions',
            icon: TransactionsIcon,
            path: '/dashboard/transactions',
        },
        {
            name: 'Users',
            icon: UsersIcon,
            path: '/dashboard/users',
        },
        {
            name: 'Setup',
            icon: SetupIcon,
            path: '/dashboard/setup',
        },
        {
            name: 'Support',
            icon: SupportIcon,
            path: '/dashboard/support',
        },
        {
            name: 'Settings',
            icon: SettingsIcon,
            path: '/dashboard/settings',
        },
    ]

    renderMenus = () => this.menus.map(menu => {
        let Icon = menu.icon;
        return <NavLink 
            to={{ pathname: menu.path, state: { pageTitle: menu.name} }} 
            key={menu.name} 
            activeClassName="main-menu__menu-item--active"
            >
            <div className="main-menu__menu-item">
                <Icon className="main-menu__menu-icon" />
                <span className="main-menu__menu-name">{menu.name}</span>
            </div>
        </NavLink>
    })

    render() {
        return(
            <div className="main-menu">
                <span className="main-menu__logo">
                    <img 
                        src={CPLogo} alt="logo" 
                        className="cp-logo cursor-pointer" 
                        onClick={() => this.props.history.push('/dashboard')} 
                    />
                </span>
                {this.renderMenus()}
                <div className="main-menu__time-heading">
                    <p>Current system time</p>
                    <p>{this.state.currentTime}</p>
                </div>
            </div>
        )
    }
}

export default withRouter(DashboardMenu);