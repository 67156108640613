import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { ReactComponent as BackArrrow } from "@/assets/icons/previous-page-arrow.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit2.svg";
import { fetchSingleCustomer, editCustomerProfile } from "@/store/Customers";
import { fetchBanks } from "@/store/app/actions";
import { showAlert } from "@/store/ui/actions";
import actionTypes from "@/store/Customers/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import {
  currency,
  serializeErrors,
  hasPermission,
  industiesList,
} from "@/utils";
import InputError from "@/components/InputError";
import RightPanel from "@/components/RightPanel";
import PhoneNumberInput from "@/components/PhoneNumberInput";

import "react-datepicker/dist/react-datepicker.css";

class SingleUser extends Component {
  bioDataForm = React.createRef();
  employmentDetailsForm = React.createRef();
  bankDetailsForm = React.createRef();
  nextKinInfoForm = React.createRef();
  otherInfoForm = React.createRef();
  documentUpload = React.createRef();

  state = {
    edit: false,
    edittedSections: [],
    listOfBanks: [],
    selectedDocuments: {},
    selectedIdType: "pvc",
    showID: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchSingleCustomer(id).then(({ data }) => {
      this.setState(data.user);
      this.setCustomerInfo(data.user?.politicalStatus, data.user?.employment);
    });
    this.props.fetchBanks().then((banks) => {
      this.setState({
        listOfBanks: banks.map((bank) => ({
          name: bank.name,
          value: `${bank.code}-${bank.name}`,
        })),
      });
    });
  }

  setCustomerInfo = (politicalStatus, employmentInfo) => {
    this.setState({
      occupiedPoliticalPosition: politicalStatus?.occupiedPoliticalPosition,
      occupiedTillDate: politicalStatus?.occupiedTillDate,
      hasPoliticalAssociate: politicalStatus?.hasPoliticalAssociate,
      associateOccupiedTillDate: politicalStatus?.associateOccupiedTillDate,
      status: employmentInfo?.status,
    });
  };

  cancelEdit = () => {
    this.setCustomerInfo(this.state.politicalStatus, this.state.employment);
    this.setState({
      edit: false,
      //formKeys is is used to set the key of the inputs
      //it is random cus we want the form inputs to rerender so that they maintain their previous value after an edit
      formKeys: Math.random() * 10,
    });
  };

  userDetails = () => [
    {
      label: "Wallet Account Balance",
      value: this.state?.wallets.length
        ? `N${currency(this.state.wallets[0]?.balance)}`
        : "N0.00",
      shouldShow: !this.state.edit,
    },
    {
      label: "Bank Name",
      value: this.state.bankInfo?.bankName,
      shouldShow: !this.state.edit,
    },
    {
      label: "Account Number",
      value: this.state.bankInfo?.accountNumber,
      shouldShow: !this.state.edit,
    },
    {
      label: "User ID",
      value: this.state.id,
      shouldShow: !this.state.edit,
    },
    {
      label: "Registration Date",
      value: dayjs(this.state.created_at).format("D MMM YYYY"),
      shouldShow: !this.state.edit,
    },
    {
      label: "Last Login",
      value: dayjs(this.state.last_login_at).format("D MMM YYYY"),
      shouldShow: !this.state.edit,
    },
    {
      label: "Email Verification Date",
      value: this.state.confirmationDate
        ? dayjs(this.state.confirmationDate).format("D MMM YYYY")
        : "",
      shouldShow: !this.state.edit,
    },
    {
      label: "IP Address",
      value: "N/A",
      shouldShow: !this.state.edit,
    },
  ];

  bioData = () => [
    {
      label: "Title",
      value: "",
      shouldShow: !this.state.edit,
    },
    {
      label: "First Name",
      value: this.state.firstName,
      shouldShow: true,
      name: "firstName",
    },
    {
      label: "Middle Name",
      value: this.state.middleName,
      shouldShow: true,
      name: "middleName",
    },
    {
      label: "Last Name",
      value: this.state.lastName,
      shouldShow: true,
      name: "lastName",
    },
    {
      label: "Email Address",
      type: "email",
      value: this.state.email,
      shouldShow: !this.state.edit,
    },
    {
      label: "Phone Number",
      phoneValue: this.state.phone,
      countryCodeValue: this.state.countryCode ?? "+234",
      shouldShow: true,
      name: "phone",
      type: "phone",
    },
    {
      label: "Gender",
      type: "select",
      options: ["male", "female", "Rather not say"],
      value: this.state.gender,
      shouldShow: true,
      name: "gender",
    },
    {
      label: "Date of Birth",
      value: this.state.dateOfBirth,
      name: "dateOfBirth",
      type: "date",
      shouldShow: !this.state.edit,
    },
    {
      label: "Country of Birth",
      value: this.state.placeOfBirth,
      name: "placeOfBirth",
      shouldShow: true,
    },
    {
      label: "Marital Status",
      value: this.state.maritalStatus,
      type: "select",
      options: ["single", "married"],
      shouldShow: true,
      name: "maritalStatus",
    },
    {
      label: "State of Origin",
      value: this.state.stateOfOrigin,
      name: "stateOfOrigin",
      shouldShow: true,
    },
    {
      label: "Mothers Maiden Name",
      value: this.state.motherMaidenName,
      shouldShow: true,
      name: "motherMaidenName",
    },
    {
      label: "Residential Address",
      value: this.state.residentialAddress,
      shouldShow: true,
      name: "residentialAddress",
    },
    {
      label: "Date of Entry into current residence",
      type: "date",
      value: this.state.residenceDate,
      shouldShow: true,
      name: "residenceDate",
    },
    {
      label: "ID Type",
      value: this.state.idType,
      type: "select",
      options: [
        "International passport",
        "Drivers licence",
        "PVC",
        "National ID",
      ],
      shouldShow: true,
      name: "idType",
    },
    {
      label: "Issue Date",
      value: this.state.idIssueDate,
      type: "date",
      shouldShow: this.state.showID,
      name: "idIssueDate",
    },
    {
      label: "Expiry Date",
      value: this.state.idExpiryDate,
      type: "date",
      shouldShow: this.state.showID,
      name: "idExpiryDate",
    },
    {
      label: "Place of Issue",
      value: this.state.placeOfIssue,
      shouldShow: this.state.showID,
      name: "placeOfIssue",
    },
    {
      label: "Nationality",
      value: this.state.nationality,
      shouldShow: true,
      name: "nationality",
    },
  ];

  employmentDetails = () => [
    {
      label: "Level of qualification",
      value: this.state.employment?.qualification,
      type: "select",
      options: [
        { name: "SSCE", value: "ssce" },
        { name: "NCE", value: "nce" },
        { name: "OND", value: "ond" },
        { name: "HND", value: "hnd" },
        { name: "Bachelors degree", value: "bachelors" },
        { name: "Masters degree", value: "masters" },
        { name: "PhD", value: "phd" },
        { name: "Others", value: "others" },
      ],
      shouldShow: true,
      name: "qualification",
    },
    {
      label: "Employment Status",
      value: this.state.employment?.status,
      type: "select",
      options: [
        "employed",
        "unemployed",
        "self-employed",
        "student",
        "retired",
      ],
      shouldShow: true,
      name: "status",
    },
    {
      label: "Occupation",
      value: this.state.employment?.occupation,
      shouldShow: this.state.status === "employed",
      name: "occupation",
    },
    {
      label: "Appointment Date",
      type: "date",
      value: this.state.employment?.appointmentDate,
      shouldShow: this.state.status === "employed",
      name: "appointmentDate",
    },
    {
      label: "Company Name",
      value: this.state.employment?.companyName,
      shouldShow:
        this.state.status === "employed" ||
        this.state.status === "self-employed",
      name: "companyName",
    },
    {
      label: "Company Type",
      value: this.state.employment?.companyType,
      type: "select",
      options: industiesList,
      shouldShow:
        this.state.status === "employed" ||
        this.state.status === "self-employed",
      name: "companyType",
    },
    {
      label: "Company Address",
      value: this.state.employment?.companyAddress,
      shouldShow:
        this.state.status === "employed" ||
        this.state.status === "self-employed",
      name: "companyAddress",
    },
    {
      label: "Official Phone number",
      phoneValue: this.state.employment?.officialPhoneNumber,
      countryCodeValue: this.state.employment?.countryCode ?? "+234",
      shouldShow: this.state.status === "employed",
      name: "officialPhoneNumber",
      type: "phone",
    },
    {
      label: "Official Email Address",
      value: this.state.employment?.officialEmailAddress,
      shouldShow: this.state.status === "employed",
      name: "officialEmailAddress",
    },
  ];

  bankDetails = () => [
    {
      label: "Bank Name",
      value: `${this.state.bankInfo?.bankCode}-${this.state.bankInfo?.bankName}`,
      shouldShow: true,
      type: "select",
      options: this.state.listOfBanks,
      name: "bankCode",
    },
    {
      label: "Bank account number",
      value: this.state.bankInfo?.accountNumber,
      shouldShow: true,
      name: "accountNumber",
    },
    {
      label: "Bank account name",
      value: this.state.bankInfo?.accountName,
      name: "accountName",
      shouldShow: !this.state.edit,
    },
    {
      label: "BVN",
      value: this.state.bvn,
      shouldShow: !this.state.edit,
    },
    {
      label: "Source of funds",
      value: "",
      shouldShow: !this.state.edit,
    },
    {
      label: "Annual Income",
      value: "",
      shouldShow: !this.state.edit,
    },
  ];

  nextKinInfo = () => [
    {
      label: "Title",
      value: "",
      shouldShow: !this.state.edit,
    },
    {
      label: "First name",
      value: this.state.nextOfKin?.firstName,
      shouldShow: true,
      name: "firstName",
    },
    {
      label: "Last name",
      value: this.state.nextOfKin?.lastName,
      shouldShow: true,
      name: "lastName",
    },
    {
      label: "Date of birth",
      value: this.state.nextOfKin?.dateOfBirth,
      shouldShow: true,
      name: "dateOfBirth",
      type: "date",
    },
    {
      label: "Nationality",
      value: this.state.nextOfKin?.nationality,
      shouldShow: true,
      name: "nationality",
    },
    {
      label: "Relationship",
      value: this.state.nextOfKin?.relationship,
      shouldShow: true,
      type: "select",
      options: [
        "father",
        "mother",
        "brother",
        "sister",
        "friend",
        "spouse",
        "son",
        "daughter",
      ],
      name: "relationship",
    },
    {
      label: "Gender",
      value: this.state.nextOfKin?.gender,
      type: "select",
      options: ["male", "female", "Rather not say"],
      shouldShow: true,
      name: "gender",
    },
    {
      label: "Email",
      value: this.state.nextOfKin?.email,
      shouldShow: !this.state.edit,
    },
    {
      label: "Phone Number",
      phoneValue: this.state.nextOfKin?.phone,
      countryCodeValue: this.state.nextOfKin?.countryCode ?? "+234",
      shouldShow: true,
      name: "phone",
      type: "phone",
    },
    {
      label: "Contact address",
      value: this.state.nextOfKin?.contactAddress,
      shouldShow: true,
      name: "contactAddress",
    },
  ];

  otherInfo = () => {
    const positionStartDate = this.state.politicalStatus?.from;
    const positionEndDate = this.state.politicalStatus?.to;
    const associatePositionStart =
      this.state.politicalStatus?.politicalAssociate?.from;
    const associatePositionEnd =
      this.state.politicalStatus?.politicalAssociate?.to;
    return [
      {
        label: "Have you occupied any Political Position?",
        value: this.state.politicalStatus?.occupiedPoliticalPosition ?? 0,
        shouldShow: true,
        type: "select",
        options: [
          { name: "Yes", value: 1 },
          { name: "No", value: 0 },
        ],
        name: "occupiedPoliticalPosition",
      },
      {
        label: "Name of Position you Occupied",
        value: this.state.politicalStatus?.positionName || "",
        shouldShow: Number(this.state.occupiedPoliticalPosition) === 1,
        name: "positionName",
      },
      {
        label: "Date: from",
        type: "date",
        value: positionStartDate,
        shouldShow: Number(this.state.occupiedPoliticalPosition) === 1,
        name: "from",
      },
      {
        label: "Do you occupy this position till date?",
        value: this.state.politicalStatus?.occupiedTillDate ?? 0,
        type: "select",
        options: [
          { name: "Yes", value: 1 },
          { name: "No", value: 0 },
        ],
        shouldShow: Number(this.state.occupiedPoliticalPosition) === 1,
        name: "occupiedTillDate",
      },
      {
        label: "Date: to",
        type: "date",
        value: positionEndDate,
        shouldShow:
          Number(this.state.occupiedTillDate) !== 1 &&
          Number(this.state.occupiedPoliticalPosition) === 1,
        name: "to",
      },
      {
        label:
          "Have any of your relatives/associates occupied a Political position?",
        value: this.state.politicalStatus?.hasPoliticalAssociate ?? 0,
        type: "select",
        options: [
          { name: "Yes", value: 1 },
          { name: "No", value: 0 },
        ],
        shouldShow: true,
        name: "hasPoliticalAssociate",
      },
      {
        label: "Name of Position",
        value:
          this.state.politicalStatus?.politicalAssociate?.positionName || "",
        shouldShow: Number(this.state.hasPoliticalAssociate) === 1,
        name: "associatePositionName",
      },
      {
        label: "Relationship with Associate",
        value: this.state.politicalStatus?.relationshipWithAssociate || "",
        shouldShow: Number(this.state.hasPoliticalAssociate) === 1,
        type: "select",
        options: [
          "father",
          "mother",
          "brother",
          "sister",
          "friend",
          "spouse",
          "son",
          "daughter",
        ],
        name: "relationshipWithAssociate",
      },
      {
        label: "Date: from",
        type: "date",
        value: associatePositionStart,
        shouldShow: Number(this.state.hasPoliticalAssociate) === 1,
        name: "associateFrom",
      },
      {
        label: "Does he/she occupy this position till date?",
        value: this.state.politicalStatus?.politicalAssociate?.occupiedTillDate
          ? 1
          : 0,
        type: "select",
        options: [
          { name: "Yes", value: 1 },
          { name: "No", value: 0 },
        ],
        shouldShow: Number(this.state.hasPoliticalAssociate) === 1,
        name: "associateOccupiedTillDate",
      },
      {
        label: "Date: to",
        type: "date",
        value: associatePositionEnd,
        shouldShow:
          Number(this.state.associateOccupiedTillDate) !== 1 &&
          Number(this.state.hasPoliticalAssociate) === 1,
        name: "associateTo",
      },
    ];
  };

  setDate = (section, name, value) => {
    this.saveEdittedSection(section);
    this.setState({
      [section + "Date"]: {
        ...this.state[section + "Date"],
        [name]: value,
      },
    });
  };

  renderFormFields = (fieldsArray, section) =>
    fieldsArray.map((field) => {
      const errorObject = serializeErrors(this.props.pageRequestError?.message);
      if (field.shouldShow) {
        const input =
          field.type === "select" ? (
            <select
              className="form-control panel-input"
              id={field.name + field.label + section}
              value={this.state.name}
              name={field.name}
              defaultValue={field.value}
              onChange={(e) => this.onInputChange(e, section)}
              disabled={!this.state.edit}
            >
              <option value=""></option>
              {field.options.map((option) => (
                <option
                  value={option.value ?? option}
                  key={option.name ?? option}
                >
                  {option.name ?? option}
                </option>
              ))}
            </select>
          ) : field.type === "phone" ? (
            <PhoneNumberInput
              onInputChange={(e) => this.onInputChange(e, section)}
              defaultCountryCode={field.countryCodeValue}
              defaultPhone={field.phoneValue}
              isDisabled={!this.state.edit}
              id={field.name + field.label + section}
              name={field.name}
            />
          ) : field.type === "date" ? (
            <DatePicker
              className="form-control panel-input"
              dayClassName={() => "react-datepicker-dates"}
              selected={
                this.state[section + "Date"]?.[field.name] ??
                (field.value ? new Date(field.value) : null)
              }
              onChange={(date) => this.setDate(section, field.name, date)}
              readOnly={!this.state.edit}
              id={field.name + field.label + section + "Date"}
              name={field.name}
              autoComplete="off"
            />
          ) : (
            <input
              type={field.type || "text"}
              className="form-control panel-input"
              id={field.name + field.label + section}
              name={field.name}
              defaultValue={field.value}
              autoComplete="off"
              onChange={(e) => this.onInputChange(e, section)}
              disabled={!this.state.edit}
            />
          );
        return (
          <div
            className="user-info__field"
            key={field.name + field.label + section + this.state.formKeys}
          >
            <label
              className="panel-label d-block"
              htmlFor={field.name + field.label + section}
            >
              {field.label}
            </label>
            {input}
            <InputError error={errorObject[`${section}.${field.name}`]} />
          </div>
        );
      }
      return "";
    });

  onInputChange = (e, section) => {
    if (["politicalStatus", "employment"].includes(section)) {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.saveEdittedSection(section);
    this.setState({ showID: true });
  };

  // keeps track of which section of the form was changed
  saveEdittedSection = (section) => {
    const changedSections = this.state.edittedSections;
    if (changedSections.indexOf(section) !== -1) return;
    this.setState({
      edittedSections: [...changedSections, section],
    });
  };

  onSubmit = () => {
    const sectionRefs = {
      bankInfo: this.bankDetailsForm,
      employment: this.employmentDetailsForm,
      personal: this.bioDataForm,
      nextOfKin: this.nextKinInfoForm,
      politicalStatus: this.otherInfoForm,
    };
    if (
      this.state.selectedDocuments.government_id &&
      !this.state.selectedIdType
    ) {
      return this.props.showAlert({
        type: "error",
        message: "Please select the type of ID you are uploading",
      });
    }
    const formData = new FormData();
    this.state.edittedSections.forEach((section) => {
      const form = sectionRefs[section].current;
      let currentSection = {};
      Array.prototype.forEach.call(form.elements, (data) => {
        currentSection[data.name] =
          data.id.endsWith("Date") && data.value
            ? dayjs(data.value).format("YYYY-MM-DD")
            : data.value;
      });
      if (section === "politicalStatus") {
        const politicalStatus = {
          occupiedPoliticalPosition: Number(
            currentSection.occupiedPoliticalPosition
          ),
          occupiedTillDate: currentSection.occupiedTillDate
            ? Number(currentSection.occupiedTillDate)
            : 0,
          positionName: currentSection.positionName ?? "",
          to: currentSection.to ?? "",
          from: currentSection.from ?? "",
          hasPoliticalAssociate: Number(currentSection.hasPoliticalAssociate),
          relationshipWithAssociate:
            currentSection.relationshipWithAssociate ?? "",
          associatePositionName: currentSection.associatePositionName ?? "",
          associateOccupiedTillDate: currentSection.associateOccupiedTillDate
            ? Number(currentSection.associateOccupiedTillDate)
            : 0,
          associateTo: currentSection.associateTo ?? "",
          associateFrom: currentSection.associateFrom ?? "",
        };
        formData.append("politicalStatus", JSON.stringify(politicalStatus));
      } else {
        formData.append(section, JSON.stringify(currentSection));
      }
    });
    for (const field in this.state.selectedDocuments) {
      const key = field === "government_id" ? this.state.selectedIdType : field;
      formData.append(key, this.state.selectedDocuments[field]);
    }

    this.props
      .editCustomerProfile(this.props.match.params.id, formData)
      .then(() => {
        this.setState({
          edit: false,
          edittedSections: [],
          formKeys: Math.random() * 10,
        });
      });
  };

  viewDocument = (category, url) => {
    if (url) {
      return window.open(url, "_blank");
    }
    const billObject = this.state.documents.find(
      (element) => element.category === category
    );
    if (billObject) {
      return window.open(billObject.url, "_blank");
    }
    this.props.showAlert({
      type: "error",
      message: "User has not uploaded this document",
    });
  };

  onDocumentButtonClick = (category, url) => {
    const categories = {
      bills: "utility_bill",
      passport: "passport-photograph",
    };
    if (this.state.edit) {
      this.setState({ documentToBeUploaded: categories[category] ?? category });
      return this.documentUpload.current.click();
    }
    this.viewDocument(category, url);
  };

  onDocumentSelect = (e) => {
    const selectedFile = e.target.files[0];
    const { selectedDocuments, documentToBeUploaded, selectedIdType } =
      this.state;
    this.setState({
      selectedIdType:
        documentToBeUploaded === "government_id" ? "" : selectedIdType,
      selectedDocuments: {
        ...selectedDocuments,
        [documentToBeUploaded]: selectedFile,
      },
    });
  };

  viewSegment = () => {
    if (!this.state.segment) {
      return this.props.showAlert({
        type: "error",
        message: "User has not joined a segment",
      });
    }
    const { id } = this.props.match.params;
    this.setState({ openPanel: true, panelTitle: "Segment" });
    this.props.fetchSingleCustomer(id, "segment").then(({ data }) => {
      this.setState({
        dataName: data.segment.name,
        dataDetails: data.details,
        segmentIcon: data.segment.icon,
      });
    });
  };

  viewRisk = () => {
    if (!this.state.risk) {
      return this.props.showAlert({
        type: "error",
        message: "No risk assessment for this user",
      });
    }
    const { id } = this.props.match.params;
    this.setState({ openPanel: true, panelTitle: "Risk Profile" });
    this.props.fetchSingleCustomer(id, "risk").then(({ data }) => {
      this.setState({
        dataName: data.risk.profile,
        dataDetails: data.details,
      });
    });
  };

  onEditClick = () => {
    this.setState({ edit: true });
  };

  render() {
    const { fetching, loading, permissions } = this.props;
    const {
      edit,
      pictureUrl,
      dataName,
      email,
      id,
      openPanel,
      panelTitle,
      dataDetails = [],
      segmentIcon,
      selectedIdType,
      selectedDocuments,
    } = this.state;
    const canEditUsers = hasPermission(permissions, "update_user");

    return (
      <div className="setup-inner">
        <RightPanel
          open={openPanel}
          onClose={() => this.setState({ openPanel: false })}
        >
          <h1 className="panel-heading">{panelTitle}</h1>
          {openPanel && fetching ? (
            <div className="text-center text-primary">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <div className="panel-info">
              {panelTitle === "Segment" && (
                <div className="panel-info__picture">
                  <img src={segmentIcon} alt="segment icon" />
                </div>
              )}
              {dataDetails.map((detail) => (
                <div className="panel-info__row" key={detail.question}>
                  <span className="panel-info__field">{detail.question}</span>
                  <span className="panel-info__value">
                      {
                      detail.others ? detail.others : detail.options.filter((item) => item !== "").join(", ")
                    }
                  </span>
                </div>
              ))}
              <div className="panel-info__row">
                <span className="panel-info__field">
                  {panelTitle === "Segment" ? "Segment" : "Profile"}
                </span>
                <span className="panel-info__value">{dataName}</span>
              </div>
              <button
                className="long-button"
                onClick={() => this.setState({ openPanel: false })}
              >
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <div className="d-flex align-items-center">
          <BackArrrow
            className="cp-pagination__arrow--active"
            onClick={() => this.props.history.push("/dashboard/users")}
          />
          <span
            className="font-md mx-3 font-weight-bold cp-pagination__arrow--active"
            onClick={() => this.props.history.push("/dashboard/users")}
          >
            Back
          </span>
          {canEditUsers && (
            <button
              className="setup-inner__button px-4 ml-auto"
              disabled={edit}
              onClick={this.onEditClick}
            >
              <EditIcon className="setup-inner__button-img" />
              Edit
            </button>
          )}
        </div>
        {!email && fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          email && (
            <div className="user-info">
              {!edit && (
                <h4 className="panel-heading text-center">User Details</h4>
              )}
              {!edit && (
                <form className="user-info__group">
                  {this.renderFormFields(this.userDetails(), "userDetails")}
                </form>
              )}
              <h4 className="panel-heading text-center">Bio Data</h4>
              <form className="user-info__group" ref={this.bioDataForm}>
                {this.renderFormFields(this.bioData(), "personal")}
              </form>
              <h4 className="panel-heading text-center">Employment Details</h4>
              <form
                className="user-info__group"
                ref={this.employmentDetailsForm}
              >
                {this.renderFormFields(this.employmentDetails(), "employment")}
              </form>
              <h4 className="panel-heading text-center">Bank Details</h4>
              <form className="user-info__group" ref={this.bankDetailsForm}>
                {this.renderFormFields(this.bankDetails(), "bankInfo")}
              </form>
              <h4 className="panel-heading text-center">Next of Kin Details</h4>
              <form className="user-info__group" ref={this.nextKinInfoForm}>
                {this.renderFormFields(this.nextKinInfo(), "nextOfKin")}
              </form>
              <h4 className="panel-heading text-center">Other Informations</h4>
              <form className="user-info__group" ref={this.otherInfoForm}>
                {this.renderFormFields(this.otherInfo(), "politicalStatus")}
              </form>

              <h4 className="panel-heading text-center">KYC Documents</h4>
              <div className="user-info__group">
                <div className="user-info__field">
                  <label className="panel-label">Utility Bill</label>
                  <button
                    onClick={() => this.onDocumentButtonClick("bills")}
                    className="form-control panel-input"
                  >
                    {edit
                      ? `${
                          selectedDocuments.utility_bill?.name ??
                          "Upload utility bill"
                        }`
                      : "View user’s utility bill"}
                  </button>
                </div>
                <div className="user-info__field">
                  <label className="panel-label">Passport Photograph</label>
                  <button
                    onClick={() =>
                      this.onDocumentButtonClick("passport", pictureUrl)
                    }
                    className="form-control panel-input"
                  >
                    {edit
                      ? `${
                          selectedDocuments[`passport-photograph`]?.name ??
                          "Upload passport photograph"
                        }`
                      : "View user’s passport photograph"}
                  </button>
                </div>
                <div className="user-info__field">
                  <label className="panel-label">ID</label>
                  <button
                    onClick={() => this.onDocumentButtonClick("government_id")}
                    className="form-control panel-input"
                  >
                    {edit
                      ? `${
                          selectedDocuments.government_id?.name ?? "Upload ID"
                        }`
                      : "View user’s ID"}
                  </button>
                </div>
                {selectedDocuments.government_id && (
                  <div className="user-info__field">
                    <label
                      className="panel-label d-block"
                      htmlFor="selectIdType"
                    >
                      ID Type
                    </label>
                    <select
                      className="form-control panel-input"
                      id="selectIdType"
                      value={selectedIdType}
                      onChange={(e) =>
                        this.setState({ selectedIdType: e.target.value })
                      }
                    >
                      <option value=""></option>
                      <option value="international_passport">
                        International passport
                      </option>
                      <option value="drivers_licence">Drivers licence</option>
                      <option value="national_id">National ID</option>
                      <option value="pvc">PVC</option>
                    </select>
                  </div>
                )}
                <div className="user-info__field">
                  <label className="panel-label">Signature Specimen</label>
                  <button
                    onClick={() => this.onDocumentButtonClick("signature")}
                    className="form-control panel-input"
                  >
                    {edit
                      ? `${
                          selectedDocuments.signature?.name ??
                          "Upload signature specimen"
                        }`
                      : "View user’s signature specimen"}
                  </button>
                </div>
                <input
                  type="file"
                  ref={this.documentUpload}
                  onChange={this.onDocumentSelect}
                  onClick={(e) => (e.target.value = null)}
                  className="invisible"
                />
              </div>
              <h4 className="panel-heading text-center">User Infomation</h4>
              <div className="user-info__group">
                <div className="user-info__field">
                  <label className="panel-label">Segment</label>
                  <button
                    onClick={this.viewSegment}
                    className="form-control panel-input"
                  >
                    View user’s segment
                  </button>
                </div>
                <div className="user-info__field">
                  <label className="panel-label">Risk Profile</label>
                  <button
                    onClick={this.viewRisk}
                    className="form-control panel-input"
                  >
                    View user’s risk profile
                  </button>
                </div>
                <div className="user-info__field">
                  <label className="panel-label">Cash Balance</label>
                  <input
                    className="form-control panel-input"
                    defaultValue={
                      this.state?.wallets.length
                        ? `N${currency(this.state.wallets[0]?.balance)}`
                        : "N0.00"
                    }
                    disabled
                  />
                </div>
                <div className="user-info__field">
                  <label className="panel-label">Transaction history</label>
                  <button
                    onClick={() =>
                      this.props.history.push(
                        `/dashboard/user/${id}/transactions`
                      )
                    }
                    className="form-control panel-input"
                  >
                    View user’s transaction history
                  </button>
                </div>
                <div className="user-info__field">
                  <label className="panel-label">Portfolio</label>
                  <button
                    onClick={() =>
                      this.props.history.push(`/dashboard/user/${id}/portfolio`)
                    }
                    className="form-control panel-input"
                  >
                    View user’s Portfolio
                  </button>
                </div>
              </div>

              {edit && (
                <div className="dual-button-box col-md-6 mx-auto">
                  <button
                    onClick={this.cancelEdit}
                    className="cp-button-blue color-red"
                  >
                    Cancel
                  </button>
                  <button className="cp-button-blue" onClick={this.onSubmit}>
                    Request Approval{" "}
                    {loading && (
                      <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                    )}
                  </button>
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
    loading: getActionLoadingState(state, actionTypes.CUSTOMERS_PAGE_REQUEST),
    pageRequestError: getActionErrorState(
      state,
      actionTypes.CUSTOMERS_PAGE_ERROR
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleCustomer: (userId, detail) =>
      dispatch(fetchSingleCustomer(userId, detail)),
    editCustomerProfile: (userId, payload) =>
      dispatch(editCustomerProfile(userId, payload)),
    showAlert: (message) => dispatch(showAlert(message)),
    fetchBanks: () => dispatch(fetchBanks()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleUser)
);
