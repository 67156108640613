import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchTerminations,
  reverseTermination,
  editTermination,
  searchTerminations,
  updateMultipleTermination,
} from "@/store/Transactions/terminationsAction";
import { clearError } from "@/store/ui/actions";
import actionTypes from "@/store/Transactions/actionTypes";
import { getActionLoadingState, getActionErrorState } from "@/store/selectors";
import { currency, serializeErrors, hasPermission } from "@/utils";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import ConfirmationBox from "@/components/ConfirmationBox";
import Emptystate from "@/components/Emptystate";
import Pagination from "@/components/Pagination";
import InputError from "@/components/InputError";
import ExportDropdown from "@/components/ExportDropdown";
import SearchBox from "@/components/SearchBox";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";

class Terminations extends Component {
  state = {
    open: false,
    previewMode: false,
    terminations: [],
    amount: "",
    lastPage: "",
    perPage: "",
    page: "",
    searchTerm: "",
    shouldSearch: false,
    selectedRowsIds: [],
  };

  componentDidMount() {
    this.getTerminations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        { searchTerm: "", shouldSearch: false, selectedRowsIds: [] },
        this.getTerminations
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.searchTerminations(1, 10, this.state.searchTerm);
    }
  }

  getTerminations = (page = 1, limit = 10) => {
    if (this.state.searchTerm)
      return this.searchTerminations(page, limit, this.state.searchTerm);
    const { status } = this.props.match.params;
    this.props.fetchTerminations(page, limit, status).then((res) => {
      this.setPageData(res.data.terminations);
    });
  };

  searchTerminations = (page, limit, searchTerm) => {
    if (!searchTerm) return this.getTerminations(1, 10);
    const { status } = this.props.match.params;
    this.props
      .searchTerminations(page, limit, status, searchTerm)
      .then((res) => {
        this.setPageData(res.data.terminations);
      });
  };

  setPageData = ({ lastPage, perPage, page, total, data: terminations }) => {
    this.setState({ terminations, lastPage, perPage, page, total });
  };

  dropdownmenus = (id, amount) => {
    const canUpdate = hasPermission(
      this.props.permissions,
      "update_liquidation"
    );
    return [
      canUpdate && {
        name: "Edit termination",
        handler: () => this.onEdit(id, amount),
      },
      canUpdate && {
        name: "Reverse termination",
        handler: () => this.setConfirmationContext("reverse", id),
      },
    ].filter(Boolean);
  };

  setConfirmationContext = (context, terminationId, isMultipleApproval) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      terminationId,
      isMultipleApproval,
    });
  };

  onEdit = (id, amount) => {
    this.setState({
      amount,
      terminationId: id,
      open: true,
      panelHeading: "Edit Termination",
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      reverse: {
        title: "Reason for reversing termination",
        action: (reason) => this.reverseTermination(reason),
        requiresReason: true,
      },
      approveUpdate: {
        title: `Approve update${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } update${addS ? "s" : ""}`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("active")
            : this.approveOrRejectUpdates("active"),
      },
      rejectUpdate: {
        title: `Reason for rejecting update${addS ? "s" : ""}`,
        action: (reason) =>
          isMultipleApproval
            ? this.updateMultiple("rejected", reason)
            : this.approveOrRejectUpdates("rejected", reason),
        requiresReason: true,
      },
    };
  };

  reverseTermination = (reason) => {
    const { terminationId } = this.state;
    this.props.reverseTermination(terminationId, reason).then(() => {
      this.setState({ confirmationContext: "" });
    });
  };

  approveOrRejectUpdates = (approvalStatus, reason) => {
    const { terminationId } = this.state;
    const payload = { approval_status: approvalStatus };
    if (reason) payload.reason = reason;
    this.props.editTermination(
      terminationId,
      payload,
      approvalStatus === "active" ? "approve" : "reject"
    );
  };

  updateMultiple = (status, message) => {
    const { selectedRowsIds } = this.state;
    this.props
      .updateMultipleTermination(selectedRowsIds, status, message)
      .then(() => {
        window.location.reload();
      });
  };

  editTermination = (e) => {
    e.preventDefault();
    const { terminationId, amount } = this.state;
    const payload = {
      amount,
      approval_status: "pending",
      action: "edit",
    };
    this.props.editTermination(terminationId, payload);
  };

  onRowClick = (e, termination) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      const { status } = this.props.match.params;
      if (status === "updates") {
        return this.setState({
          currentAmount: `N${currency(termination.amount)}`,
          newAmount: `N${currency(termination.temp.newAmount)}`,
          open: true,
          panelHeading: "Modification",
          previewMode: true,
        });
      }
      this.setState({
        previewMode: true,
        previewItem: {
          Reference: termination.reference,
          "Termed investment REF": termination.investment.reference,
          "Termed investment name": termination.investment.title,
          "Customer name": `${termination.user.firstName} ${termination.user.lastName}`,
          "Start date": dayjs(termination.investment.startDate).format(
            "D MMM YYYY"
          ),
          "Maturity date": termination.investment.endDate
            ? dayjs(termination.investment.endDate).format("D MMM YYYY")
            : "N/A",
          "Termination date": dayjs(termination.created_at).format(
            "D MMM YYYY"
          ),
          "Termination amount": `N${currency(termination.amount)}`,
          "Accrued interest": `N${currency(termination.accruedInterest)}`,
          "Penalty amount": `N${currency(termination.fees)}`,
          "Balance on investment": `N${currency(
            termination.investment.balance
          )}`,
          "Part/Full liquidation": `${termination.liquidation} liquidation`,
        },
        open: true,
        panelHeading: "Termination Details",
      });
    }
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderTerminations = () => {
    const { status } = this.props.match.params;
    const isUpdate = status === "updates";
    return (
      <table className="custum-table custum-table__long">
        <tbody>
          <tr>
            {isUpdate && <th></th>}
            <th>REFERENCE</th>
            <th>TERMED INVESTMENT REF</th>
            <th>TERMED INVESTMENT NAME</th>
            <th>CUSTOMER NAME</th>
            <th>START DATE</th>
            <th>MATURITY DATE</th>
            <th>TERMINATION DATE</th>
            <th>TERMINATION AMOUNT</th>
            <th>ACCRUED INTEREST</th>
            <th>PENALTY AMOUNT</th>
            <th>BALANCE ON INVESTMENT</th>
            <th>PART/FULL LIQUIDATION</th>
            <th></th>
          </tr>
          {this.state.terminations.map((termination) => (
            <tr
              key={termination.id}
              onClick={(e) => this.onRowClick(e, termination)}
              className="cursor-pointer"
            >
              {isUpdate && (
                <td className="row-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => this.onCheckRow(e, termination.id)}
                  />
                </td>
              )}
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {termination.reference}
              </td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {termination.investment?.reference}
              </td>
              <td style={{ maxWidth: "25rem", whiteSpace: "normal" }}>
                {termination.investment?.title}
              </td>
              <td className="text-capitalize">{`${termination.user?.firstName} ${termination.user?.lastName}`}</td>
              <td>
                {dayjs(termination.investment?.startDate).format("D MMM YYYY")}
              </td>
              <td>
                {termination.investment?.endDate
                  ? dayjs(termination.investment.endDate).format("D MMM YYYY")
                  : "N/A"}
              </td>
              <td>{dayjs(termination.created_at).format("D MMM YYYY")}</td>
              <td>{`N${currency(termination.amount)}`}</td>
              <td>{`N${currency(termination?.accruedInterest)}`}</td>
              <td>{`N${currency(termination.fees)}`}</td>
              <td>{`N${currency(termination.investment?.balance)}`}</td>
              <td className="text-capitalize">{`${termination.liquidation} liquidation`}</td>
              {!isUpdate && (
                <td className="custum-table__ellipsis">
                  <Dropdown
                    menu={this.dropdownmenus(
                      termination.id,
                      termination.amount
                    )}
                    arrow={true}
                  >
                    <button className="wrapper-button ellipsis">
                      <img
                        src={require("@/assets/icons/flat-ellipsis.svg")}
                        alt="dropdown"
                      />
                    </button>
                  </Dropdown>
                </td>
              )}
              {isUpdate && (
                <td className="custum-table__button-cell">
                  <button
                    className="custum-table__button-blue"
                    onClick={() =>
                      this.setConfirmationContext(
                        "approveUpdate",
                        termination.id
                      )
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="custum-table__button-plain"
                    onClick={() =>
                      this.setConfirmationContext(
                        "rejectUpdate",
                        termination.id
                      )
                    }
                  >
                    Reject
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderPreview = () => {
    const { status } = this.props.match.params;
    if (status === "updates") {
      const { currentAmount, newAmount } = this.state;
      return (
        <div className="panel-info__row">
          <span className="font-md mr-5">Amount</span>
          <span className="text-right ml-2">
            <span className="d-block font-md text-bold text-grey text-break">
              Old data: {currentAmount}
            </span>
            <span className="d-block font-md text-bold text-break">
              New data: {newAmount}
            </span>
          </span>
        </div>
      );
    }
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      amount: "",
      terminationId: "",
      previewMode: false,
    });
    this.props.clearError();
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      match: {
        params: { status },
      },
      pageRequest,
      pageRequestError,
    } = this.props;
    const {
      previewMode,
      confirmationContext,
      amount,
      terminations,
      lastPage,
      perPage,
      page,
      total,
      panelHeading,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const createErrorObject = serializeErrors(pageRequestError?.message);
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">{panelHeading}</h1>
          {!previewMode ? (
            <form className="form employer-onboard__form">
              <label className="panel-label" htmlFor="amount">
                Termination amount
              </label>
              <input
                type="text"
                className="form-control panel-input"
                placeholder="50,000"
                id="amount"
                name="amount"
                value={amount}
                onChange={this.onInputChange}
                autoComplete="off"
                required
              />
              <InputError error={createErrorObject.amount} />
              <div className="dual-button-box">
                <button onClick={this.closePanel} className="cp-button-blue">
                  Cancel
                </button>
                <button
                  className="cp-button-blue"
                  onClick={this.editTermination}
                >
                  Submit{" "}
                  {pageRequest && (
                    <div className="spinner-border spinner-border-white spinner-border-sm ml-2 mb-2"></div>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="panel-info">
              {this.renderPreview()}
              <button className="long-button" onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink to="recent" activeClassName="setup-nav__item--active">
                Recent
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink to="all" activeClassName="setup-nav__item--active">
                All Terminations
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink to="updates" activeClassName="setup-nav__item--active">
                Pending updates
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {status !== "updates" && terminations.length > 0 && (
            <ExportDropdown
              module="liquidations"
              status="approved"
              page={page}
              limit={perPage}
              range={status}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown
              approve={() =>
                this.setConfirmationContext("approveUpdate", null, true)
              }
              reject={() =>
                this.setConfirmationContext("rejectUpdate", null, true)
              }
            />
          )}
        </div>
        {this.props.fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!terminations.length ? (
              <Emptystate
                title="No Terminations"
                icon={require("@/assets/icons/info.svg")}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getTerminations(page, limit)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderTerminations()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${terminations.length} of ${total}`}</span>
                    Terminations
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(
      state,
      actionTypes.GET_TERMINATIONS_REQUEST
    ),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.TERMINATIONS_PAGE_REQUEST
    ),
    permissions: state.user.permissions,
    pageRequestError: getActionErrorState(
      state,
      actionTypes.TERMINATIONS_PAGE_ERROR
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTerminations: (page, limit, status) =>
      dispatch(fetchTerminations(page, limit, status)),
    searchTerminations: (page, limit, status, searchTerm) =>
      dispatch(searchTerminations(page, limit, status, searchTerm)),
    reverseTermination: (id, reason) =>
      dispatch(reverseTermination(id, reason)),
    editTermination: (id, data, approvalStatus) =>
      dispatch(editTermination(id, data, approvalStatus)),
    updateMultipleTermination: (ids, status, message) =>
      dispatch(updateMultipleTermination(ids, status, message)),
    clearError: () => dispatch(clearError()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Terminations)
);
