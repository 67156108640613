import React, { Component } from "react";

class RightPanel extends Component {

    render() {
        const { open, onClose, children} = this.props;
        return(
            <div className={`modal-pane ${!open ? 'modal-pane--hide' : ''}`}>
                <div className="modal-pane__backdrop"></div>
                <div className="modal-pane__main">
                    <img 
                        src={require("@/assets/icons/cancel.svg")} 
                        alt="close icon" 
                        className="modal-pane__close"
                        onClick={onClose}
                    />
                    {children}
                </div>
            </div>
        )
    }
}

export default RightPanel;