import axios from 'axios';
import CONFIG from '@/config';
import * as actions from "./actionTypes";
import { logout, updateUser } from "@/store/user/actions";
import { showAlert } from '@/store/ui/actions';
import { apiRequestHandler } from "@/utils";

const clear = () => {
    return {
      type: actions.CLEAR,
    }
  }
  
const getConfigRequest = () => {
    return {
        type: actions.GET_CONFIG_REQUEST
    }
}

const getConfigError = () => {
    return {
        type: actions.GET_CONFIG_ERROR,
    }
}

const getConfigSuccess = () => {
    return {
        type: actions.GET_CONFIG_SUCCESS
    }
}

const configPageRequest = () => {
    return {
        type: actions.CONFIG_PAGE_REQUEST
    }
}

const configPageError = (message) => {
    return {
        type: actions.CONFIG_PAGE_ERROR,
        message
    }
}

const configPageSuccess = () => {
    return {
        type: actions.CONFIG_PAGE_SUCCESS
    }
}

export const fetchConfigurations = (status) => {
    return (dispatch, getState) => {
        dispatch(getConfigRequest());
        return new Promise((resolve) => {
            axios.get(`${CONFIG.BASE_URL}/admin/configurations/${status}`, {
                headers: {
                    "Authorization": getState().user.token,
                },
            })
            .then(response => {
                dispatch(updateUser({
                    token: response.headers.authorization
                }))
                if (response.status === 200) {
                    resolve(response.data.data?.configurations);
                    dispatch(getConfigSuccess());
                }
            })
            .catch(({ response }) => {
                dispatch(getConfigError());
                dispatch(updateUser({token: response?.headers.authorization}));
                if(response?.status === 401) {
                    dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
                    return setTimeout(() => dispatch(logout()), 2000) 
                }
                if(response?.status === 403) {
                    return dispatch(showAlert({ type: 'error', message: 'Oops! you are unauthorised' }))
                }
                dispatch(showAlert({ type: 'error', message: 'Oops! something went wrong please try again.' }))
            })
        })
    }
}

export const fetchSnappyConfigurations = (page, limit, searchTerm) => {
    let param = `?page=${page}&limit=${limit}`;
    if (searchTerm) {
      param = `search?search=${searchTerm}&page=${page}&limit=${limit}`;
    }
    
    return (dispatch, getState) => {
      dispatch(getConfigRequest());
      return new Promise((resolve) => {
        axios.get(`${CONFIG.BASE_URL}/admin/services/snappy-rates/${param}`, {
            headers: {
              Authorization: getState().user.token,
            },
          })
          .then((response) => {
            dispatch(
              updateUser({
                token: response.headers.authorization,
              })
            );
            if (response.status === 200) {
              dispatch(getConfigSuccess());
              resolve(response.data.data.investments || response.data.data);
            }
          })
          .catch(({ response }) => {
            dispatch(getConfigError());
            dispatch(
              updateUser({
                token: response?.headers.authorization,
              })
            );
            if ([400, 404].includes(response?.status)) {
              response.data.message &&
                dispatch(
                  showAlert({ type: "error", message: response.data.message })
                );
            }
            if (response?.status === 403) {
              return dispatch(
                showAlert({
                  type: "error",
                  message: "Oops! you are unauthorised",
                })
              );
            }
            if (response?.status === 401) {
              dispatch(
                showAlert({ type: "error", message: "Your session has expired" })
              );
              return setTimeout(() => dispatch(logout()), 2000);
            }
            dispatch(
              showAlert({
                type: "error",
                message: "Oops! something went wrong please try again.",
              })
            );
          });
      });
    };
  };

export const updateConfiguration = (configId, status) => {
    const path = Array.isArray(configId) ? `admin/configurations/multiple/status` : `admin/configurations/${configId}/status`
    const payload = {status, requests: configId};
    const config = {
        urlPath: path,
        method: Array.isArray(configId) ? 'patch' : 'put',
        payload,
        requestAction: configPageRequest,
        successAction: configPageSuccess,
        errorAction: configPageError,
    }
    return apiRequestHandler(config);
}

export const updateSnappyConfiguration = (configId, status) => {
    const path = `admin/services/${configId}/snappy/status`
    const payload = {status};
    const config = {
        urlPath: path,
        method: 'put',
        payload,
        requestAction: configPageRequest,
        successAction: configPageSuccess,
        errorAction: configPageError,
    }
    return apiRequestHandler(config);
}

export const changeConfiguration = (configId, value) => {
    const config = {
        urlPath: `admin/configurations/${configId}`,
        method: 'put',
        payload: {value},
        requestAction: configPageRequest,
        successAction: configPageSuccess,
        errorAction: configPageError,
    }
    return apiRequestHandler(config);
}

export const createConfiguration = (payload) => {
    return (dispatch, getState) => {
        dispatch(getConfigRequest());
  
      const { token } = getState().user;
  
      axios.put(`${CONFIG.BASE_URL}/admin/services/new/rates`, payload, {
        headers: {
            'Content-Type': 'application/json', 
            Authorization: token,
        },
      })
      .then(response => {
        dispatch(updateUser({
          token: response.headers.authorization
        }))
        if ([200, 201].includes(response.status)) {
          dispatch(getConfigSuccess(response.data));
          setTimeout(() => dispatch(clear()), 3000)
          return dispatch(showAlert({ type: 'success', message: response.data.message }))
        }
        return;
      })
      .catch(({ response }) => {
        response && dispatch(updateUser({
          token: response.headers.authorization
        }))
        if (response && [400, 404, 403].includes(response.status)) {
          response.data.message && dispatch(showAlert({ type: 'error', message: response.data.message }))
          return dispatch(getConfigError(response.data.error ? response.data.error : response.data.message));
        }
        if (response && [401].includes(response.status)) {
          dispatch(showAlert({ type: 'error', message: 'Your session has expired' }))
          return setTimeout(() => dispatch(logout()), 2000) 
        }
        if (response && response.status >= 500) {
          return dispatch(getConfigError('Oops! We did something wrong.'));
        }
        dispatch(getConfigError('Oops! We did something wrong.'));
      })
    }
  }


