import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from 'dayjs';
import { fetchCustomers, updateAccount, fetchBvnInfo, searchCustomers, exportPendingAccountActivation, updateMultipleBvnActivation } from "@/store/Customers";
import actionTypes from "@/store/Customers/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import Emptystate from '@/components/Emptystate';
import Pagination from '@/components/Pagination';
import ConfirmationBox from '@/components/ConfirmationBox';
import ExportDropdown from '@/components/ExportDropdown';
import RightPanel from '@/components/RightPanel';
import SearchBox from '@/components/SearchBox';
import MultipleApprovalDropdown from '@/components/MultipleApprovalDropdown';
import { camelCaseToString } from "@/utils";

class AccountActivation extends Component {
    state = { 
        users: [],
        showBvnDetails: false,
        bvnInfo: {},
        searchTerm: '',
        shouldSearch: false,
        selectedRowsIds: [],
    };

    componentDidMount() {
        this.getCustomers(1, 10);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({searchTerm: '', shouldSearch: false, selectedRowsIds: []}, () => this.getCustomers(1, 10));
        }
        if (this.state.searchTerm !== prevState.searchTerm && this.state.shouldSearch) {
            this.searchCustomers(1, 10, this.state.searchTerm);
        }
    }

    getCustomers = (page, limit) => {
        if (this.state.searchTerm) return this.searchCustomers(page, limit, this.state.searchTerm);
        const { status } = this.props.match.params;
        const path = status === 'manual' ? 'manual/activations' : status === 'auto' ? 'auto/activations' : 'activation/pending';
        this.props.fetchCustomers(page, limit, path).then(data => {
            this.setPageData(data.data.users || data.data);
        })
    }

    searchCustomers = (page, limit, searchTerm) => {
        if(!searchTerm) return this.getCustomers(1, 10);
        const { status } = this.props.match.params;
        const section = status === 'manual' ? 'manually-activated-account' : status === 'auto' ? 'automatically-activated-account' : 'pending-account-activation';
        this.props.searchCustomers(page, limit, section, searchTerm).then(data => {
            this.setPageData(data.data.users, searchTerm);
        })
    }

    setPageData = ({lastPage, perPage, page, total, data: users}, searchTerm, shouldSearch) => {
        this.setState({users, lastPage, perPage, page, total, searchTerm, shouldSearch})
    }

    removeFromList = (ids) => {
        let users;
        if(Array.isArray(ids)) {
            users = this.state.users.filter(data => !ids.includes(data.id));
        } else {
            users = [ ...this.state.users ];
            const userIndex = users.findIndex(user => user.id === ids);
            users.splice(userIndex, 1);
        }
        this.setState({
            users,
            selectedRowsIds: [],
            confirmationSuccess: true,
        })
    }

    updateAccount = (shouldActivate, message) => {
        const { userId, isMultipleApproval, selectedRowsIds } = this.state;
        let id = isMultipleApproval ? selectedRowsIds : userId;
        if(!isMultipleApproval) {
            this.props.updateAccount(id, shouldActivate, 'bvn', message).then(() => {
                this.removeFromList(id)
            });
        } else {
            this.props.updateMultipleBvnActivation(id, shouldActivate, message).then(() => {
                this.removeFromList(id)
            })
        }
    }

    confirmationData = () => {
        const {isMultipleApproval, selectedRowsIds} = this.state;
        const addS = isMultipleApproval && selectedRowsIds.length > 1;
        return {
            approve: {
                title: `Activate account${addS ? 's' : ''}`,
                question: `Are you sure you want to activate ${addS ? 'these' : 'this'} account${addS ? 's' : ''}?`,
                action: () => this.updateAccount(true),
            },
            reject: {
                title: 'Reason for Rejecting activation',
                requiresReason: true,
                action: (message) => this.updateAccount(false, message),
            },
        }
    }

    setConfirmationContext = (context, userId, isMultipleApproval ) => {
        this.setState({
            confirmationContext: context,
            confirmationSuccess: false,
            userId,
            isMultipleApproval,
        })
    }

    viewBvnDetails = (id) => {
        this.setState({showBvnDetails: true});
        this.props.fetchBvnInfo(id).then(data => {
            this.setState({bvnInfo: data.data.user});
        })
    }

    onCheckRow = (e, rowId) => {
        const selectedRows = [ ...this.state.selectedRowsIds ]
        if(e.target.checked) {
            selectedRows.push(rowId);
        } else {
            const uncheckedRowIndex = selectedRows.findIndex(id => id === rowId);
            selectedRows.splice(uncheckedRowIndex, 1)
        }
        this.setState({selectedRowsIds: selectedRows})
    }

    renderCustomers = () => {
        const { status } = this.props.match.params;
        const isManual = status === 'manual';
        const isAutomatic = status === 'auto';
        return <table className="custum-table">
            <tbody>
                <tr>
                    {!isManual && !isAutomatic && <th></th>}
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>BVN</th>
                    <th>Email Address</th>
                    {isManual && isAutomatic && <th>Phone Number</th>}
                    {isManual && isAutomatic && <th>Date Activated</th>}
                    {!isManual && !isAutomatic && <th></th>}
                </tr>
                {this.state.users.map(user => (
                    <tr key={user.id}>
                        {!isManual && !isAutomatic && <td className="row-checkbox">
                            <input type="checkbox" onChange={(e) => this.onCheckRow(e, user.id)}/>
                        </td>}
                        <td className="text-capitalize">{user.firstName || user.user.firstName}</td>
                        <td className="text-capitalize">{user.lastName || user.user.lastName}</td>
                        <td>{dayjs(user.dateOfBirth).format('D MMM YYYY')}</td>
                        <td>{user.bvn}</td>
                        <td>{user.email}</td>
                        {isManual && isAutomatic && <td>{user.phone}</td>}
                        {isManual && isAutomatic && <td>{dayjs(user.activationDate).format('D MMM YYYY')}</td>}
                        {/* {(!isManual && isBvnType) && <td className="custum-table__button-cell">
                            <button 
                                className="custum-table__button-blue" 
                                onClick={() => this.viewBvnDetails(user.id)}
                            >
                                View Details
                            </button>
                        </td>} */}
                        {!isManual && !isAutomatic && <td className="custum-table__button-cell">
                            <button 
                                className="custum-table__button-blue" 
                                onClick={() => this.setConfirmationContext('approve', user.id)}
                            >
                                Approve
                            </button>
                            <button 
                                className="custum-table__button-plain"
                                onClick={() => this.setConfirmationContext('reject', user.id)}
                            >
                                Reject
                            </button>
                        </td>}
                    </tr>
                ))}
            </tbody>
        </table>
    }

    renderBvnDetails = () => {
        const { bvnInfo } = this.state;
        return Object.keys(bvnInfo).map(field => (
            <div className="panel-info__row" key={field}>
                <span className="panel-info__field text-capitalize">
                    {field === 'formattedDob' ? 'date of birth' : camelCaseToString(field)}
                </span>
                <span className="panel-info__value">{bvnInfo[field]}</span>
            </div>
        ))
    }

    render() {
        const { match: { params: { status } }, fetching, pageRequest } = this.props;
        const { 
            users, lastPage, perPage, page, total, confirmationContext, confirmationSuccess, showBvnDetails, searchTerm,
        } = this.state;
        const prompt = this.confirmationData()[confirmationContext];
        const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;

        return(
            <div className="setup-inner">
                <RightPanel 
                    open={showBvnDetails}
                    onClose={() => this.setState({showBvnDetails: false})}
                >
                    <h1 className="panel-heading">User BVN Information</h1>
                    {pageRequest ? <div className="text-center text-primary">
                        <div className="spinner-border" role="status"></div>
                    </div> : <div className="panel-info">
                        {this.renderBvnDetails()}
                        <button className="long-button" onClick={() => this.setState({showBvnDetails: false})}>Close</button>
                    </div>}
                </RightPanel>
                <ConfirmationBox
                    open={confirmationContext}
                    closeHandler={() => this.setState({confirmationContext: ''})}
                    success={confirmationSuccess}
                    title={prompt?.title}
                    question={prompt?.question}
                    action={prompt?.action}
                    loading={pageRequest}
                    requiresReason={prompt?.requiresReason}
                />
                <div className="setup-inner__top">
                    <ul className="setup-inner__nav">
                        <li className="setup-nav__item">
                            <NavLink  
                                to={{pathname: 'pending'}}
                                isActive={() => status === 'pending'}
                                activeClassName="setup-nav__item--active"
                            >
                                All Pending
                            </NavLink>
                        </li>
                        <li className="setup-nav__item">
                            <NavLink  
                                to={{pathname: 'manual'}}
                                isActive={() => status === 'manual'}
                                activeClassName="setup-nav__item--active"
                            >
                                Activated Manually (BVN)
                            </NavLink>
                        </li>
                        <li className="setup-nav__item">
                            <NavLink  
                                to={{pathname: 'auto'}}
                                isActive={() => status === 'auto'}
                                activeClassName="setup-nav__item--active"
                            >
                                Activated Automatically (BVN)
                            </NavLink>
                        </li>
                    </ul>
                    <SearchBox
                        placeholder={`Search ${status === 'pending' ? 'pending' : ''} accounts`}
                        handleSearch={searchTerm => this.setState({searchTerm, shouldSearch: true})}
                        isActiveSearch={Boolean(searchTerm)}
                        key={status}
                    />
                    {users.length > 0 && <ExportDropdown
                        module={`${status === 'manual' ? 'manually-activated-accounts' : status === 'auto' ? 'automatically-activated-accounts' : 'activation-pending'}`}
                        status={status === 'pending' ? 'bvn' : 'approved'} 
                        page={page}
                        limit={perPage}
                        downloadHandler={status === 'pending' ? this.props.exportPending : null}
                        hasMoreThanOnePage={lastPage > 1}
                    />}
                    {showMultiAprrovalDropdown && <MultipleApprovalDropdown
                        approve={() => this.setConfirmationContext('approve', null, true)}
                        reject={() => this.setConfirmationContext('reject', null, true)}
                    />}
                </div>
                {fetching ? <div className="text-center text-primary">
                    <div className="spinner-border" role="status"></div>
                </div> : 
                <div className="position-relative">
                    {!users.length ? <Emptystate
                        title={`${searchTerm ? 'No Result Found' : 'No Account'}`}
                        icon={require('@/assets/icons/info.svg')}
                    /> : <>
                        <Pagination
                            totalPages={lastPage}
                            page={page}
                            limit={perPage}
                            changePageHandler={(page, limit) => this.getCustomers(page, limit)}
                        />
                        <div className="table-overflow">
                            <div className="setup-inner__main setup-inner__expand">
                                {this.renderCustomers()}
                            </div>
                            <div className="data-count">
                                Showing<span className="font-weight-bold mx-2">{`${users.length} of ${total}`}</span>Users
                            </div>
                        </div>
                    </>}
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetching: getActionLoadingState(state, actionTypes.GET_CUSTOMERS_REQUEST),
        pageRequest: getActionLoadingState(state, actionTypes.CUSTOMERS_PAGE_REQUEST),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomers: (page, limit, path) => dispatch(fetchCustomers(page, limit, null, path)),
        fetchBvnInfo: (id) => dispatch(fetchBvnInfo(id)),
        updateAccount: (userId, shouldActivate, type, message = '') => dispatch(updateAccount(userId, shouldActivate, type, message)),
        updateMultipleBvnActivation: (userId, shouldActivate, message = '') => dispatch(updateMultipleBvnActivation(userId, shouldActivate, message)),
        searchCustomers: (page, limit, status, searchTerm) => dispatch(searchCustomers(page, limit, status, searchTerm)),
        exportPending: (action, module, format, status, page, limit) => dispatch(exportPendingAccountActivation(action, module, format, status, page, limit)),   
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
);
