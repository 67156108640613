import React, { Component } from "react";
import './style.scss';

class EmptyState extends Component {
    render() {
        const {icon, title, isSmall} = this.props;
        return(
            <div className="empty-state">
                <img src={icon} alt="icon" className="empty-state__icon"/>
                <h4 className={`empty-state__title ${isSmall && 'empty-state__title-small'}`}>{title}</h4>
                <p className={`empty-state__info ${isSmall && 'empty-state__info-small'}`}>
                    {this.props.children}
                </p>
            </div>
        )
    }
}

export default EmptyState;