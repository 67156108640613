import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, NavLink, Redirect } from "react-router-dom";
import Configurations from "./Configurations";
import Admins from "./Admins";
import Roles from "./Roles";
import Segments from "./Segments";
import News from "./News";
import Blog from "./Blog";
import Investments from "./Investments";
import { hasPermission } from "@/utils";
import UserGuide from "./UserGuide";
import MutualFunds from "./MutualFunds";
import Faq from "./Faq";
import PushNotification from "./PushNotification";
import "./style.scss";

class Setup extends Component {
  canReadUsers = hasPermission(this.props.permissions, [
    "read_user",
    "create_user",
    "update_user",
    "delete_user",
    "approve_user",
  ]);
  canReadConfigs = hasPermission(this.props.permissions, [
    "read_configuration",
    "update_configuration",
    "approve_configuration",
  ]);
  canReadRoles = hasPermission(this.props.permissions, [
    "read_role",
    "create_role",
    "update_role",
    "delete_role",
    "approve_role",
  ]);
  canReadService = hasPermission(this.props.permissions, [
    "read_service",
    "create_service",
    "update_service",
    "delete_service",
    "approve_service",
  ]);
  canReadSegment = hasPermission(this.props.permissions, [
    "read_segment",
    "create_segment",
    "update_segment",
    "delete_segment",
    "approve_segment",
  ]);
  canReadMutualFunds = hasPermission(this.props.permissions, [
    "read_mutual_fund",
    "create_mutual_fund",
    "initiate_mutual_fund_update",
    "initiate_enable_mutual_fund",
    "initiate_disable_mutual_fund",
    "initiate_delete_mutual_fund",
    "approve_mutual_fund",
  ]);
  canReadGuide = hasPermission(this.props.permissions, [
    "read_guide",
    "create_guide",
    "update_guide",
    "approve_guide",
  ]);
  canReadFAQ = hasPermission(this.props.permissions, [
    "read_faq",
    "create_faq",
    "update_faq",
    "delete_faq",
    "approve_faq",
  ]);
  canReadPushNotifications = hasPermission(this.props.permissions, [
    "read_pushnotification",
    "create_pushnotification",
    "update_pushnotification",
    "delete_pushnotification",
    "approve_pushnotification",
  ]);

  navItems = [
    this.canReadConfigs
      ? { name: "Configurations", path: "configurations" }
      : null,
    this.canReadService ? { name: "Investments", path: "investments" } : null,
    this.canReadUsers ? { name: "Admin Users", path: "admins" } : null,
    this.canReadRoles ? { name: "Roles", path: "roles" } : null,
    this.canReadSegment ? { name: "Segments", path: "segments" } : null,
    { name: "News", path: "news" },
    { name: "Blog", path: "blog" },
    this.canReadGuide ? { name: "User Guide", path: "user-guide" } : null,
    this.canReadMutualFunds
      ? { name: "New Offers", path: "mutual-funds" }
      : null,
    this.canReadFAQ ? { name: "FAQ", path: "faq" } : null,
    this.canReadPushNotifications
      ? { name: "Push Notifications", path: "push-notification" }
      : null,
  ].filter(Boolean);

  render() {
    const {
      match: { path },
    } = this.props;
    const firstRoute = this.navItems.length ? this.navItems[0].path : "";
    return (
      <div className="setup-area">
        <ul className="setup-nav">
          {this.navItems.map((item, index) => (
            <li key={index} className="setup-nav__item">
              <NavLink
                to={{
                  pathname: `/dashboard/setup/${item.path}`,
                  state: { pageTitle: "Setup" },
                }}
                activeClassName="setup-nav__item--active"
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <Switch>
          <Route exact path={path}>
            <Redirect to={{ pathname: `${path}/${firstRoute}` }} />
          </Route>
          {this.canReadConfigs && (
            <Route path={`${path}/configurations`}>
              <Configurations />
            </Route>
          )}
          {this.canReadService && (
            <Route path={`${path}/investments`}>
              <Investments />
            </Route>
          )}
          {this.canReadUsers && (
            <Route path={`${path}/admins`}>
              <Admins />
            </Route>
          )}
          {this.canReadRoles && (
            <Route path={`${path}/roles`}>
              <Roles />
            </Route>
          )}
          {this.canReadSegment && (
            <Route path={`${path}/segments`}>
              <Segments />
            </Route>
          )}
          <Route path={`${path}/news`}>
            <News />
          </Route>
          <Route path={`${path}/blog`}>
            <Blog />
          </Route>
          {this.canReadGuide && (
            <Route path={`${path}/user-guide`}>
              <UserGuide />
            </Route>
          )}
          {this.canReadMutualFunds && (
            <Route path={`${path}/mutual-funds`}>
              <MutualFunds />
            </Route>
          )}
          {this.canReadFAQ && (
            <Route path={`${path}/faq`}>
              <Faq />
            </Route>
          )}
          {this.canReadPushNotifications && (
            <Route path={`${path}/push-notification`}>
              <PushNotification />
            </Route>
          )}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.user.permissions,
  };
};

export default withRouter(connect(mapStateToProps, null)(Setup));
