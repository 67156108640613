import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { fetchFaqs, createOrEditFaq, updateOrDeleteFaq } from "@/store/setup/faqAction";
import actionTypes from "@/store/setup/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { hasPermission } from "@/utils";
import Dropdown from '@/components/DropdownMenu';
import Emptystate from '@/components/Emptystate';
import ConfirmationBox from '@/components/ConfirmationBox';
import Pagination from '@/components/Pagination';
import SearchBox from "@/components/SearchBox";

class AllFaq extends Component {
    state = {
        faqObject: {},
        faqTitles: [],
        searchTerm: '',
        shouldSearch: false,
    }

    componentDidMount() {
        this.getFaq(1, 10);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.getFaq(1, 10);
        }
        if(this.state.searchTerm !== prevState.searchTerm && this.state.shouldSearch) {
            this.getFaq(1, 10, this.state.searchTerm);
        }
    }

    getFaq = (page, limit, searchTerm) => {
        const { type } = this.props.match.params;
        this.props.fetchFaqs(type, page, limit, searchTerm).then(faq => {
            this.formatFaqData(faq)
        })
    }

    formatSearchData = ({ lastPage, perPage, page, total, data }) => {
        const faqTitles = [];
        const faqObject = {};
        data.forEach(faq => {
            faqTitles.push(faq.title)
            faqObject[faq.title] = faq;
        })
        this.setState({
            faqTitles,
            faqObject,
            activeFaq: faqTitles[0],
        })
    }

    formatFaqData = ({ lastPage, perPage, page, total, data }) => {
        const faqTitles = [];
        const faqObject = {};
        data.forEach(faq => {
            faqTitles.push(faq.title)
            faqObject[faq.title] = faq;
        })
        this.setState({
            faqTitles,
            faqObject,
            activeFaq: faqTitles[0],
            lastPage,
            perPage,
            page,
            total,
        })
    }

    renderFaqs = () => {
        const { faqObject, faqTitles, activeFaq, lastPage, page, perPage, shouldSearch } = this.state;
        const { type } = this.props.match.params;

        if(faqTitles.length) {
            const activeFaqDetails = faqObject[activeFaq];
            return <>
                <Pagination
                    totalPages={lastPage}
                    page={page}
                    limit={perPage}
                    changePageHandler={(page, limit) => this.getFaq(page, limit)}
                />
                <div className="setup-inner__main">
                    <div className="roles-main">
                        <div className="roles-main__left">
                            {faqTitles.map((faq, index) => (
                                <button 
                                    className={`roles-main__left-item ${activeFaq === faq ? 'roles-main__left-item--active' : ''}`}
                                    onClick={() => this.setState({activeFaq: faq, activeFaqIndex: index})}
                                    key={faq}
                                >
                                    {faq}
                                </button>)
                            )}
                        </div>
                        <div className="roles-main__right">
                            <div className="roles-main__role-detail roles-main__role-detail--short">
                                <h2 className="roles-main__role-name">{activeFaqDetails.title}</h2>
                                <div className="roles-main__dropdown">
                                    {type !== 'pending' ? (
                                        <span className={`status mr-5 ${activeFaqDetails.status === 'active' ? 'status--active' : 'status--disabled'}`}>
                                            {activeFaqDetails.status}
                                        </span>) : (
                                        <span className='status mr-5 status--info'>
                                            {activeFaqDetails.action}
                                        </span>)
                                    }
                                    <Dropdown menu={this.dropdownmenus(activeFaqDetails.id, activeFaqDetails.action)} width="136px" arrow={true}>
                                        <button className="wrapper-button ellipsis">
                                            <img src={require("@/assets/icons/flat-ellipsis.svg")} alt="dropdown"/>
                                        </button>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="roles-main__content">
                                {activeFaqDetails.content}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        } else {
            return <Emptystate
                title={shouldSearch ? 'No FAQ matches this search word' : 'No FAQ'}
                icon={require('@/assets/icons/info.svg')}
            />
        }
    }

    updateOrDeleteFaq = (status) => {
        const { faqId, activeFaqIndex, faqTitles  } = this.state;
        const payload = status ? { status } : '';
        this.props.updateOrDeleteFaq(faqId, payload).then(() => {
            faqTitles.splice(activeFaqIndex, 1);
            this.setState({faqTitles, activeFaq: faqTitles[0], confirmationSuccess: true });
        })
    }

    publishFaq = () => {
        const { faqObject, activeFaq } = this.state;
        const activeFaqDetails = faqObject[activeFaq];
        const payload = {
            title: activeFaqDetails.title,
            content: activeFaqDetails.content,
            publish: true,
        }
        this.props.createOrEditFaq(payload, activeFaqDetails.id, this.props.history);
    }

    dropdownmenus = (faqId, action) => {
        const { type } = this.props.match.params;
        const isDeleteRequest = action === 'delete';
        const canUpdate = hasPermission(this.props.permissions, 'update_faq');
        const canDelete = hasPermission(this.props.permissions, 'delete_faq');
        const canCreate = hasPermission(this.props.permissions, 'create_faq');
        const canApprove = hasPermission(this.props.permissions, 'approve_faq');
        return [
            canUpdate && {name: 'Edit FAQ', handler: () => this.props.history.push(`edit/${faqId}`)},
            (type !== 'pending' && canDelete) && {
                name: 'Delete FAQ', 
                handler: () => this.setConfirmationContext('deleteFaq', faqId),
                textClass: 'text-red',
            },
            (type === 'draft' && canCreate) && {
                name: 'Publish FAQ', 
                handler: () => this.setConfirmationContext('publishFaq'),
            },
            (type === 'pending' && canApprove) && {
                name: `Approve ${isDeleteRequest ? 'delete' : 'FAQ'}`, 
                handler: () => this.setConfirmationContext('approveFaq', faqId, isDeleteRequest),
            },
            (type === 'pending' && canApprove) && {
                name: 'Reject FAQ', 
                handler: () => this.setConfirmationContext('rejectFaq', faqId),
                textClass: 'text-red',
            },
        ].filter(Boolean)
    }

    setConfirmationContext = (context, faqId, isDeleteRequest ) => {
        this.setState({
            confirmationContext: context,
            confirmationSuccess: false,
            faqId,
            isDeleteRequest,
        })
    }

    confirmationData = () => {
        const { isDeleteRequest } = this.state;
        return {
            publishFaq: {
                title: 'Publish FAQ',
                question: `Are you sure you want to publish this FAQ?`,
                action: () => this.publishFaq(),
            },
            deleteFaq: {
                title: 'Delete FAQ',
                question: 'Are you sure you want to delete this FAQ?',
                action: () => this.updateOrDeleteFaq(),
            },
            approveFaq: {
                title: `Approve ${isDeleteRequest ? 'delete' : 'FAQ'}`,
                question: `Are you sure you want to approve this ${isDeleteRequest ? 'request' : 'FAQ'}?`,
                action: () => this.updateOrDeleteFaq('active'),
            },
            rejectFaq: {
                title: 'Reason for rejecting FAQ',
                action: () => this.updateOrDeleteFaq('rejected'),
                requiresReason: true
            },
        }
    }

    render() {
        const { confirmationLoading, permissions, fetching } = this.props;
        const { confirmationContext, confirmationSuccess, searchTerm } = this.state;
        const prompt = this.confirmationData()[confirmationContext];
        const canApprove = hasPermission(permissions, 'approve_role');
        const canCreate = hasPermission(permissions, 'create_faq');

        return(
            <div className="setup-inner">
                <ConfirmationBox
                    open={confirmationContext}
                    closeHandler={() => this.setState({confirmationContext: ''})}
                    success={confirmationSuccess}
                    title={prompt?.title}
                    question={prompt?.question}
                    action={prompt?.action}
                    loading={confirmationLoading}
                    requiresReason={prompt?.requiresReason}
                />
                <div className="setup-inner__top">
                    <ul className="setup-inner__nav">
                        <li className="setup-nav__item">
                            <NavLink 
                                exact 
                                to={{pathname: `/dashboard/setup/faq/active`}} 
                                activeClassName="setup-nav__item--active"
                            >
                                All FAQs
                            </NavLink>
                        </li>
                        <li className="setup-nav__item">
                            <NavLink
                                to={{pathname: `/dashboard/setup/faq/draft`}} 
                                activeClassName="setup-nav__item--active"
                            >
                                Drafts
                            </NavLink>
                        </li>
                        {canApprove && <li className="setup-nav__item">
                            <NavLink
                                to={{pathname: `/dashboard/setup/faq/pending`}} 
                                activeClassName="setup-nav__item--active"
                            >
                                Pending Approval
                            </NavLink>
                        </li>}
                    </ul>
                    <SearchBox
                        placeholder="Search"
                        handleSearch={searchTerm => this.setState({searchTerm, shouldSearch: true})}
                        isActiveSearch={Boolean(searchTerm)}
                        key={this.props.location.pathname}
                    />
                    {canCreate && <button className="setup-inner__button" onClick={() => this.props.history.push('/dashboard/setup/faq/create')}>
                        <img src={require("@/assets/icons/plus.svg")} alt="plus icon" className="setup-inner__button-img" />
                         Create New FAQ
                    </button>}
                </div>
                {fetching ? <div className="text-center text-primary">
                        <div className="spinner-border" role="status"></div>
                    </div> :
                this.renderFaqs()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetching: getActionLoadingState(state, actionTypes.GET_FAQ_REQUEST),
        permissions: state.user.permissions,
        confirmationLoading: getActionLoadingState(state, actionTypes.FAQ_PAGE_REQUEST),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFaqs: (status, page, limit, searchTerm) => dispatch(fetchFaqs(status, page, limit, null, searchTerm)),
        updateOrDeleteFaq: (id, payload) => dispatch(updateOrDeleteFaq(id, payload)),
        createOrEditFaq: (payload, editId, history) => dispatch(createOrEditFaq(payload, editId, history, 'pending')),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AllFaq)
);
